import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import Liabilities from "./Liabilities";
import {
  getFinancialAnalysisFinancialList,
  deleteLoanFinancial,
  getLoanProduct,
} from "Components/LoanDetails/Actions/LoanDetailsAction";
import {
  addLoanLiabilities,
  updateLoanLiabilities,
} from "./Actions/LiabilitiesActions";
import { showLiabilityPage } from "../../Utils/LogicUtilities";

class LiabilitiesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: false,
      liabilitiesList: [],
    };
  }

  componentDidMount() {
    console.log("container");
    const {
      products,
      getActiveProduct,
      doGetFinancialAnalysisFinancialList,
      handleBack,
      continuehandler,
      actionBack,
      config,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (showLiabilityPage(activeProduct, config)) {
      doGetFinancialAnalysisFinancialList(
        {
          applicantId: activeProduct.applicantId,
          finAnalysisId: activeProduct.finAnalysisId,
        },
        (res) => {
          console.log(res);
          this.getLiabilitiesList(res);
          console.log(this.state.liabilitiesList);
          // console.log(this.state.liabilitiesList);
        }
      );
    } else {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.LIABILITIES);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.LIABILITIES);
      }
    }
  }

  filterLiabilities(data) {
    return data.filter(
      (liability) => liability.type === AppConstants.LIABILITIES.TYPE
    );
  }

  getLiabilitiesList = (data) => {
    const filteredLiabilities = this.filterLiabilities(data);
    this.setState({
      liabilitiesList: filteredLiabilities,
      choice: filteredLiabilities.length > 0 ? true : false,
    });

    console.log(filteredLiabilities);
    // console.log(data);
  };

  addLiability(applicantId, finAnalysisId, newList) {
    const { doAddLoanLiabilities, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    const loanDetailsId = activeProduct.doximProductId;
    console.log(newList);
    if (newList) {
      newList.forEach((raw, idx) => {
        console.log(raw);

        const liabilityValue =
          String(raw.value).indexOf("$") > -1
            ? this.parseAmount(raw.value)
            : raw.value;
        const liabilityLimit =
          String(raw.locLimit).indexOf("$") > -1
            ? this.parseAmount(raw.locLimit)
            : raw.locLimit;
        const liabilityRate =
          String(raw.intRate).indexOf("$") > -1
            ? this.parseAmount(raw.intRate)
            : raw.intRate;
        const monthly =
          String(raw.monthly).indexOf("$") > -1
            ? this.parseAmount(raw.monthly)
            : raw.monthly;
        console.log(liabilityValue);
        console.log(liabilityLimit);
        console.log(liabilityRate);
        if (raw.subType === "Guarantor" || raw.subType === "Guar") {
          let addData = {
            SubType: raw.subType,
            Description: raw.description,
            Value: liabilityValue,
            IsPrimaryResidence: raw.primaryResidence,
            Institution: raw.institution,
            SecuredType: raw.secType,
            LocLimit: liabilityLimit,
            Rate: liabilityRate,
            MonthlyAmount: monthly,
          };
          raw.adjustment && (addData.Adjustment = raw.adjustment);
          raw.associatedLoanId &&
            (addData.AssociatedLoanId = raw.associatedLoanId);
          doAddLoanLiabilities(applicantId, finAnalysisId, addData, (res) => {
            console.log(res);
          });
        } else {
          let addData = {
            SubType: raw.subType,
            Description: raw.description,
            Value: liabilityValue,
            IsPrimaryResidence: raw.primaryResidence,
            Institution: raw.institution,
            SecuredType: raw.secType,
            Adjustment: raw.adjustment,
            LocLimit: liabilityLimit,
            Rate: liabilityRate,
            PaymentType: raw.paymentType,
            MonthlyAmount: monthly,
          };
          if (raw.subType === "CCard" || raw.subType === "LOC") {
            addData.paymentType = "Add Payment";
          }
          raw.adjustment && (addData.Adjustment = raw.adjustment);
          raw.associatedLoanId &&
            (addData.AssociatedLoanId = raw.associatedLoanId);
          doAddLoanLiabilities(applicantId, finAnalysisId, addData, (res) => {
            console.log(res);
          });
        }
      });
    }
  }

  updateLiability(applicantId, finAnalysisId, financialId, updatedLiability) {
    const { doUpdateLoanLiabilities, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    const loanDetailsId = activeProduct.doximProductId;
    console.log(updatedLiability);
    if (updatedLiability) {
      const liabilityValue =
        String(updatedLiability.value).indexOf("$") > -1
          ? this.parseAmount(updatedLiability.value)
          : updatedLiability.value;
      const liabilityLimit =
        String(updatedLiability.locLimit).indexOf("$") > -1
          ? this.parseAmount(updatedLiability.locLimit)
          : updatedLiability.locLimit;
      const liabilityRate =
        String(updatedLiability.intRate).indexOf("$") > -1
          ? this.parseAmount(updatedLiability.intRate)
          : updatedLiability.intRate;
      const monthly =
        String(updatedLiability.monthly).indexOf("$") > -1
          ? this.parseAmount(updatedLiability.monthly)
          : updatedLiability.monthly;
      console.log(liabilityLimit);
      console.log(liabilityRate);
      if (
        updatedLiability.subType === "Guarantor" ||
        updatedLiability.subType === "Guar"
      ) {
        let updateData = {
          SubType: updatedLiability.subType,
          Description: updatedLiability.description,
          Value: liabilityValue,
          Institution: updatedLiability.institution,
          IsPrimaryResidence: updatedLiability.primaryResidence,
          SecuredType: updatedLiability.secType,
          Adjustment: updatedLiability.adjustment,
          LocLimit: liabilityLimit,
          Rate: liabilityRate,
          MonthlyAmount: monthly,
          AssociatedLoanId: updatedLiability.associatedLoanId,
        };
        doUpdateLoanLiabilities(
          applicantId,
          finAnalysisId,
          financialId,
          updateData,
          (res) => {
            console.log(res);
          }
        );
      } else {
        let updateData = {
          SubType: updatedLiability.subType,
          Description: updatedLiability.description
            .split("[PrimaryResidence]")
            .join(""),
          Value: liabilityValue,
          Institution: updatedLiability.institution,
          IsPrimaryResidence: updatedLiability.primaryResidence,
          SecuredType: updatedLiability.secType,
          Adjustment: updatedLiability.adjustment
            .split("[PrimaryResidence]")
            .join(""),
          LocLimit: liabilityLimit,
          Rate: liabilityRate,
          PaymentType: updatedLiability.paymentType,
          MonthlyAmount: monthly,
          AssociatedLoanId: updatedLiability.associatedLoanId,
        };
        if (
          updatedLiability.subType === "CCard" ||
          updatedLiability.subType === "LOC"
        ) {
          updateData.PaymentType = "Add Payment";
        }
        doUpdateLoanLiabilities(
          applicantId,
          finAnalysisId,
          financialId,
          updateData,
          (res) => {
            console.log(res);
          }
        );
      }
    }
  }

  removeAllLiabilities(applicantId, finAnalysisId, liabilities) {
    const { doDeleteLoanLiabilities } = this.props;
    if (liabilities.length > 0) {
      console.log("liabilities ");
      liabilities.forEach((asset) => {
        // console.log(asset);
        doDeleteLoanLiabilities(applicantId, finAnalysisId, asset.financialId);
      });
    }
  }

  getDiff(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return (
            other.value == current.value &&
            other.display == current.display &&
            other.description == current.description &&
            other.subType == current.subType &&
            other.institution == current.institution &&
            other.adjustment == current.adjustment &&
            other.secType == current.secType &&
            other.locLimit == current.locLimit &&
            other.intRate == current.intRate &&
            other.paymentType == current.paymentType &&
            other.primaryResidence == current.primaryResidence
          );
        }).length == 0
      );
    };
  }

  parseAmount(str) {
    // const matches = str.match(/(\d+)/);
    // const replaces = str.replace(/\D/g, "");
    const result = Number(str.replace(/[^0-9\.-]+/g, ""));

    return result;
  }

  handleLiabilitiesButtonChange = (choice) => {
    this.setState({ choice: choice });
  };

  handleContinue = (choice) => {
    this.processLiabilities(choice.liabilitiesList, choice);
  };

  processLiabilities(liabilities, paramChoice) {
    console.log("processLiabilities", liabilities);
    const { liabilitiesList, choice } = this.state;
    const {
      products,
      getActiveProduct,
      doDeleteLoanLiabilities,
      continuehandler,
      doGetApplicationProperty,
    } = this.props;
    let updateCtr = 0;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (!activeProduct) {
      return;
    }
    // if (!activeProduct.finAnalysisId !== 0 || !activeProduct.finAnalysisId !== "" || !activeProduct.finAnalysisId) {
    //   return;
    // }
    const { applicantId } = activeProduct;
    const { finAnalysisId } = activeProduct;

    console.log(choice);
    if (choice) {
      console.log(liabilitiesList);
      console.log(liabilities);
      if (liabilitiesList.length > 0) {
        // need to remove old asset if not in new list
        liabilitiesList.forEach((old) => {
          const oldAssetExist = liabilities.find(
            (asset) => asset.financialId === old.financialId
          );
          console.log("search asset", oldAssetExist);
          if (!oldAssetExist) {
            console.log("deleting asset", old.financialId);
            updateCtr += 1;
            doDeleteLoanLiabilities(
              applicantId,
              finAnalysisId,
              old.financialId
            );
          }
        });
      }
      if (liabilities) {
        const newList = liabilitiesList.filter(this.getDiff(liabilities));
        const diff = liabilities.filter(this.getDiff(liabilitiesList));
        console.log(newList);
        console.log(diff);
        // do update here
        const addedList = [];
        diff.forEach((data) => {
          console.log(data.financialId);
          if (data.financialId && data.financialId !== "undefined") {
            console.log("To Update");
            updateCtr += 1;
            this.updateLiability(
              applicantId,
              finAnalysisId,
              data.financialId,
              data
            );
          } else {
            console.log("to add");
            addedList.push(data);
          }
        });

        if (addedList && addedList.length !== 0) {
          updateCtr += 1;
          this.addLiability(applicantId, finAnalysisId, addedList);
          console.log("new liabilities");
        }
      }
    } else {
      if (liabilitiesList.length > 0) {
        console.log("liabilities");
        updateCtr += 1;
        this.removeAllLiabilities(applicantId, finAnalysisId, liabilitiesList);
      }
    }

    console.log("waiting before continuing", updateCtr);
    doGetApplicationProperty(
      {
        applicationId: activeProduct.applicationId,
        delayTime: updateCtr * 1000,
      },
      () => {
        continuehandler(
          { choice: paramChoice },
          AppConstants.APPLICATIONSTEP.LIABILITIES
        );
      }
    );
  }

  render() {
    const { choice, liabilitiesList } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      liabilityTypes,
      products,
      getActiveProduct,
      config,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    return (
      <>
        <Liabilities
          activeProduct={activeProduct}
          config={config}
          handleContinue={this.handleContinue}
          handleLiabilitiesButtonChange={this.handleLiabilitiesButtonChange}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          liabilitiesList={liabilitiesList}
          liabilityTypes={liabilityTypes}
        />
      </>
    );
  }
}

LiabilitiesContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

LiabilitiesContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  liabilityTypes: state.LoanDetailsReducer.liabilityTypes,
  config: state.LoanDetailsReducer.config,
  actionBack: state.ApplicationReducer.actionBack,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetFinancialAnalysisFinancialList: (request, callback) =>
      dispatch(getFinancialAnalysisFinancialList(request, callback)),
    doAddLoanLiabilities: (applicantId, finAnalysisId, payLoad, callback) =>
      dispatch(
        addLoanLiabilities(applicantId, finAnalysisId, payLoad, callback)
      ),
    doUpdateLoanLiabilities: (
      applicantId,
      finAnalysisId,
      financialId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLoanLiabilities(
          applicantId,
          finAnalysisId,
          financialId,
          payLoad,
          callback
        )
      ),
    doDeleteLoanLiabilities: (
      applicantId,
      finAnalysisId,
      financialId,
      payLoad,
      callback
    ) =>
      dispatch(
        deleteLoanFinancial(
          applicantId,
          finAnalysisId,
          financialId,
          payLoad,
          callback
        )
      ),
  };
};

const LiabilitiesWrapper = withApplicantHOC(LiabilitiesContainer);

export default connect(mapStateToProps, mapDispatchToProps)(LiabilitiesWrapper);
