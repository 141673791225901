import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Breadcrumbs from "react-router-dynamic-breadcrumbs";
import { Dropdown } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom"; // Added withRouter
import PropTypes from "prop-types";

import STRINGS from "Constants/Strings";
import FIInformation from "Constants/FIInformation";
import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import {
  HTTP_STATUS,
  TOKEN_KEY,
  VAULT_PASSWORD,
} from "Communication/Constants";
import Vault from "Components/Vault/Vault";

import doLogout from "Redux/Actions/LogoutAction";
import FundingReviewWindow from "../FundingReviewWindow/FundingReviewWindow";

import "./Header.scss";
import PopupModal from "../PopupModal/PopupModal";

// ToDo : Create another component for CustomToggle
const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <div
    ref={ref}
    onKeyDown={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    role="presentation"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div className="[ icon header-elipse-icon ]" />
  </div>
));

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbs: ROUTES.BREADCRUMBS,
      showWarning: false,
    };
  }

  componentDidMount() {
    this.removeDuplicatesFromBreadcrumbs();
  }

  componentDidUpdate(prevProps) {
    const { activeRoute } = this.props;
    if (prevProps.activeRoute !== activeRoute) {
      this.removeDuplicatesFromBreadcrumbs();
    }
  }

  /**
   * When we are on the Market place or Dashboard page we dont need the home
   * breadcrumb again but on other pages we need it.
   * So just on Market place and Dashboard pages we are removing the home breadcrumb
   */
  removeDuplicatesFromBreadcrumbs = () => {
    const { activeRoute } = this.props;
    let updatedBreadcrumbs = { ...ROUTES.BREADCRUMBS };

    if (
      activeRoute === ROUTES.MARKETPLACE ||
      activeRoute === ROUTES.VAULT_DASHBOARD
    ) {
      delete updatedBreadcrumbs[ROUTES.HOME];
    } else {
      const accessToken = sessionStorage.getItem("token");
      if (accessToken) {
        updatedBreadcrumbs = { ...ROUTES.BREADCRUMBS };
        if (activeRoute.includes(VAULT_PASSWORD)) {
          updatedBreadcrumbs[ROUTES.HOME] = STRINGS.MARKETPLACE.TITILE;
        } else {
          updatedBreadcrumbs[ROUTES.HOME] = STRINGS.HOME_BREADCRUMB;
        }
      }
    }
    this.setState({ breadcrumbs: updatedBreadcrumbs });
  };

  getActiveHeaderFromRoute = (activeRoute) => {
    if (activeRoute.includes(ROUTES.RECOVER_PASSWORD)) {
      return ROUTES.BREADCRUMBS[ROUTES.RECOVER_PASSWORD];
    }
    return ROUTES.BREADCRUMBS[activeRoute];
  };

  signOut = () => {
    const { inSessionJointApplicant } = this.props;
    console.log("signOut", window.location);
    if (
      inSessionJointApplicant &&
      !window.location.href.endsWith(ROUTES.JOINT_APPLICANT_STATUS)
    ) {
      this.setState({ showWarning: true });
    } else {
      this.continueSignOut();
    }
  };

  continueSignOut = () => {
    const { doLogoutAction, history } = this.props;
    doLogoutAction((res) => {
      if (res.status === HTTP_STATUS.OK) {
        history.push(ROUTES.MARKETPLACE);
      }
    });
  };

  changePassword = () => {
    const { history } = this.props;
    history.push(ROUTES.CHANGE_PASSWORD);
  };

  closeMenu = () => {};

  getActiveProduct = (products) => {
    return products?.find((product) => product?.active);
  };

  dropdownToggle = (e) => {
    const { showVaultClicked, showVault } = this.props;
    if (e && showVault) {
      showVaultClicked();
    }
  };

  toggleWarning = () => {
    this.setState(
      {
        showWarning: false,
      },
      () => {}
    );
  };

  render() {
    const accessToken = sessionStorage.getItem(TOKEN_KEY);
    const {
      showVault,
      activeRoute,
      history,
      vaultProductList,
      activeTab,
      FundState,
      fundProduct,
      showFundAccount,
      showFundVaultClicked,
      showVaultClicked,
      products,
      bankingType,
      switchBanking,
      handleSignIn,
      handleRegister,
      isQrKyc,
      location,
    } = this.props;
    console.log("active route", activeRoute);
    const { showWarning } = this.state;

    const activeProduct = this.getActiveProduct(products);

    const { method, amount } = FundState;

    const { breadcrumbs } = this.state;
    let popUpVault = null;
    if (showVault) {
      console.log("show vault");
      popUpVault = (
        <div className="[ small-vault-container ]">
          <Vault
            showProceedBtn={
              activeRoute === ROUTES.MARKETPLACE ||
              activeRoute === ROUTES.VAULT_DASHBOARD ||
              activeRoute === ROUTES.QUESTIONNAIRE
            }
            showVaultClicked={showVaultClicked}
            history={history}
          />
        </div>
      );
    } else if (showFundAccount) {
      popUpVault = (
        <div className="[ small-vault-container ]">
          <FundingReviewWindow
            fundMethod={method}
            fundAmount={amount}
            productName={fundProduct.productName}
            showFundVaultClicked={showFundVaultClicked}
          />
        </div>
        // <Backdrop show={showVault} clicked={showVaultClicked} top />
      );
    }
    let activeHeader = this.getActiveHeaderFromRoute(activeRoute);
    if (
      activeRoute === ROUTES.EXISTING_MEMBER ||
      activeRoute === ROUTES.MEMBER_CREATION ||
      activeRoute === ROUTES.BEFORE_WE_START ||
      activeRoute === ROUTES.GETTING_STARTED ||
      activeRoute === ROUTES.ABOUT_YOU ||
      activeRoute === ROUTES.EMPLOYMENT_AND_INCOME ||
      activeRoute === ROUTES.TAX_INFO ||
      activeRoute === ROUTES.COMPLIANCE ||
      activeRoute === ROUTES.CHANGE_PASSWORD
    ) {
      activeHeader = STRINGS.HEADER.MEMBER_INFO;
    } else if (
      activeRoute === ROUTES.ONLINE_BANKING ||
      activeRoute === ROUTES.FUND_METHOD ||
      activeRoute === ROUTES.FUNDING_AMOUNT ||
      activeRoute === ROUTES.SIGNATURE
    ) {
      if (fundProduct?.productName) {
        activeHeader = fundProduct.productName;
      } else {
        activeHeader = activeProduct?.productName;
      }
    } else if (
      activeRoute === ROUTES.REGISTER ||
      activeRoute === ROUTES.VERIFICATION_CODE ||
      activeRoute === ROUTES.VAULT_PASSWORD
    ) {
      activeHeader = STRINGS.HEADER.REGISTRATION;
    } else if (activeRoute === ROUTES.SIGN_IN) {
      activeHeader = STRINGS.HEADER.SIGNIN;
    } else if (
      activeRoute === ROUTES.FORGOT_PASSWORD ||
      activeRoute === ROUTES.SIGNIN_FORGOT_VERIFICATION ||
      activeRoute === ROUTES.RECOVER_PASSWORD_BY_EMAIL ||
      activeRoute === ROUTES.SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD
    ) {
      activeHeader = STRINGS.HEADER.FORGOTPASSWORD;
    } else if (
      activeProduct &&
      activeRoute !== ROUTES.MARKETPLACE &&
      activeRoute !== ROUTES.VAULT_DASHBOARD
    ) {
      activeHeader = activeProduct.productName;
    }

    let isShowVaultIcon = true;
    if (activeRoute === ROUTES.VAULT_DASHBOARD) {
      if (activeTab === 0) {
        isShowVaultIcon = false;
      }
    }

    const isShowSignOut = accessToken && !activeRoute.includes(VAULT_PASSWORD);

    return (
      <>
        {showWarning && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            title="You are almost there! For security purposes, we do not store any sensitive personal information about your co-applicant. "
            description="If you log out now, your co-applicant information will need to be re-submitted. We recommend you complete the application during this session to save time."
            toggleModal={this.toggleWarning}
            showModal={showWarning}
            popupBtnClick={this.toggleWarning}
            closeBtnClick={this.toggleWarning}
            customBtnClick={this.continueSignOut}
            customBtnText="Continue Logout"
            btnText="Complete the Application"
          />
        )}
        <header>
          <div className="[ container-fluid ] [ header-container ]">
            <div className="[ row ]">
              <div className="[ d-flex ] [ w-100 ] [ justify-content-between align-items-xl-center ] [ header-row ]">
                <div className="[ logo ]" />
                <div className="[ page-header ]">
                  {activeRoute || activeHeader !== ""
                    ? activeHeader
                    : STRINGS.HEADER.SIGNINTITLE}
                </div>

                <div className="[ d-flex flex-row d-xl-none ] [ pt-2 ] [ header-container__item vault-icon-container ]">
                  {/* CHRIS NOTE: Hacks inplace to support existing global nav bar. Should and will refactor at later date to use button group + dropdowns. */}
                  {(activeRoute === ROUTES.FUNDING_AMOUNT ||
                    activeRoute === ROUTES.FUND_METHOD) && (
                    <div
                      className={
                        showFundAccount
                          ? "[ fund-account-image opened ]"
                          : "[ fund-account-image ]"
                      }
                      onClick={showFundVaultClicked}
                      role="presentation"
                    />
                  )}
                  {activeRoute !== ROUTES.FUNDING_AMOUNT &&
                    activeRoute !== ROUTES.FUND_METHOD &&
                    !isQrKyc &&
                    isShowVaultIcon &&
                    vaultProductList &&
                    vaultProductList.length !== 0 && (
                      <span className="[ ic-circle vault-circle ]">
                        {vaultProductList.length}
                      </span>
                    )}
                  {isShowVaultIcon && !isQrKyc && (
                    <div
                      className={
                        showVault ? "[ vault-image opened ]" : "[ vault-image ]"
                      }
                      onClick={showVaultClicked}
                      role="presentation"
                    />
                  )}
                  <Dropdown onToggle={this.dropdownToggle}>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                    />
                    {isShowSignOut && (
                      <Dropdown.Menu
                        size="sm"
                        title="Navigation options"
                        className="[ global_nav_drawer ] [ signed-in ]"
                      >
                        <div className="[ global_nav_drawer__inner-container ]">
                          <div className="[ vault-header__container ]">
                            <h4 className="[ form-subtitle ]">
                              <i className="[ fas fa-bars ]" />

                              {STRINGS.HEADER.GLOBALNAV.TITLE}
                              <Dropdown.Item
                                eventKey="1"
                                className="[ d-inline p-0 ]"
                              >
                                <button
                                  type="button"
                                  className="[ close ]"
                                  onClick={this.closeMenu}
                                >
                                  <span aria-hidden="true">×</span>
                                  <span className="[ sr-only ]">Close</span>
                                </button>
                              </Dropdown.Item>
                            </h4>
                          </div>

                          {!isQrKyc && (
                            <>
                              <Dropdown.Item
                                eventKey="1"
                                onClick={this.signOut}
                              >
                                {STRINGS.HEADER.GLOBALNAV.SIGNOUT}{" "}
                                <span className="[ icon ] [ product-navigation-icon ]" />
                              </Dropdown.Item>

                              <Dropdown.Divider />

                              <Dropdown.Header>
                                {STRINGS.HEADER.GLOBALNAV.SETTINGS}
                              </Dropdown.Header>

                              <Dropdown.Item onClick={this.changePassword}>
                                {STRINGS.HEADER.GLOBALNAV.CHANGEPASSWORD}{" "}
                                <span className="[ icon ] [ product-navigation-icon ]" />
                              </Dropdown.Item>

                              <Dropdown.Divider />
                            </>
                          )}

                          <Dropdown.Header>
                            {STRINGS.HEADER.GLOBALNAV.LEGALESE}
                          </Dropdown.Header>

                          <Dropdown.Item
                            tag={Link}
                            href={FIInformation.FI_PRIVACY}
                            target="_blank"
                          >
                            {STRINGS.HEADER.GLOBALNAV.PRIVACY}{" "}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>

                          <Dropdown.Item
                            tag={Link}
                            href={FIInformation.FI_CONDITIONS}
                            target="_blank"
                          >
                            {STRINGS.HEADER.GLOBALNAV.CONDITIONS}{" "}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>

                          <Dropdown.Divider />

                          <Dropdown.Header>
                            {STRINGS.HEADER.GLOBALNAV.CONTACTUS}
                          </Dropdown.Header>

                          <Dropdown.Item
                            tag={Link}
                            href={FIInformation.FI_SITE}
                            target="_blank"
                          >
                            {STRINGS.HEADER.GLOBALNAV.COMPANYSITE}{" "}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>

                          <Dropdown.Item
                            tag={Link}
                            href={FIInformation.FI_EMAIL}
                            target="_blank"
                          >
                            {STRINGS.HEADER.GLOBALNAV.CUSTOMERSERVICE}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    )}
                    {!isShowSignOut && (
                      <Dropdown.Menu
                        size="sm"
                        title="Navigation options"
                        className="[ global_nav_drawer ] [ signed-out ]"
                      >
                        <div className="[ global_nav_drawer__inner-container ]">
                          <div className="[ vault-header__container ]">
                            <h4 className="[ form-subtitle ]">
                              <i className="[ fas fa-bars ]" />

                              {STRINGS.HEADER.GLOBALNAV.TITLE}
                              <Dropdown.Item
                                eventKey="1"
                                className="[ d-inline p-0 ]"
                              >
                                <button
                                  type="button"
                                  className="[ close ]"
                                  onClick={this.closeMenu}
                                >
                                  <span aria-hidden="true">×</span>
                                  <span className="[ sr-only ]">Close</span>
                                </button>
                              </Dropdown.Item>
                            </h4>
                          </div>

                          <Dropdown.Header>
                            {STRINGS.HEADER.GLOBALNAV.PARENT_MARKETPLACE.split(
                              "_BANKING_TYPE_"
                            ).join(bankingType)}
                          </Dropdown.Header>
                          <Dropdown.Item onClick={handleSignIn}>
                            {STRINGS.HEADER.GLOBALNAV.SIGNIN}{" "}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>
                          <Dropdown.Item onClick={handleRegister}>
                            {STRINGS.HEADER.GLOBALNAV.REGISTER}{" "}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Header>
                            {STRINGS.HEADER.GLOBALNAV.LEGALESE}
                          </Dropdown.Header>

                          <Dropdown.Item
                            tag={Link}
                            href={FIInformation.FI_PRIVACY}
                            target="_blank"
                          >
                            {STRINGS.HEADER.GLOBALNAV.PRIVACY}{" "}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>

                          <Dropdown.Item
                            tag={Link}
                            href={FIInformation.FI_CONDITIONS}
                            target="_blank"
                          >
                            {STRINGS.HEADER.GLOBALNAV.CONDITIONS}{" "}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>

                          <Dropdown.Divider />

                          <Dropdown.Header>
                            {STRINGS.HEADER.GLOBALNAV.CONTACTUS}
                          </Dropdown.Header>
                          <Dropdown.Item
                            tag={Link}
                            href={FIInformation.FI_SITE}
                            target="_blank"
                          >
                            {STRINGS.HEADER.GLOBALNAV.COMPANYSITE}{" "}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>

                          <Dropdown.Item
                            tag={Link}
                            href={FIInformation.FI_EMAIL}
                            target="_blank"
                          >
                            {STRINGS.HEADER.GLOBALNAV.CUSTOMERSERVICE}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Header>
                            {STRINGS.HEADER.GLOBALNAV.PARENT_MARKETPLACE.split(
                              "_BANKING_TYPE_"
                            ).join(
                              bankingType === AppConstants.BANKING_TYPE.PERSONAL
                                ? AppConstants.BANKING_TYPE.BUSINESS
                                : AppConstants.BANKING_TYPE.PERSONAL
                            )}
                          </Dropdown.Header>
                          <Dropdown.Item onClick={switchBanking}>
                            {STRINGS.HEADER.GLOBALNAV.GO_TO_OTHER_MARKETPLACE.split(
                              "_BANKING_TYPE_"
                            ).join(
                              bankingType === AppConstants.BANKING_TYPE.PERSONAL
                                ? AppConstants.BANKING_TYPE.BUSINESS
                                : AppConstants.BANKING_TYPE.PERSONAL
                            )}{" "}
                            <span className="[ icon ] [ product-navigation-icon ]" />
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </div>
                <div className="[ d-none d-xl-flex flex-row ] [ mr-xl-5 ] [ header-container__item drop-mobile-menu ]">
                  <div className="[ pt-3 ] [ welcome-user-text ]">
                    <div className="[ icon welcome-user-icon ]">
                      {STRINGS.HEADER.WELCOME}
                    </div>
                  </div>
                  {isShowSignOut && (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        className="[ pt-3 ]"
                      />

                      <Dropdown.Menu className="[ signed-in ]">
                        <Dropdown.Item eventKey="1" onClick={this.signOut}>
                          {STRINGS.HEADER.GLOBALNAV.SIGNOUT}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.SETTINGS}
                        </Dropdown.Header>

                        <Dropdown.Item onClick={this.changePassword}>
                          {STRINGS.HEADER.GLOBALNAV.CHANGEPASSWORD}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.LEGALESE}
                        </Dropdown.Header>

                        <Dropdown.Item
                          tag={Link}
                          href={FIInformation.FI_PRIVACY}
                          target="_blank"
                        >
                          {STRINGS.HEADER.GLOBALNAV.PRIVACY}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Item
                          tag={Link}
                          href={FIInformation.FI_CONDITIONS}
                          target="_blank"
                        >
                          {STRINGS.HEADER.GLOBALNAV.CONDITIONS}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.CONTACTUS}
                        </Dropdown.Header>
                        <Dropdown.Item
                          tag={Link}
                          href={FIInformation.FI_SITE}
                          target="_blank"
                        >
                          {STRINGS.HEADER.GLOBALNAV.COMPANYSITE}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Item
                          tag={Link}
                          href={FIInformation.FI_EMAIL}
                          target="_blank"
                        >
                          {STRINGS.HEADER.GLOBALNAV.CUSTOMERSERVICE}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {!isShowSignOut && (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        className="[ pt-3 ]"
                      />

                      <Dropdown.Menu className="[ signed-out ]">
                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.PARENT_MARKETPLACE.split(
                            "_BANKING_TYPE_"
                          ).join(bankingType)}
                        </Dropdown.Header>
                        <Dropdown.Item onClick={handleSignIn}>
                          {STRINGS.HEADER.GLOBALNAV.SIGNIN}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleRegister}>
                          {STRINGS.HEADER.GLOBALNAV.REGISTER}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>
                        <Dropdown.Divider />

                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.LEGALESE}
                        </Dropdown.Header>

                        <Dropdown.Item
                          tag={Link}
                          href={FIInformation.FI_PRIVACY}
                          target="_blank"
                        >
                          {STRINGS.HEADER.GLOBALNAV.PRIVACY}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Item
                          tag={Link}
                          href={FIInformation.FI_CONDITIONS}
                          target="_blank"
                        >
                          {STRINGS.HEADER.GLOBALNAV.CONDITIONS}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.CONTACTUS}
                        </Dropdown.Header>
                        <Dropdown.Item
                          tag={Link}
                          href={FIInformation.FI_SITE}
                          target="_blank"
                        >
                          {STRINGS.HEADER.GLOBALNAV.COMPANYSITE}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Item
                          tag={Link}
                          href={FIInformation.FI_EMAIL}
                          target="_blank"
                        >
                          {STRINGS.HEADER.GLOBALNAV.CUSTOMERSERVICE}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Divider />
                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.PARENT_MARKETPLACE.split(
                            "_BANKING_TYPE_"
                          ).join(
                            bankingType === AppConstants.BANKING_TYPE.PERSONAL
                              ? AppConstants.BANKING_TYPE.BUSINESS
                              : AppConstants.BANKING_TYPE.PERSONAL
                          )}
                        </Dropdown.Header>
                        <Dropdown.Item onClick={switchBanking}>
                          {STRINGS.HEADER.GLOBALNAV.GO_TO_OTHER_MARKETPLACE.split(
                            "_BANKING_TYPE_"
                          ).join(
                            bankingType === AppConstants.BANKING_TYPE.PERSONAL
                              ? AppConstants.BANKING_TYPE.BUSINESS
                              : AppConstants.BANKING_TYPE.PERSONAL
                          )}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              </div>
            </div>
          </div>
          {popUpVault}
          {!isQrKyc && (
            <div className="[ breadcrumb-container ]">
              <Breadcrumbs
                WrapperComponent={(wrappedCompProps) => (
                  <ol className="[ breadcrumb ]">
                    {wrappedCompProps.children}
                  </ol>
                )}
                ActiveLinkComponent={(activeLinkCompProps) => (
                  <li className="[ breadcrumb-icon ] [ active ]">
                    {activeLinkCompProps.children}
                  </li>
                )}
                LinkComponent={(linkCompProps) => {
                  return linkCompProps.children.props.children ===
                    "Verification Code" ? (
                    ""
                  ) : (
                    <li className="[ breadcrumb-icon ]">
                      {linkCompProps.children}
                    </li>
                  );
                }}
                mappedRoutes={breadcrumbs}
                routeMatcherRegex={AppConstants.HEADER.matchRoutePattern}
              />

              {location.pathname === ROUTES.MARKETPLACE && (
                <>
                  {/* CHRIS: Apply nav button for business / personal when mobile */}
                  <div className="[ fi-brand__cta-container ]">
                    <h3 className="[ cta__header ]">
                      {STRINGS.MARKETPLACE.HEADER.CARD.TITLE.split(
                        "_BANKING_TYPE_"
                      ).join(
                        bankingType === AppConstants.BANKING_TYPE.PERSONAL
                          ? AppConstants.BANKING_TYPE.BUSINESS
                          : AppConstants.BANKING_TYPE.PERSONAL
                      )}
                    </h3>
                    <p className="[ cta__body ] [ cta__body-1 ]">
                      {STRINGS.MARKETPLACE.HEADER.CARD.BODY.TITLE.split(
                        "_BANKING_TYPE_"
                      ).join(
                        bankingType === AppConstants.BANKING_TYPE.PERSONAL
                          ? AppConstants.BANKING_TYPE.BUSINESS
                          : AppConstants.BANKING_TYPE.PERSONAL
                      )}
                    </p>
                    <button
                      type="button"
                      className="[ cta__primary-action-call btn info-btn ]"
                      onClick={switchBanking}
                    >
                      {STRINGS.MARKETPLACE.HEADER.CARD.BODY.SWITCH_MOBILE.split(
                        "_BANKING_TYPE_"
                      ).join(
                        bankingType === AppConstants.BANKING_TYPE.PERSONAL
                          ? AppConstants.BANKING_TYPE.BUSINESS
                          : AppConstants.BANKING_TYPE.PERSONAL
                      )}
                    </button>
                  </div>
                  {/* CHRIS: END Apply nav button for business / personal when mobile */}
                </>
              )}
            </div>
          )}
        </header>
      </>
    );
  }
}

Header.propTypes = {
  showVault: PropTypes.bool,
  showVaultClicked: PropTypes.func,
  activeRoute: PropTypes.string,
  doLogoutAction: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]).isRequired,
  activeTab: PropTypes.number.isRequired,
  FundState: PropTypes.oneOfType([PropTypes.object]),
  // getActiveProduct: PropTypes.func,
  fundProduct: PropTypes.oneOfType([PropTypes.object]),
  showFundAccount: PropTypes.bool,
  showFundVaultClicked: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  inSessionJointApplicant: PropTypes.bool,
  handleSignIn: PropTypes.func,
  handleRegister: PropTypes.func,
};
Header.defaultProps = {
  history: {},
  showVault: false,
  showVaultClicked: () => {},
  activeRoute: "",
  doLogoutAction: () => {},
  FundState: {},
  fundProduct: {},
  showFundAccount: false,
  showFundVaultClicked: () => {},
  products: [],
  inSessionJointApplicant: false,
  handleSignIn: () => {},
  handleRegister: () => {},
};

CustomToggle.propTypes = { onClick: PropTypes.func };
CustomToggle.defaultProps = {
  onClick: () => {},
};

const mapStateToProps = (state) => ({
  vaultProductList: state.VaultReducer.vaultProductList,
  activeTab: state.MarketplaceReducer.activeTab,
  FundState: state.FundReducer,
  fundProduct: state.VaultReducer.fundProduct,
  products: state.VaultReducer.vaultProductList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  bankingType: state.MarketplaceReducer.bankingType,
  finInfo: state.MarketplaceReducer.finInfo,
  isQrKyc: state.ApplicationReducer.isQrKyc,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doLogoutAction: doLogout,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
