import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";
import { HTTP_STATUS } from "Communication/Constants";
import qr from "query-string";
import Signin from "./Signin";
import {
  doSignin,
  doReset,
  doForgotPassword,
  addUsername,
} from "./Actions/SigninAction";
import STRINGS from "Constants/Strings";
import { getLoanProduct } from "../LoanDetails/Actions/LoanDetailsAction";
import {
  getFIInfo,
  getProductList,
  storeFilterBankingType,
  updateVaultProductList,
} from "../MarketPlace/Action/MarketPlaceAction";
import { doGetProvinceList } from "../AboutYou/Actions/AboutYouActions";
import { getApplicantType } from "../JointApplicant/Actions/JointApplicantAction";
import {
  getOccupationCodes,
  getReasonCodes,
  setKycFlag,
  toggleScanFlag,
} from "../../Redux/Actions/ApplicationAction";
import { getCentral } from "../../Redux/Actions/LoginAction";
import { addApplicant } from "../Inverite/Actions/InveriteAction";
import doCheckExistingUser from "../Register/Actions/ExistingUserActions";
import AppConstants from "../../Constants/AppConstants";
import {
  DEBIT_CARD_ONLINE_BANKING,
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  S_APPLICANT_ID,
  S_BANKING_TYPE,
  S_EMAIL,
  S_INSTNT_FINGER_PRINT,
  S_INSTNT_TXN_ID,
  S_MEMBER_VAULT_UPDATE,
  S_MSISDN,
  S_QR_KYC,
  TOKEN_KEY,
} from "../../Communication/Constants";
import { Modal } from "react-bootstrap";
import $FIInformation from "../../Constants/FIInformation";
import MODALMESSAGES from "../../Constants/Messages";
import PopupModal from "../../Components/Common/PopupModal/PopupModal";

class SigninContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      isChecked: false,
      showModal: false,
      dontHaveBankId: false,
      password: "",
      bankId: "",
      showPassword: false,
      emailCheckOnly: false,
      showReset: false,
      showInvalidBankId: false,
    };
  }

  componentDidMount() {
    // const { doResetStore } = this.props;
    // doResetStore();
    // sessionStorage.removeItem(
    //   STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
    // );
    // sessionStorage.removeItem(STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID);
    console.log("componentDidMount", this.props.location);
    const {
      doGetCentralInfo,
      doAddApplicant,
      prepareVaultProductList,
      doGetLoanProduct,
      doGetFinInfo,
      doGetProvinceList,
      doGetApplicantType,
      doGetOccupationCodes,
      doGetReasonCodes,
      doGetProductList,
      doStoreFilterBankingType,
      doToggleScanFlag,
      doSetKycFlag,
    } = this.props;
    const authCode = this.getAuthCode();
    const token = this.getToken();
    console.log("auth_code", authCode);
    if (authCode) {
      doGetCentralInfo(authCode, (res) => {
        console.log(res);
        if (res.status === HTTP_STATUS.OK) {
          sessionStorage.setItem(S_MEMBER_VAULT_UPDATE, true);
          const productId = this.getProductId();
          doStoreFilterBankingType(AppConstants.BANKING_TYPE.PERSONAL);
          if (res.data.applicantId && res.data.newAccount) {
            doAddApplicant(
              {
                applicantId: res.data.applicantId,
                relationship: "self",
              },
              (response) => {
                console.log(response);
                if (response.status === HTTP_STATUS.OK) {
                  sessionStorage.setItem(S_APPLICANT_ID, res.data.applicantId);
                  sessionStorage.setItem(S_EMAIL, res.data.email);
                  sessionStorage.setItem(S_MSISDN, res.data.msisdn);
                  doGetProductList();
                  doGetLoanProduct();
                  doGetFinInfo();
                  doGetProvinceList();
                  doGetApplicantType();
                  doGetOccupationCodes();
                  doGetReasonCodes();
                  prepareVaultProductList(true, authCode, productId);
                }
              }
            );
          } else {
            doGetProductList();
            doGetLoanProduct();
            doGetFinInfo();
            doGetProvinceList();
            doGetApplicantType();
            doGetOccupationCodes();
            doGetReasonCodes();
            prepareVaultProductList(true, authCode, productId);
          }
        }
      });
    } else if (token) {
      const { vaultProductList, doUpdateVaultProductList } = this.props;
      doToggleScanFlag(true);
      if (vaultProductList && vaultProductList.length > 0) {
        vaultProductList[0].kycStatus = "mobileStarted";
        doUpdateVaultProductList(vaultProductList, () => {
          doSetKycFlag(true);
        });
      } else {
        doSetKycFlag(true);
      }
      sessionStorage.setItem(S_MEMBER_VAULT_UPDATE, true);
      sessionStorage.setItem(TOKEN_KEY, token);
      sessionStorage.setItem(EXPIRES_AT, this.getExpiresAt());
      sessionStorage.setItem(REFRESH_EXPIRES_AT, this.getRefreshExpiresAt());
      sessionStorage.setItem(
        S_BANKING_TYPE,
        AppConstants.BANKING_TYPE.PERSONAL
      );
      doGetLoanProduct();
      doGetFinInfo();
      doGetProvinceList();
      doGetApplicantType();
      doGetOccupationCodes();
      doGetReasonCodes();
      prepareVaultProductList(true, null, null, true);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      signInState,
      doToggleScanFlag,
      scanDocument,
      doSetKycFlag,
      isQrKyc,
    } = this.props;
    const { isChecked, username } = this.state;
    console.log("componentDidUpdate", isQrKyc, prevProps !== this.props);
    if (prevProps !== this.props) {
      const sessionQrKycFlag = sessionStorage.getItem(S_QR_KYC);
      if (!isQrKyc && sessionQrKycFlag && sessionQrKycFlag === "true") {
        doSetKycFlag(true);
      }
      if ((isQrKyc || sessionQrKycFlag === "true") && !scanDocument) {
        doToggleScanFlag(true);
      }
      if (
        signInState.success &&
        signInState.success.status === HTTP_STATUS.OK
      ) {
        if (isChecked) {
          localStorage.removeItem("username");
          localStorage.setItem("checkbox", isChecked);
          localStorage.setItem("username", username);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("checkbox");
        }
      }
    }
  }

  getAuthCode = () => {
    const params = qr.parse(this.props.location.search);
    return params.auth_code;
  };

  getToken = () => {
    const params = qr.parse(this.props.location.search);
    return params.token;
  };

  getExpiresAt = () => {
    const params = qr.parse(this.props.location.search);
    return params.expiresAt;
  };

  getRefreshExpiresAt = () => {
    const params = qr.parse(this.props.location.search);
    return params.refreshExpiresAt;
  };

  getProductId = () => {
    const params = qr.parse(this.props.location.search);
    return params.product_id;
  };

  toggleShowBankId = (param) => {
    this.setState({ showBankId: param });
  };

  handleSignin = (userDetails, paramExistingQuery) => {
    const {
      doSignIn,
      prepareVaultProductList,
      doGetLoanProduct,
      doGetFinInfo,
      doGetProvinceList,
      doGetApplicantType,
      doGetOccupationCodes,
      doGetReasonCodes,
      doStoreFilterBankingType,
      finInfo,
      doCheckExistingUser,
      bankingType,
      doAddUserName,
      doForgotPassword,
    } = this.props;
    const {
      showModal,
      bankId,
      dontHaveBankId,
      username,
      previousExistingQueryCheck,
    } = this.state;
    console.log(
      "handleSignin",
      dontHaveBankId,
      showModal,
      bankId,
      userDetails,
      previousExistingQueryCheck
    );
    if (showModal && dontHaveBankId) {
      this.setState({
        errorBankId: "",
        showBankId: false,
        showModal: false,
        bankId: "",
      });
    } else if (showModal && !bankId) {
      this.setState({
        errorBankId:
          finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
            ? STRINGS.SIGNIN.ERRORS.DEBIT_CARD_NUMBER_MISSING
            : STRINGS.SIGNIN.ERRORS.MEMBER_NUMBER_MISSING,
      });
    } else if (userDetails.emailCheckOnly) {
      if (userDetails.bankId) {
        doCheckExistingUser(
          { credential: username, bankingType, bankId },
          (res) => {
            this.setState({ showModal: false }, () => {
              if (
                res.data.autoCreated &&
                res.data.status === "P" &&
                res.data.size === 1
              ) {
                console.log(res);
                doAddUserName(username);
                const jointApplicationId = sessionStorage.getItem(
                  STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
                );
                const jointApplicantSlot = sessionStorage.getItem(
                  STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
                );
                const userDetails = {
                  credential: username,
                  otpTo: STRINGS.FORGOTPASSWORD.OTP_TO.EMAIL,
                  bankingType,
                  bankId,
                  jointApplicationId,
                  jointApplicantSlot,
                };
                console.log("userDetails", userDetails);
                doForgotPassword(userDetails, (forgotResponse) => {
                  console.log(forgotResponse);
                  if (forgotResponse.status === HTTP_STATUS.OK) {
                    this.setState({
                      showReset: true,
                      bankId: "",
                    });
                  }
                });
              } else if (previousExistingQueryCheck && res.data.size === 0) {
                this.setState({ showInvalidBankId: true, bankId: "" });
              } else {
                if (res.data.size === 0) {
                  this.setState({
                    showPassword: true,
                    emailCheckOnly: false,
                    bankId: "",
                  });
                } else {
                  this.setState({ showPassword: true, emailCheckOnly: false });
                }
              }
            });
          }
        );
      } else {
        this.setState({
          username: userDetails.credential,
          showBankId: false,
          showModal: true,
          dontHaveBankId: false,
          emailCheckOnly: true,
          previousExistingQueryCheck: paramExistingQuery,
        });
      }
    } else {
      this.setState({
        username: userDetails.credential,
        password: userDetails.password,
        isChecked: userDetails.isChecked,
      });
      this.setState(
        {
          showBankId: false,
          showModal: false,
          dontHaveBankId: false,
          bankId: "",
        },
        () => {
          if (bankId) {
            userDetails.bankId = bankId;
          }
          doSignIn(userDetails, (response) => {
            console.log("login", response);
            if (response.status === HTTP_STATUS.OK) {
              if (response?.data?.duplicate) {
                this.setState({
                  showBankId: false,
                  showModal: true,
                  dontHaveBankId: false,
                });
              } else {
                sessionStorage.removeItem(S_INSTNT_FINGER_PRINT);
                sessionStorage.removeItem(S_INSTNT_TXN_ID);
                sessionStorage.setItem(S_MEMBER_VAULT_UPDATE, true);
                doStoreFilterBankingType(
                  userDetails.bankingType || AppConstants.BANKING_TYPE.PERSONAL
                );
                sessionStorage.setItem(S_BANKING_TYPE, userDetails.bankingType);
                doGetLoanProduct();
                doGetFinInfo();
                doGetProvinceList();
                doGetApplicantType();
                doGetOccupationCodes();
                doGetReasonCodes();
                prepareVaultProductList(true);
              }
            }
          });
        }
      );
    }
  };

  toggleModal = (dontHaveBankId) => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleCloseBankIdError = () => {
    this.setState({
      showInvalidBankId: false,
    });
  };

  changeBankIdHandler = (event) => {
    const re = /^[0-9\b]+$/;

    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ bankId: event.target.value, errorBankId: "" });
    }
  };

  render() {
    const {
      history,
      doCheckExistingUser,
      finInfo,
      doAddUserName,
      doForgotPassword,
    } = this.props;
    const {
      showBankId,
      showModal,
      dontHaveBankId,
      errorBankId,
      username,
      password,
      bankId,
      showPassword,
      emailCheckOnly,
      showReset,
      showInvalidBankId,
    } = this.state;
    return (
      <>
        {showInvalidBankId && (
          <PopupModal
            type={STRINGS.SIGNIN.ERRORS.MODAL_TITLE_INFO}
            description={
              <>
                <p>
                  {finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                    ? STRINGS.SIGNIN.ERRORS.DEBIT_CARD_NUMBER_ERROR
                    : STRINGS.SIGNIN.ERRORS.MEMBER_NUMBER_ERROR}
                </p>
                <p>{STRINGS.SIGNIN.ERRORS.TYPO_CHECK}</p>
                <p>{STRINGS.SIGNIN.ERRORS.ONLINE_BANKING_LOGIN_REMINDER}</p>
              </>
            }
            toggleModal={this.handleCloseBankIdError}
            showModal={showInvalidBankId}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <Signin
          handleSignin={this.handleSignin}
          history={history}
          doCheckExistingUser={doCheckExistingUser}
          finInfo={finInfo}
          doAddUserName={doAddUserName}
          doForgotPassword={doForgotPassword}
          showBankId={showBankId}
          toggleShowBankId={this.toggleShowBankId}
          showPassword={showPassword}
          showReset={showReset}
        />
        <Modal
          show={showModal}
          onHide={() => {
            this.toggleModal();
          }}
          className={"[ popup-modal__container ]"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" // this prevents the pop up from being closed when clicked outside of the pop up
          keyboard={false}
        >
          <Modal.Header className="[ popup-modal__header ]" closeButton={false}>
            <Modal.Title>
              {dontHaveBankId ? "Info" : STRINGS.SIGNIN.ERRORS.MODAL_TITLE_INFO}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!dontHaveBankId && (
              <div className="[ form-container ]">
                <div className="[ mb-3 ]">
                  <p>
                    {
                      STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL
                        .PROFILE_UNCERTAINTY
                    }
                  </p>
                </div>
                <div className="[ mb-3 ]">
                  {STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.INSTRUCTION}{" "}
                  {finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                    ? STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.LAST_4_DIGITS
                    : `${STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.MEMBER_NUMBER} ${STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.INSTRUCTION1}`}
                </div>
                <div className="[ mb-3 ]">
                  <label htmlFor="bankId" className="[ form-label ]">
                    {finInfo.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                      ? STRINGS.SIGNIN.FORM.BANK_ID_LABEL_PAN
                      : STRINGS.SIGNIN.FORM.BANK_ID_LABEL_MEMBER_NO}
                  </label>
                  <input
                    id="bankId"
                    type="text"
                    name="bankId"
                    className={
                      errorBankId
                        ? "[ form-control ] [ is-invalid ]"
                        : "[ form-control ]"
                    }
                    placeholder={
                      finInfo.onlineBankingUserName ===
                      DEBIT_CARD_ONLINE_BANKING
                        ? STRINGS.SIGNIN.FORM.BANK_ID_LABEL_PAN
                        : STRINGS.SIGNIN.FORM.BANK_ID_LABEL_MEMBER_NO
                    }
                    value={bankId}
                    onChange={this.changeBankIdHandler}
                    maxLength={
                      finInfo.onlineBankingUserName ===
                      DEBIT_CARD_ONLINE_BANKING
                        ? 4
                        : 10
                    }
                    aria-describedby="bankIdHelp"
                  />
                  {errorBankId && (
                    <div className="invalid-feedback">{errorBankId}</div>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
            <>
              <div className="[ row ]">
                <div className="[ col-6 ]">
                  {!dontHaveBankId && (
                    <button
                      type="button"
                      className="[ btn submit-btn ] [ w-100 ]"
                      onClick={() => this.toggleModal(true)}
                    >
                      {STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.CANCELBTN}
                    </button>
                  )}
                </div>
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className="[ btn submit-btn ] [ w-100 ]"
                    onClick={() =>
                      this.handleSignin({
                        credential: username,
                        password,
                        bankId,
                        bankingType: AppConstants.BANKING_TYPE.PERSONAL,
                        checkDuplicate: finInfo?.allowDuplicateProfile,
                        emailCheckOnly,
                      })
                    }
                  >
                    {STRINGS.SIGNIN.DUPLICATE_CREDENTIAL_MODAL.CONTINUEBTN}
                  </button>
                </div>
              </div>
            </>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doSignIn: doSignin,
      doResetStore: doReset,
      doGetLoanProduct: getLoanProduct,
      doGetFinInfo: getFIInfo,
      doGetProvinceList,
      doGetApplicantType: getApplicantType,
      doGetOccupationCodes: getOccupationCodes,
      doGetReasonCodes: getReasonCodes,
      doGetCentralInfo: getCentral,
      doAddApplicant: addApplicant,
      doCheckExistingUser,
      doForgotPassword,
      doAddUserName: addUsername,
      doGetProductList: getProductList,
      doStoreFilterBankingType: storeFilterBankingType,
      doToggleScanFlag: toggleScanFlag,
      doSetKycFlag: setKycFlag,
      doUpdateVaultProductList: updateVaultProductList,
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  signInState: state.SigninReducer,
  finInfo: state.MarketplaceReducer.finInfo,
  scanDocument: state.ApplicationReducer.scanDocument,
  isQrKyc: state.ApplicationReducer.isQrKyc,
  vaultProductList: state.VaultReducer.vaultProductList,
  bankingType: state.MarketplaceReducer.bankingType,
});

SigninContainer.propTypes = {
  doSignIn: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]),
  signInState: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  prepareVaultProductList: PropTypes.func.isRequired,
  doGetCentralInfo: PropTypes.func.isRequired,
  doAddApplicant: PropTypes.func.isRequired,
  doGetLoanProduct: PropTypes.func.isRequired,
  doGetFinInfo: PropTypes.func.isRequired,
  doGetProvinceList: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  doGetOccupationCodes: PropTypes.func.isRequired,
  doGetReasonCodes: PropTypes.func.isRequired,
  doCheckExistingUser: PropTypes.func.isRequired,
  doForgotPassword: PropTypes.func.isRequired,
  doAddUserName: PropTypes.func.isRequired,
  finInfo: PropTypes.oneOfType([PropTypes.object]),
};
SigninContainer.defaultProps = {
  history: {},
  finInfo: {},
};
const SigninWrapper = withVaultHOC(SigninContainer);

export default connect(mapStateToProps, mapDispatchToProps)(SigninWrapper);
