import { Modal } from "react-bootstrap";
import $FIInformation from "../../Constants/FIInformation";
import { DEBIT_CARD_ONLINE_BANKING } from "../../Communication/Constants";
import AppConstants from "../../Constants/AppConstants";
import React, { Component } from "react";
import STRINGS from "../../Constants/Strings";

class BankModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankId: "",
    };
  }

  changeHandler = (event) => {
    if (event.target.name === "bankId") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.bankId !== this.props.bankId) {
      if (!this.props.bankId) {
        this.setState({ bankId: "" });
      }
    }
  }

  continueHandler = () => {
    const { bankId } = this.state;
    const {
      modalType,
      continueHandler,
      email,
      password,
      finInfo,
      setBankId,
    } = this.props;
    if (modalType === "SIGNIN") {
      continueHandler({
        credential: email,
        password,
        bankId,
        bankingType: AppConstants.BANKING_TYPE.PERSONAL,
        checkDuplicate: finInfo?.allowDuplicateProfile,
      });
    } else {
      setBankId(bankId, () => continueHandler(email, bankId));
    }
  };

  render() {
    const { bankId } = this.state;
    const {
      showModal,
      toggleModal,
      finInfo,
      dontHaveBankId,
      errorBankId,
      username,
      password,
      modalType,
    } = this.props;
    return (
      <Modal
        show={showModal}
        onHide={toggleModal}
        className={"[ popup-modal__container ]"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static" // this prevents the pop up from being closed when clicked outside of the pop up
        keyboard={false}
      >
        <Modal.Header className="[ popup-modal__header ]" closeButton={false}>
          <Modal.Title>{dontHaveBankId ? "Info" : "Hmmm..."}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!dontHaveBankId && (
            <div className="[ form-container ]">
              <div className="[ mb-3 ]">
                We need more information to help us identify your{" "}
                {$FIInformation.FI_APPLICATION_NAME} profile
              </div>
              <div className="[ mb-3 ]">
                Please enter your{" "}
                {finInfo?.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                  ? "last 4 digits"
                  : "member number"}{" "}
                that is associated with your{" "}
                {$FIInformation.FI_APPLICATION_NAME} profile
              </div>
              <input
                type="text"
                name="bankId"
                className={
                  errorBankId
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                placeholder={
                  finInfo?.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                    ? STRINGS.SIGNIN.FORM.BANK_ID_LABEL_PAN
                    : STRINGS.SIGNIN.FORM.BANK_ID_LABEL_MEMBER_NO
                }
                value={bankId}
                onChange={this.changeHandler}
                maxLength={
                  finInfo?.onlineBankingUserName === DEBIT_CARD_ONLINE_BANKING
                    ? 4
                    : 10
                }
              />

              <div className="[ error-msg ]">{errorBankId}</div>
              <div className="[ mb-3 ]">
                We need more information to help us identify your{" "}
                {$FIInformation.FI_APPLICATION_NAME} profile
              </div>
            </div>
          )}
          {dontHaveBankId && (
            <div className="[ mb-3 ]">
              Placeholder on what the user should do to be able to login.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
          <>
            {!dontHaveBankId && (
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div>
                      <button
                        type="button"
                        className="[ btn submit-btn ] [ w-100 ]"
                        onClick={() => toggleModal(true)}
                      >
                        I don't have a{" "}
                        {finInfo?.onlineBankingUserName ===
                        DEBIT_CARD_ONLINE_BANKING
                          ? "PAN"
                          : "member number"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="[ mb-3 ]">
              <div className="[ row ]">
                <div className="[ col-12 ]">
                  <div>
                    <button
                      type="button"
                      className="[ btn submit-btn ] [ w-100 ]"
                      onClick={this.continueHandler}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BankModal;
