import {
  S_BANKING_TYPE,
  S_BANKING_TYPE_SWITCH,
  TOKEN_KEY,
} from "../Communication/Constants";

export const logout = (jointInfo) => {
  console.log("logout", jointInfo);
  let n = sessionStorage.length;
  const bankingType = sessionStorage.getItem(S_BANKING_TYPE);
  const bankingTypeSwitch = sessionStorage.getItem(S_BANKING_TYPE_SWITCH);
  while (n--) {
    let key = sessionStorage.key(n);
    sessionStorage.removeItem(key);
  }
  if (bankingType) {
    sessionStorage.setItem(S_BANKING_TYPE, bankingType);
  }
  if (bankingTypeSwitch) {
    sessionStorage.setItem(S_BANKING_TYPE_SWITCH, bankingTypeSwitch);
  }
  if (jointInfo?.jointApplicationId) {
    console.log("saving joint info");
    sessionStorage.setItem(
      "JOINT_APPLICANT_APPLICATION_ID",
      jointInfo.jointApplicationId
    );
    sessionStorage.setItem(
      "JOINT_APPLICANT_SLOTID",
      jointInfo.jointApplicantSlot
    );
  }
};

export const isLogin = () => {
  if (sessionStorage.getItem(TOKEN_KEY)) {
    return true;
  }

  return false;
};
