import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import checkErrors from "Utils/InputValidator";
import { doGetIdType } from "./Actions/AboutYouActions";
import { Modal } from "react-bootstrap";
import { closeTab } from "../../Utils/CommonUtilities";
import {
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  S_EMAIL,
  S_INSTNT_FINGER_PRINT,
  S_INSTNT_TXN_ID,
  S_MSISDN,
  S_QR_KYC,
  TOKEN_KEY,
} from "../../Communication/Constants";
import { setKycFlag } from "../../Redux/Actions/ApplicationAction";
import * as ROUTES from "../../Constants/Routes";

class IdSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      govID: "",
      idError: [],
      idList: [],
      showHelp: false,
      qrKycStart: true,
      qrCodeDifferent: false,
    };
  }

  componentDidMount() {
    const {
      doGetIdList,
      vaultProductList,
      doUpdateProductToVault,
    } = this.props;
    doGetIdList();
    console.log("componentDidMount id selection", vaultProductList);
    if (vaultProductList[0].kycStatus === "generate") {
      vaultProductList[0].kycStatus = "mobileStarted";
      doUpdateProductToVault(vaultProductList);
    }
  }

  componentDidUpdate(prevProps) {
    const { idListResponse } = this.props;
    if (prevProps.idListResponse !== idListResponse) {
      if (!idListResponse.error) {
        this.setListFromS3(idListResponse.idTypes);
      }
    }
  }

  setListFromS3 = (list) => {
    this.setState({ idList: list });
  };

  changeHandler = (event) => {
    console.log("id selected", event.target.value);
    this.setState({ govID: event.target.value, idError: [] });
  };

  verifyData = () => {
    const { govID } = this.state;
    const idErrorsList = checkErrors(govID, VALIDATIONS.ABOUTYOU.ID);
    if (idErrorsList && idErrorsList.length > 0) {
      this.setState({ idError: idErrorsList });
      return false;
    }

    return true;
  };

  submitData = () => {
    if (this.verifyData()) {
      const sessionFingerPrint = sessionStorage.getItem(S_INSTNT_FINGER_PRINT);
      console.log(`sessionFingerPrint[${sessionFingerPrint}]`);
      if (!sessionFingerPrint) {
        const fingerprint = document.getElementById("fingerprint_txt");
        console.log("fingerprint", fingerprint);
        if (fingerprint?.value) {
          console.log("fingerprint value", fingerprint.value);
          sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint.value);
        } else {
          sessionStorage.setItem(
            S_INSTNT_FINGER_PRINT,
            window.instnt?.fingerprint_txt
          );
        }
      }
      const { govID } = this.state;
      const { idSelectionHandler } = this.props;
      const idType = this.getIdType(govID);
      idSelectionHandler(govID, idType);
    }
  };

  getIdType = (idName) => {
    const { idList } = this.state;
    let selectedID = null;
    if (idList && idList.length > 0) {
      selectedID = idList.find((elem) => {
        return elem.description === idName;
      });
    }
    return selectedID ? selectedID.type : null;
  };

  showHelp = (flag) => {
    this.setState({ showHelp: flag });
  };

  confirmCode = (codeConfirmed) => {
    const {
      vaultProductList,
      doUpdateProductToVault,
      doSetKycFlag,
      doUpdateVaultProductList,
    } = this.props;
    if (codeConfirmed) {
      vaultProductList[0].kycStatus = "mobileInProgress";
      vaultProductList[0].kycInsntTxnId = sessionStorage.getItem(
        S_INSTNT_TXN_ID
      );
      doUpdateProductToVault(vaultProductList, () => {
        this.setState({ qrKycStart: false, qrCodeDifferent: false });
      });
    } else {
      vaultProductList[0].kycStatus = "started";
      doUpdateProductToVault(vaultProductList, () => {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(S_EMAIL);
        sessionStorage.removeItem(S_MSISDN);
        sessionStorage.removeItem(EXPIRES_AT);
        sessionStorage.removeItem(REFRESH_EXPIRES_AT);
        doSetKycFlag(false);
        doUpdateVaultProductList([]);
        this.setState({ qrKycStart: false, qrCodeDifferent: true });
      });
    }
  };

  render() {
    const {
      govID,
      idError,
      idList,
      showHelp,
      qrKycStart,
      qrCodeDifferent,
    } = this.state;
    const { isQrKyc, vaultProductList, getActiveProduct, history } = this.props;
    console.log("qrkyc", qrKycStart, isQrKyc);
    const activeProduct = getActiveProduct(vaultProductList);
    const className = ["form-control", "select__dropdown"];
    if (govID) {
      className.push("active");
    } else {
      className.push("default");
    }

    let selectIdListElements = [];
    if (idList && idList.length > 0) {
      selectIdListElements = idList.map((elem) => {
        return (
          <option
            value={elem.description}
            key={elem.description + elem.code}
            className="[ dropdown__option ]"
          >
            {elem.description}
          </option>
        );
      });
    }
    const defaultOpt = (
      <option
        value=""
        key="default"
        className="[ default ] [ dropdown__option ]"
      >
        {STRINGS.ABOUTYOU.PARTONE.DEFAULTIDPLACEHOLDER}
      </option>
    );
    selectIdListElements.splice(0, 0, defaultOpt);

    if (idError && idError.length > 0) {
      className.push("invalid-input");
    }

    const selectElement = (
      <select
        value={govID}
        className={className.join(" ")}
        onChange={this.changeHandler}
      >
        {selectIdListElements}
      </select>
    );

    return (
      <>
        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTONE.SUBTITILE}
          </div>
          <div className="[ form-description ]">
            {STRINGS.ABOUTYOU.PARTONE.ABOUTYOUINFO}
          </div>
          <div className="[ mb-3 ]">
            <div
              className="[ card-text ]"
              style={{ textDecoration: "underline" }}
              onClick={this.showHelp}
            >
              <span>{STRINGS.ABOUTYOU.PARTONE.ID_COMPATIBLE}</span>
            </div>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTONE.LABEL}
            </div>
            {selectElement}
            <div className="[ error-msg ]">{idError[0]}</div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.submitData}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
        {showHelp && (
          <>
            <Modal
              show={showHelp}
              onHide={() => {
                this.showHelp(false);
              }}
              className={"[ popup-modal__container ]"}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static" // this prevents the pop up from being closed when clicked outside of the pop up
              keyboard={false}
            >
              <Modal.Header
                className="[ popup-modal__header ]"
                closeButton={showHelp}
              >
                <Modal.Title>ID Compatability Check</Modal.Title>
              </Modal.Header>
              <Modal.Body className="[ text-left ] [ overflow-auto ] [ iframe-popup-modal__body ]">
                <div className="[ row ]">
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    Our system uses machine reading and AI technologies to
                    authentic ID documents. To prevent application delays,
                    please upload government issued ID documents compatible with
                    our system.
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    &nbsp;
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    <b>
                      Compatible government issued ID documents have:
                      <ul>
                        <li>your photo</li>
                        <li>your date of birth</li>
                        <li>machine readable fonts</li>
                        <li>a bar code</li>
                      </ul>
                    </b>
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    &nbsp;
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    Example of compatible government issued IDs:
                    <ul>
                      <li>
                        Provincial / Territorial Issued Enhanced Driver's
                        License
                      </li>
                      <li>Provincial / Territorial Issued Photo ID Card</li>
                      <li>
                        Federally issued Secure Certificate of Indian Status
                      </li>
                      <li>Canadian Citizenship Card</li>
                      <li>Passport</li>
                      <li>Candaian Forces Identification Cards</li>
                      <li>Permanent Resident Card</li>
                      <li>
                        New (2023) Provincial / Territorial Issued PAL firearms
                        license
                      </li>
                      <li>USA/CAN Nexus Card</li>
                    </ul>
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    <b>Example:</b>
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    <img src={"idExample.png"} />
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    &nbsp;
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    NOTE: Some IDs may be government issued, valid, and legal
                    but <b>cannot be validated via automatic verification</b>.
                    Uploading these will cause delays in your applicaiton and
                    may require further verification.
                    <br />
                    DO NOT UPLOAD THESE KIND.
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    &nbsp;
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    Incompatible government issued ID documents:
                    <ul>
                      <li>may be plastic laminated</li>
                      <li>
                        use fonts or scripts like "hand-writing" which are not
                        machine readable
                      </li>
                    </ul>
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    &nbsp;
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    Examples of incompatible government issued IDs:
                    <ul>
                      <li>Older, laminated Canadian Citizenship Card</li>
                      <li>Older (pre 2023) POL or PAL firearms licenses</li>
                      <li>
                        Provincial / Territorial, regional or nation / band
                        issued Indian Status
                      </li>
                    </ul>
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    <b>Example:</b>
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    <img src={"id1.png"} />
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    <img src={"id2.png"} />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
                <>
                  <div className="[ mb-3 ]">
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div>
                          <button
                            type="button"
                            className="[ btn submit-btn ] [ w-100 ]"
                            onClick={() => this.showHelp(false)}
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Modal.Footer>
            </Modal>
          </>
        )}
        {qrKycStart && isQrKyc && (
          <>
            <Modal
              show={qrKycStart}
              className={"[ popup-modal__container ]"}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static" // this prevents the pop up from being closed when clicked outside of the pop up
              keyboard={false}
            >
              <Modal.Header className="[ popup-modal__header ]">
                <Modal.Title>
                  {
                    STRINGS.ABOUTYOU.KYC_QR_CODE
                      .QR_CODE_MOBILE_VERIFICATION_TITLE
                  }
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="[ overflow-auto ] [ iframe-popup-modal__body ]">
                <div className="[ row ]">
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    {
                      STRINGS.ABOUTYOU.KYC_QR_CODE
                        .QR_CODE_MOBILE_VERIFICATION_DESCRIPTION
                    }
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    &nbsp;
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ] [ text-center ]">
                    <span className="[ qr-code_text ]">
                      {activeProduct?.kycQrCode}
                    </span>
                  </div>
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    &nbsp;
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
                <>
                  <div className="[ mb-3 ]">
                    <div className="[ row ]">
                      <div className="[ col-6 ]">
                        <div>
                          <button
                            type="button"
                            className="[ btn ] [ tertiary-btn ] [ w-100 ]"
                            onClick={() => this.confirmCode(true)}
                          >
                            <span className="[ btn-text ]">
                              <i class="fas fa-check-circle"></i>{" "}
                              {
                                STRINGS.ABOUTYOU.KYC_QR_CODE
                                  .QR_CODE_MOBILE_VERIFICATION_BUTTON_YES
                              }
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="[ col-6 ]">
                        <div>
                          <button
                            type="button"
                            className="[ btn ] [ tertiary-btn ] [ w-100 ]"
                            onClick={() => this.confirmCode(false)}
                          >
                            <span className="[ btn-text ]">
                              <i class="fas fa-times-circle"></i>{" "}
                              {
                                STRINGS.ABOUTYOU.KYC_QR_CODE
                                  .QR_CODE_MOBILE_VERIFICATION_BUTTON_NO
                              }
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Modal.Footer>
            </Modal>
          </>
        )}
        {qrCodeDifferent && (
          <>
            <Modal
              show={qrCodeDifferent}
              className={"[ popup-modal__container ]"}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static" // this prevents the pop up from being closed when clicked outside of the pop up
              keyboard={false}
            >
              <Modal.Header className="[ popup-modal__header ]">
                <Modal.Title>
                  {STRINGS.ABOUTYOU.KYC_QR_CODE.QR_CODE_MOBILE_NOMATCH_TITLE}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="[ text-left ] [ overflow-auto ] [ iframe-popup-modal__body ]">
                <div className="[ row ] [ mb-3 ]">
                  <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                    {
                      STRINGS.ABOUTYOU.KYC_QR_CODE
                        .QR_CODE_MOBILE_NOMATCH_DESCRIPTION
                    }
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
                <>
                  <div className="[ mb-3 ]">
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div>
                          <button
                            type="button"
                            className="[ btn submit-btn ] [ w-100 ]"
                            onClick={() => history.push(ROUTES.SIGN_IN)}
                          >
                            {
                              STRINGS.ABOUTYOU.KYC_QR_CODE
                                .QR_CODE_MOBILE_NOMATCH_BUTTON_OK
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </>
    );
  }
}

IdSelection.propTypes = {
  idSelectionHandler: PropTypes.func,
  doGetIdList: PropTypes.func,
  idListResponse: PropTypes.oneOfType([
    PropTypes.arrayOf(Object),
    PropTypes.object,
    PropTypes.string,
  ]),
};

IdSelection.defaultProps = {
  idSelectionHandler: () => {},
  doGetIdList: () => {},
  idListResponse: null,
};

const mapStateToProps = (state) => ({
  idListResponse: state.AboutYouReducer,
  isQrKyc: state.ApplicationReducer.isQrKyc,
});
const mapDispatchToProps = (dispatch) => {
  return {
    doGetIdList: () => dispatch(doGetIdType()),
    doSetKycFlag: () => dispatch(setKycFlag()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdSelection);
