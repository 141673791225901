import React from "react";
import PropTypes from "prop-types";
// import constants
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import {
  HTTP_STATUS,
  REGISTERCONTRACTPRODUCT,
  LENDING_PRODUCT,
} from "Communication/Constants";
import { setOptions } from "Utils/EmploymentAndIncomeUtilities";

import AuthBody from "Components/Common/AuthBody/AuthBody";
// import common components
import PopupModal from "Components/Common/PopupModal/PopupModal";
import Stepper from "Components/Common/Stepper/Stepper";
import { connect } from "react-redux";
import * as ROUTES from "Constants/Routes";
import {
  getDNAAddress,
  getDNAApartment,
  getJointInSessionFlow,
  getRFSAddress,
  isNewMember,
  showAssetPage,
  showCompliance,
  showDebitCard,
  showDocumentUpload,
  showExpensePage,
  showGettingStarted,
  showIncomePage,
  showJoint,
  showLiabilityPage,
  showTaxInfo,
} from "../../Utils/LogicUtilities";
import {
  getDescriptionFromArray,
  getGettingStarted,
  findByDescription,
  findCode,
} from "../../Utils/CommonUtilities";
import AmountDisplay from "../Common/AmountDisplay";

class ConfirmAccountCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAcceptedTerms: false, // boolean value to check user has accepted terms
      gettingStartedData: {},
      aboutYouData: {},
      employmentData: {},
      complianceData: {},
      spousalInfoData: {},
      productInformationData: {},
      beneficiaryData: [],
      show: [],
      termsConditionError: [],
      jointApplicants: [],
      applicantTypes: [],
      assetList: [],
      incomeList: [],
      expenseList: [],
      liabilitiesList: [],
      loanDetails: {},
      documentList: [],
      showDebitCardSection: false,
      employerPhoneSettings: {},
      occupationCodeSettings: {},
      employmentStatusSettings: {},
      employmentPositionSettings: {},
      sourceOfIncomeSettings: {},
      reasonCodeSettings: {},
      GICInfo: {
        amount: 0,
        intendedUse: "",
      },
      showConsentMessage: false,
    };
  }

  componentDidMount() {
    const {
      doGetProductInformation,
      doGetBeneficiaryData,
      doGetSpousalInformation,
      doGetApplicationProperty,
      doGetApplicantType,
      inSessionJointApplicant,
      doGetFinancialAnalysisFinancialList,
      doGetDocumentList,
      doGetLoanLoanSecurity,
      applicationCompleteList,
      getDataFromServer,
      processFlow,
      config,
      doGetTermProduct,
      countryList,
      doGetCountryList,
      applicantData,
      finInfo,
    } = this.props;

    if (countryList && countryList.length <= 0) {
      doGetCountryList();
    }

    this.checkPageSettings();

    getDataFromServer(AppConstants.APPLICATIONSTEP.CONFIRM, (applicantRes) => {
      console.log("applicantRes", applicantRes);
      if (applicantRes.status === HTTP_STATUS.OK) {
        this.updateStateFromResponse(applicantRes.data);
        const { getActiveProduct, vaultProductList } = this.props;
        const activeProduct = getActiveProduct(vaultProductList);

        if (
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.DEBIT_CARD,
            activeProduct
          ) !== -1
        ) {
          if (
            showDebitCard(
              activeProduct,
              applicationCompleteList,
              applicantRes.data,
              inSessionJointApplicant,
              finInfo
            )
          ) {
            this.setState({
              showDebitCardSection: true,
            });
          }
        }

        if (
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.SPOUSAL_INFO,
            activeProduct
          ) !== -1 &&
          !activeProduct.hasExistingContract
        ) {
          doGetSpousalInformation(
            activeProduct.applicationId,
            REGISTERCONTRACTPRODUCT,
            activeProduct.contractProductId,
            (getSpousalInformationRes) => {
              if (getSpousalInformationRes.status === HTTP_STATUS.OK) {
                this.setState({
                  spousalInfoData: getSpousalInformationRes.data,
                });
              }
            }
          );
        }

        if (
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.BENEFICIARIES,
            activeProduct
          ) !== -1 &&
          !activeProduct.hasExistingContract
        ) {
          doGetBeneficiaryData(
            activeProduct.applicationId,
            activeProduct.contractProductId,
            (getBeneficiaryDataRes) => {
              if (getBeneficiaryDataRes.status === HTTP_STATUS.OK) {
                this.setState({
                  beneficiaryData: getBeneficiaryDataRes.data,
                });
              }
            }
          );
        }

        if (
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.GIC_INFO,
            activeProduct
          ) !== -1
        ) {
          console.log("active product", activeProduct);
          doGetTermProduct(
            {
              applicationId: activeProduct.applicationId,
              doximProductId: activeProduct.doximProductId,
            },
            (res) => {
              this.setState({
                GICInfo: {
                  amount:
                    res.amount === 0 ? activeProduct.amount.min : res.amount,
                  intendedUse: res.intendedUse,
                  baseRate: res.baseRate,
                },
              });
            }
          );
        }

        if (
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION,
            activeProduct
          ) !== -1 ||
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION,
            activeProduct
          ) !== -1
        ) {
          doGetProductInformation(
            activeProduct.applicationId,
            activeProduct.type,
            activeProduct.doximProductId,
            (getProductInformationRes) => {
              if (getProductInformationRes.status === HTTP_STATUS.OK) {
                this.setState({
                  productInformationData: getProductInformationRes.data,
                });
              }
            }
          );
        }

        if (
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.JOINT_APPLICANT,
            activeProduct
          ) !== -1 &&
          !activeProduct.isJointProduct
        ) {
          doGetApplicationProperty(
            { applicationId: activeProduct.applicationId },
            (res) => {
              if (res.slots.length > 1) {
                this.setState({
                  jointApplicants: res.slots.filter(
                    (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
                  ),
                });
              }
            }
          );
          doGetApplicantType((res) => {
            console.log("applicantTypes", res);
            this.setState({
              applicantTypes: res,
            });
          });
        }

        if (inSessionJointApplicant) {
          const show = [];
          const inSessionJointFlow = getJointInSessionFlow(
            processFlow,
            applicantData
          );
          const currentFlow = processFlow;
          for (const flow of inSessionJointFlow) {
            const step = currentFlow?.find(
              (s) => s.componentName === flow.componentName
            );
            if (step) {
              show.push(flow.componentName);
            }
          }
          this.setState({ show });
        } else if (processFlow && processFlow.length > 0) {
          const show = [];
          processFlow.forEach((flow) => {
            if (flow.componentName === AppConstants.APPLICATIONSTEP.INCOME) {
              if (showIncomePage(activeProduct, config)) {
                show.push(flow.componentName);
              }
            } else if (
              flow.componentName === AppConstants.APPLICATIONSTEP.GETTINGSTARTED
            ) {
              if (
                showGettingStarted(activeProduct, finInfo) &&
                !inSessionJointApplicant
              ) {
                show.push(flow.componentName);
              }
            } else if (
              flow.componentName === AppConstants.APPLICATIONSTEP.ASSET
            ) {
              if (showAssetPage(activeProduct, config)) {
                show.push(flow.componentName);
              }
            } else if (
              flow.componentName === AppConstants.APPLICATIONSTEP.EXPENSE
            ) {
              if (showExpensePage(activeProduct, config)) {
                show.push(flow.componentName);
              }
            } else if (
              flow.componentName === AppConstants.APPLICATIONSTEP.LIABILITIES
            ) {
              if (showLiabilityPage(activeProduct, config)) {
                show.push(flow.componentName);
              }
            } else if (
              flow.componentName ===
              AppConstants.APPLICATIONSTEP.DOCUMENT_UPLOAD
            ) {
              if (showDocumentUpload(activeProduct)) {
                show.push(flow.componentName);
                // doGetDocumentList(activeProduct.applicationId, (res) => {
                this.setState({
                  documentList: activeProduct.docs,
                });
                // });
              }
            } else if (activeProduct.isJointProduct) {
              if (
                flow.componentType ===
                  AppConstants.COMPONENTSTEPTYPE.APPLICANT ||
                (process.componentType ===
                  AppConstants.COMPONENTSTEPTYPE.APPLICATION &&
                  process.componentName ===
                    AppConstants.APPLICATIONSTEP.DEBIT_CARD) ||
                flow.componentName ===
                  AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
              ) {
                show.push(flow.componentName);
              }
            } else {
              show.push(flow.componentName);
            }
          });
          this.setState({ show });
        }
        if (
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.ASSET,
            activeProduct
          ) !== -1 ||
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.INCOME,
            activeProduct
          ) !== -1 ||
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.EXPENSE,
            activeProduct
          ) !== -1 ||
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.LIABILITIES,
            activeProduct
          ) !== -1
        ) {
          if (!activeProduct.isJointProduct && activeProduct.finAnalysisId) {
            doGetFinancialAnalysisFinancialList(
              {
                applicantId: activeProduct.applicantId,
                finAnalysisId: activeProduct.finAnalysisId,
              },
              (res) => {
                this.saveFinancialAnalysisFinancialList(res);
                // console.log(this.state.assets);
              }
            );
          }
        }

        if (
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.LOAN_DETAILS,
            activeProduct
          ) !== -1
        ) {
          console.log("Loan Details");
          this.saveLoanDetailsData(activeProduct.loanDetails);
        }
        /* Loan Details Here */
        if (
          activeProduct.productType === "Loan" ||
          activeProduct.productType === "Mortgage"
        ) {
          console.log("loan details here");
        } else {
          console.log("no loan details");
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.finInfo !== this.props.finInfo) {
      this.componentDidMount();
    }
  }

  updateStateFromResponse = (applicantRes) => {
    console.log("updateStateFromResponse", applicantRes);
    this.saveGettingStartedData(applicantRes);
    this.saveAboutYouData(applicantRes);
    this.saveemploymentData(applicantRes);
    this.saveComplianceData(applicantRes);
  };

  saveGettingStartedData = (response) => {
    getGettingStarted(response, (gettingStartedData) => {
      this.setState({ gettingStartedData });
    });
  };

  retrieveIntendedUse = (productInformationData, activeProduct) => {
    console.log("retrieveIntendedUse", productInformationData);
    let res = productInformationData.intendedUse;
    if (activeProduct.intendedUse) {
      if (productInformationData.udfList) {
        res = productInformationData.udfList[activeProduct.intendedUse];
      }
    }
    return res;
  };

  saveAboutYouData = (response) => {
    const { vaultMember, inSessionJointApplicant } = this.props;
    const { member, address } = response;
    this.setState({
      aboutYouData: {
        firstName: inSessionJointApplicant
          ? inSessionJointApplicant.vault?.name || member.name
          : vaultMember?.name || member.name,
        middleName: inSessionJointApplicant
          ? inSessionJointApplicant.vault?.middleName || member.middleName
          : vaultMember?.middleName || member.middleName,
        surname: inSessionJointApplicant
          ? inSessionJointApplicant.vault?.surname || member.surname
          : vaultMember?.surname || member.surname,
        dateOfBirth: inSessionJointApplicant
          ? inSessionJointApplicant.vault?.dob || member.dob
          : vaultMember?.dob || member.dob,
        address: address.currentAddressLine1,
        addressLine2: address.currentAddressLine2,
        addressLine3: address.currentAddressLine3,
        currentAddressUnitNumber: address.currentAddressUnitNumber,
        currentAddressUnitType: address.currentAddressUnitType,
        currentAddressStreetType: address.currentAddressStreetType,
        currentAddressStreetDir: address.currentAddressStreetDir,
        currentAddressStreetNumber: address.currentAddressStreetNumber,
        currentAddressStreetName: address.currentAddressStreetName,
        city: address.currentAddressCity,
        state: address.currentAddressProvince,
        postalCode: address.currentAddressPostalCode,
        mailingAddressLine1: address.mailingAddressLine1,
        mailingAddressLine2: address.mailingAddressLine2,
        mailingCity: address.mailingCity,
        mailingProvince: address.mailingProvince,
        mailingPostalCode: address.mailingPostalCode,
        mailingCountry: address.mailingCountry,
        mailingSameAsCivic: address.mailingSameAsCivic,
        mailingUnitNumber: address.mailingUnitNumber,
        mailingUnitType: address.mailingUnitType,
        mailingStreetType: address.mailingStreetType,
        mailingStreetDir: address.mailingStreetDir,
        mailingStreetNumber: address.mailingStreetNumber,
        mailingStreetName: address.mailingStreetName,
      },
    });
  };

  isPagePresent = (pageName, activeProduct) => {
    const { processFlow, inSessionJointApplicant, applicantData } = this.props;
    console.log(
      "isPagePresent",
      pageName,
      activeProduct,
      processFlow,
      inSessionJointApplicant
    );

    let pageIndex = -1;

    if (inSessionJointApplicant) {
      const inSessionJointFlow = getJointInSessionFlow(
        processFlow,
        applicantData
      );
      pageIndex = inSessionJointFlow.findIndex(
        (process) => process.componentName === pageName
      );
    } else {
      pageIndex = activeProduct?.isJointProduct
        ? processFlow.findIndex(
            (process) =>
              process.componentName === pageName &&
              (process.componentType ===
                AppConstants.COMPONENTSTEPTYPE.APPLICANT ||
                (process.componentType ===
                  AppConstants.COMPONENTSTEPTYPE.APPLICATION &&
                  process.componentName ===
                    AppConstants.APPLICATIONSTEP.DEBIT_CARD))
          )
        : processFlow.findIndex(
            (process) => process.componentName === pageName
          );
    }
    console.log("pageIndex", pageIndex);
    return pageIndex;
  };

  saveemploymentData = (response) => {
    const { finInfo, vaultMember, inSessionJointApplicant } = this.props;
    let { employment, regulatory, member } = response;

    if (!inSessionJointApplicant && vaultMember?.name) {
      regulatory = { ...regulatory, ...vaultMember };
      member = {
        ...member,
        ...vaultMember,
      };
    } else if (inSessionJointApplicant && inSessionJointApplicant.vault?.name) {
      regulatory = { ...regulatory, ...inSessionJointApplicant.vault };
      member = {
        ...member,
        ...inSessionJointApplicant.vault,
      };
    }

    const resOptions = setOptions(regulatory?.taxResidency, finInfo);
    console.log("resOptions", resOptions);
    const selectedOption = resOptions?.options?.find(
      (obj) => obj.taxResidencyResult?.taxResidency === regulatory?.taxResidency
    );
    console.log("selectedOption", selectedOption);
    let areYouTaxResidentOfOtherCountry =
      resOptions?.taxResidentOther !== ""
        ? resOptions?.taxResidentOther
        : false;
    let countryName = selectedOption?.taxResidentOtherName;
    let tin = "";

    if (finInfo.useRegulatoryFieldsModel) {
      const tinOtherCountry = regulatory.regulatoryFields?.find(
        (item) => item.Code === 2
      );
      if (tinOtherCountry?.Value) {
        areYouTaxResidentOfOtherCountry = true;
        countryName = regulatory?.regulatoryFields?.find(
          (item) => item.Code === 3
        )?.Value;
        tin = tinOtherCountry.Value;
      }
    }

    this.setState({
      employmentData: {
        ...employment,
        employerName: employment.currentEmployer,
        employerContactNumber:
          employment.employerPhoneArea + employment.employerPhone,
        annualGrossIncome: employment.employerSalary,
        sourceOfIncome: employment.incomeType,
        sin: member.sin,
        ssn: regulatory.ssn,
        areYouTaxResidentOfCanada: !!member.sin,
        areYouTaxResidentOfOtherCountry,
        countryOption: resOptions?.options,
        countryList: regulatory.taxResidencyOther,
        countryName,
        regulatoryFields: regulatory.regulatoryFields,
        tin,
      },
    });
  };

  saveComplianceData = (response) => {
    const {
      applicantData,
      finInfo,
      vaultMember,
      inSessionJointApplicant,
      sessionCompletedProducts,
      contKycFlag,
    } = this.props;
    let { peps, member, regulatory } = response;
    if (contKycFlag) {
      if (inSessionJointApplicant) {
        peps = inSessionJointApplicant.vault.peps;
        response.thirdParties = inSessionJointApplicant.vault.thirdParties;
        regulatory = inSessionJointApplicant.vault; // we are only using PEP flags
        response.peps = inSessionJointApplicant.vault.peps;
      } else {
        peps = vaultMember.peps;
        response.thirdParties = vaultMember.thirdParties;
        regulatory = vaultMember; // we are only using PEP flags
        response.peps = vaultMember.peps;
      }
    }
    this.setState({
      complianceData: {
        ...response,
        isAnyoneConsideredPEPHIO: !!(peps && peps.length > 0),
        member: {
          ...member,
          pep:
            regulatory?.memberIsPedp ||
            regulatory?.memberIsPefp ||
            regulatory?.memberIsHio,
        },
        regulatory: {
          ...response.regulatory,
          ...(inSessionJointApplicant
            ? inSessionJointApplicant.vault
            : vaultMember),
        },
      },
    });
  };

  saveLoanDetailsData = (data) => {
    console.log(data);
    this.setState({
      loanDetails: data,
    });
  };

  capitalize = (s) => {
    if (typeof s !== "string") return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handleChecked = () => {
    const { isAcceptedTerms } = this.state;
    this.setState({
      isAcceptedTerms: !isAcceptedTerms,
      termsConditionError: "",
    });
  };

  handleContinueClick = () => {
    const { isAcceptedTerms, productInformationData } = this.state;
    const { handleSubmitDetails, alreadyApplied } = this.props;
    if (isAcceptedTerms || alreadyApplied) {
      handleSubmitDetails(productInformationData);
    } else {
      this.setState({
        termsConditionError: STRINGS.CONFIRM_CREATION.ACCOUNT_DISCLAIMER_ERROR,
        showConsentMessage: true,
      });
    }
  };

  getApplicantType = (type) => {
    const { applicantTypes } = this.state;
    const appType = applicantTypes.find((item) => item.code === type);
    if (appType) {
      return appType.description;
    }
    return type;
  };

  hideConsentMessage = () => {
    this.setState({ showConsentMessage: false });
  };

  formatPhoneNumber = (phoneNumberString) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return "";
  };

  filterFinancialAnalysis = (data, type) => {
    return data.filter((financial) => financial.type === type);
  };

  saveFinancialAnalysisFinancialList = (response) => {
    const tempList = this.filterFinancialAnalysis(
      response,
      AppConstants.INCOME.TYPE
    );

    this.setState({
      assetList: this.filterFinancialAnalysis(
        response,
        AppConstants.ASSET.TYPE
      ),
      incomeList: tempList,
      expenseList: this.filterFinancialAnalysis(
        response,
        AppConstants.EXPENSE.TYPE
      ),
      liabilitiesList: this.filterFinancialAnalysis(
        response,
        AppConstants.LIABILITIES.TYPE
      ),
    });
  };

  checkPageSettings = () => {
    const { finInfo, activeProduct } = this.props;
    const employerPhoneSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "employerPhone"
    );
    const occupationCodeSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "occupationCode"
    );
    const employmentStatusSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "employmentStatus"
    );
    const employmentPositionSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "employmentPosition"
    );
    const sourceOfIncomeSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "sourceOfIncome"
    );
    const reasonCodeSettings = finInfo.config?.fieldSettings?.find(
      (item) => item.field === "reasonOpened"
    );
    if (activeProduct.type === LENDING_PRODUCT) {
      // always show for loan products
      this.setState({
        employerPhoneSettings: {
          field: "employerPhone",
          page: "EMPLOYMENT_AND_INCOME",
        },
        occupationCodeSettings,
        employmentStatusSettings: {
          field: "employmentStatus",
          page: "EMPLOYMENT_AND_INCOME",
        },
        sourceOfIncomeSettings: {
          field: "sourceOfIncome",
          page: "EMPLOYMENT_AND_INCOME",
        },
        employmentPositionSettings,
      });
    } else {
      this.setState({
        employerPhoneSettings,
        occupationCodeSettings,
        employmentStatusSettings,
        sourceOfIncomeSettings,
        employmentPositionSettings,
        reasonCodeSettings,
      });
    }
  };

  render() {
    const {
      showModal,
      type,
      modal,
      toggleModal,
      showVaultClicked,
      showVault,
      handleEditPage,
      gettingStartedBranchData,
      incomeSourcesList,
      statusList,
      provinceList,
      accountPurposeList,
      handlePopupModalBtnClick,
      handleBack,
      steps,
      activeStepID,
      isDebitCardEnabled,
      enableDebitCard,
      debitCardShown,
      activeProduct,
      applicationCompleteList,
      applicantData,
      inSessionJointApplicant,
      finInfo,
      streetTypes,
      alreadyApplied,
      occupationCodes,
      employerPositionCodes,
      reasonCodes,
      intendedUseList,
      countryList,
      sessionCompletedProducts,
      contKycFlag,
    } = this.props;

    const {
      gettingStartedData,
      aboutYouData,
      employmentData,
      complianceData,
      spousalInfoData,
      productInformationData,
      beneficiaryData,
      show,
      jointApplicants,
      loanDetails,
      assetList,
      incomeList,
      expenseList,
      liabilitiesList,
      documentList,
      showDebitCardSection,
      employmentPositionSettings,
      employerPhoneSettings,
      occupationCodeSettings,
      employmentStatusSettings,
      sourceOfIncomeSettings,
      reasonCodeSettings,
      GICInfo,
      showConsentMessage,
      employment,
    } = this.state;
    console.log("applicationCompleteList", applicationCompleteList);
    const { isAcceptedTerms } = this.state;

    const actionCompnent = (
      <div className="[ confirm-account-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.CONFIRM_CREATION.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.CONFIRM_CREATION.FIRST_MSG}
            {""}
            {aboutYouData && aboutYouData.firstName
              ? aboutYouData.firstName
              : ""}
            {STRINGS.CONFIRM_CREATION.SECOND_MSG}
          </div>
          <div className="[ form-description ]">
            {STRINGS.CONFIRM_CREATION.REVIEW_DETAILS_MSG}
          </div>

          {/* Getting started block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.GETTINGSTARTED) &&
            (applicationCompleteList.length === 0 ||
              (applicationCompleteList.length === 1 &&
                applicationCompleteList[0].id ===
                  activeProduct.applicationId)) &&
            applicantData?.acquireType !== "search" && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.GETTING_STARTED.TITLE}
                      {!alreadyApplied && !activeProduct.prospect?.id && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.GETTING_STARTED)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.BRANCH}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {gettingStartedData && gettingStartedData.branch
                        ? getDescriptionFromArray(
                            gettingStartedBranchData,
                            gettingStartedData.branch
                          )
                        : ""}
                    </div>
                  </div>
                </div>

                {!finInfo.hideStatementSelection && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.ACCOUNT_STATEMENT_CHOICE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {gettingStartedData &&
                        gettingStartedData.accountStatementType
                          ? gettingStartedData.accountStatementType.toUpperCase()
                          : ""}
                      </div>
                    </div>
                  </div>
                )}

                {reasonCodeSettings?.page ===
                  AppConstants.APPLICATIONSTEP.GETTINGSTARTED && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.REASON_OPENED}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {getDescriptionFromArray(
                          reasonCodes,
                          gettingStartedData?.reasonCode
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {finInfo.showPromoCode && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.GETTING_STARTED.PROMO_CODE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {
                          applicantData.regulatory?.regulatoryFields?.find(
                            (item) => item.Code === 506
                          )?.Value
                        }
                      </div>
                    </div>
                  </div>
                )}

                {finInfo.showReferralCode && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.GETTING_STARTED.REFERRAL_CODE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {
                          applicantData.regulatory?.regulatoryFields?.find(
                            (item) => item.Code === 507
                          )?.Value
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          {/* Getting started block end */}

          {/* About you block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.ABOUTYOU) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.CONFIRM_CREATION.ABOUT_YOU}
                    {!alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.ABOUT_YOU)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.FIRST_NAME}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.firstName
                      ? aboutYouData.firstName
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.MIDDLE_NAME}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.middleName
                      ? aboutYouData.middleName
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.SURNAME}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.surname
                      ? aboutYouData.surname
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.DOB}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.dateOfBirth
                      ? aboutYouData.dateOfBirth
                      : ""}
                  </div>
                </div>
              </div>

              {finInfo.coreBankingType ===
                AppConstants.CORE_BANKING_TYPE.OVATION && (
                <>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.ADDRESS2}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.currentAddressUnitNumber
                          ? aboutYouData.currentAddressUnitNumber
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.UNIT_TYPE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.currentAddressUnitType
                          ? aboutYouData.currentAddressUnitType
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.STREET_NO}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.currentAddressStreetNumber
                          ? aboutYouData.currentAddressStreetNumber
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.STREET_NAME}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.currentAddressStreetName
                          ? aboutYouData.currentAddressStreetName
                          : ""}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {finInfo.coreBankingType !==
                AppConstants.CORE_BANKING_TYPE.OVATION && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.ADDRESS1}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    {finInfo.coreBankingType ===
                      AppConstants.CORE_BANKING_TYPE.RFS && (
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.address
                          ? getRFSAddress(aboutYouData.address)
                          : ""}
                      </div>
                    )}
                    {finInfo.coreBankingType ===
                      AppConstants.CORE_BANKING_TYPE.DNA && (
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.address
                          ? getDNAAddress(aboutYouData.address)
                          : ""}
                      </div>
                    )}
                    {finInfo.coreBankingType !==
                      AppConstants.CORE_BANKING_TYPE.RFS &&
                      finInfo.coreBankingType !==
                        AppConstants.CORE_BANKING_TYPE.DNA && (
                        <div className="[ details-section__item-description ]">
                          {aboutYouData && aboutYouData.address
                            ? aboutYouData.address
                            : ""}
                        </div>
                      )}
                  </div>
                </div>
              )}

              {finInfo.coreBankingType !== AppConstants.CORE_BANKING_TYPE.RFS &&
                finInfo.coreBankingType !==
                  AppConstants.CORE_BANKING_TYPE.DNA &&
                finInfo.coreBankingType !==
                  AppConstants.CORE_BANKING_TYPE.OVATION && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.ADDRESS2}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.addressLine2
                          ? aboutYouData.addressLine2
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
              {finInfo.coreBankingType ===
                AppConstants.CORE_BANKING_TYPE.RFS && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.ADDRESS2}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {aboutYouData && aboutYouData.currentAddressUnitNumber
                        ? aboutYouData.currentAddressUnitNumber
                        : ""}
                    </div>
                  </div>
                </div>
              )}
              {finInfo.coreBankingType ===
                AppConstants.CORE_BANKING_TYPE.DNA && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.ADDRESS2}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {aboutYouData && aboutYouData.address
                        ? getDNAApartment(aboutYouData.address)
                        : ""}
                    </div>
                  </div>
                </div>
              )}
              {finInfo.coreBankingType ===
                AppConstants.CORE_BANKING_TYPE.OVATION && (
                <>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.STREET_TYPE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.currentAddressStreetType
                          ? aboutYouData.currentAddressStreetType
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.DIRECTION}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.currentAddressStreetDir
                          ? aboutYouData.currentAddressStreetDir
                          : ""}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.CITY}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  {finInfo.coreBankingType !==
                    AppConstants.CORE_BANKING_TYPE.RFS &&
                    finInfo.coreBankingType !==
                      AppConstants.CORE_BANKING_TYPE.DNA && (
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.city
                          ? aboutYouData.city
                          : ""}
                      </div>
                    )}
                  {finInfo.coreBankingType ===
                    AppConstants.CORE_BANKING_TYPE.RFS && (
                    <div className="[ details-section__item-description ]">
                      {aboutYouData && aboutYouData.city
                        ? aboutYouData.city
                        : ""}
                    </div>
                  )}
                  {finInfo.coreBankingType ===
                    AppConstants.CORE_BANKING_TYPE.DNA && (
                    <div className="[ details-section__item-description ]">
                      {aboutYouData && aboutYouData.addressLine3
                        ? aboutYouData.addressLine3
                        : ""}
                    </div>
                  )}
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.STATE}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.state
                      ? getDescriptionFromArray(
                          provinceList,
                          aboutYouData.state
                        )
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.POSTAL_CODE}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]  [ postalCode ]">
                    {aboutYouData && aboutYouData.postalCode
                      ? aboutYouData.postalCode
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.SAMEASMAILING}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.mailingSameAsCivic
                      ? STRINGS.COMMON.CHOICE.YES
                      : STRINGS.COMMON.CHOICE.NO}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* About you block end */}

          {/* Mailing address block start */}
          {aboutYouData && !aboutYouData.mailingSameAsCivic ? (
            <>
              {show.includes(AppConstants.APPLICATIONSTEP.ABOUTYOU) && (
                <div className="[ details-section ] [ mb-3 ]">
                  <div className="[ row ] [ details-section__header">
                    <div className="[ col-12 ]">
                      <h3 className="[ d-flex justify-content-between align-items-center ]">
                        {STRINGS.CONFIRM_CREATION.MAILING_ADDRESS}
                        {!alreadyApplied && (
                          <button
                            type="button"
                            className="[ btn edit-btn ]"
                            onClick={() => handleEditPage(ROUTES.ABOUT_YOU)}
                            aria-label="edit"
                          >
                            <span className="[ edit-icon ]" />
                            <span className="[ edit-btn__label ]">
                              {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                            </span>
                          </button>
                        )}
                      </h3>
                    </div>
                  </div>
                  {finInfo.coreBankingType ===
                    AppConstants.CORE_BANKING_TYPE.OVATION && (
                    <>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.MAILINGADDRESSLINE2}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {aboutYouData && aboutYouData.mailingUnitNumber
                              ? aboutYouData.mailingUnitNumber
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.MAILING_UNIT_TYPE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {aboutYouData && aboutYouData.mailingUnitType
                              ? aboutYouData.mailingUnitType
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.MAILING_STREET_NUMBER}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {aboutYouData && aboutYouData.mailingStreetNumber
                              ? aboutYouData.mailingStreetNumber
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.MAILING_STREET_NAME}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {aboutYouData && aboutYouData.mailingStreetName
                              ? aboutYouData.mailingStreetName
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.MAILING_STREET_TYPE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {aboutYouData && aboutYouData.mailingStreetType
                              ? aboutYouData.mailingStreetType
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.MAILING_DIRECTION}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {aboutYouData && aboutYouData.mailingStreetDir
                              ? aboutYouData.mailingStreetDir
                              : ""}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {finInfo.coreBankingType !==
                    AppConstants.CORE_BANKING_TYPE.OVATION && (
                    <>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.MAILINGADDRESSLINE1}
                          </div>
                        </div>
                        {finInfo.coreBankingType !==
                          AppConstants.CORE_BANKING_TYPE.DNA && (
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {aboutYouData && aboutYouData.mailingAddressLine1
                                ? aboutYouData.mailingAddressLine1
                                : ""}
                            </div>
                          </div>
                        )}
                        {finInfo.coreBankingType ===
                          AppConstants.CORE_BANKING_TYPE.DNA && (
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {aboutYouData && aboutYouData.mailingAddressLine1
                                ? getDNAAddress(
                                    aboutYouData.mailingAddressLine1
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.MAILINGADDRESSLINE2}
                          </div>
                        </div>
                        {finInfo.coreBankingType !==
                          AppConstants.CORE_BANKING_TYPE.DNA && (
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {aboutYouData && aboutYouData.mailingUnitNumber
                                ? aboutYouData.mailingUnitNumber
                                : ""}
                            </div>
                          </div>
                        )}
                        {finInfo.coreBankingType ===
                          AppConstants.CORE_BANKING_TYPE.DNA && (
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {aboutYouData && aboutYouData.mailingAddressLine1
                                ? getDNAApartment(
                                    aboutYouData.mailingAddressLine1
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGCITY}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.mailingCity
                          ? aboutYouData.mailingCity
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGPROVINCE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.mailingProvince
                          ? getDescriptionFromArray(
                              provinceList,
                              aboutYouData.mailingProvince
                            )
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGPOSTALCODE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ] [ postalCode ]">
                        {aboutYouData && aboutYouData.mailingPostalCode
                          ? aboutYouData.mailingPostalCode
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGCOUNTRY}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.mailingCountry
                          ? countryList.find(
                              (x) => x.code === aboutYouData.mailingCountry
                            )?.description
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}
          {/* Mailing address block end */}

          {/* Employment and income block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.EMPLOYMENT) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.EMPLOYMENT_AND_INCOME.TITLE}
                    {!alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.EMPLOYMENT_AND_INCOME)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>

              {employmentStatusSettings?.page ===
                AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.EMPLOYMENT_STATUS}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentData && employmentData.employmentStatus
                        ? getDescriptionFromArray(
                            statusList,
                            employmentData.employmentStatus
                          )
                        : ""}
                    </div>
                  </div>
                </div>
              )}

              {occupationCodeSettings?.page ===
                AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.OCCUPATION}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentData && employmentData.occupationCode
                        ? findCode(
                            employmentData.occupationCode,
                            occupationCodes
                          )
                        : ""}
                    </div>
                  </div>
                </div>
              )}

              {employmentPositionSettings?.page ===
                AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.POSITION}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    {employmentPositionSettings.isText && (
                      <div className="[ details-section__item-description ]">
                        {employmentData.employerPosition}
                      </div>
                    )}
                    {!employmentPositionSettings.isText && (
                      <div className="[ details-section__item-description ]">
                        {employmentData && employmentData.employerPosition
                          ? findCode(
                              employmentData.employerPosition,
                              employerPositionCodes
                            )
                          : ""}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.CURRENT_EMPLOYER}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {employmentData && employmentData.employerName
                      ? employmentData.employerName
                      : ""}
                  </div>
                </div>
              </div>

              {employerPhoneSettings?.page ===
                AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.EMPLOYER_PHONE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentData && employmentData.employerContactNumber
                        ? this.formatPhoneNumber(
                            employmentData.employerContactNumber
                          )
                        : ""}
                    </div>
                  </div>
                </div>
              )}

              {finInfo.showEmployerAddress &&
                activeProduct.type === LENDING_PRODUCT && (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.ADDRESS1}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {employmentData.employerLine1}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.ADDRESS2}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {employmentData.employerLine2}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.CITY}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {employmentData.employerCity}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.PROVINCE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {employmentData.employerProv}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.EMPLOYMENT_AND_INCOME.ADDRESSFORM.POSTALCODE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {employmentData.employerPc}
                        </div>
                      </div>
                    </div>
                  </>
                )}

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.MONTHLY_GROSS_INCOME}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    <AmountDisplay amount={employmentData.annualGrossIncome} />
                  </div>
                </div>
              </div>

              {sourceOfIncomeSettings?.page ===
                AppConstants.APPLICATIONSTEP.EMPLOYMENT && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.SOURCE_OF_INCOME}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentData && employmentData.sourceOfIncome
                        ? getDescriptionFromArray(
                            incomeSourcesList,
                            employmentData.sourceOfIncome
                          )
                        : ""}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* Employment and income block end */}

          {show.includes(AppConstants.APPLICATIONSTEP.TAX_INFO) &&
            showTaxInfo(
              applicationCompleteList,
              applicantData,
              inSessionJointApplicant,
              activeProduct,
              finInfo,
              sessionCompletedProducts
            ) && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.TAX_INFO.TITLE}
                      {!alreadyApplied && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.TAX_INFO)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.TAX_RESIDENT_OF_CANADA}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentData &&
                      employmentData.areYouTaxResidentOfCanada
                        ? STRINGS.COMMON.CHOICE.YES
                        : STRINGS.COMMON.CHOICE.NO}
                    </div>
                  </div>
                </div>

                {employmentData && employmentData.sin && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.SIN_NUMBER}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        ****&nbsp;
                        {employmentData && employmentData.sin
                          ? employmentData.sin.substr(
                              employmentData.sin.length - 3
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                )}

                {finInfo.useRegulatoryFieldsModel && (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.TAX_RESIDENT_OF_USA}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {employmentData && employmentData.ssn
                            ? STRINGS.COMMON.CHOICE.YES
                            : STRINGS.COMMON.CHOICE.NO}
                        </div>
                      </div>
                    </div>
                    {employmentData.ssn && (
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.CONFIRM_CREATION.SSN_NUMBER}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            ****&nbsp;
                            {employmentData && employmentData.ssn
                              ? employmentData.ssn.substr(
                                  employmentData.ssn.length - 3
                                )
                              : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.TAX_RESIDENT_OTHER_COUNTRY}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {employmentData &&
                      employmentData.areYouTaxResidentOfOtherCountry
                        ? STRINGS.COMMON.CHOICE.YES
                        : STRINGS.COMMON.CHOICE.NO}
                    </div>
                  </div>
                </div>

                {employmentData &&
                  employmentData.areYouTaxResidentOfOtherCountry && (
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.TAX_SELECT_COUNTRY}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {employmentData && employmentData.countryName
                            ? employmentData.countryName
                            : null}
                        </div>
                      </div>
                    </div>
                  )}

                {employmentData &&
                  employmentData.ssn &&
                  !finInfo.useRegulatoryFieldsModel && (
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.SSN_NUMBER}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          ****&nbsp;
                          {employmentData && employmentData.ssn
                            ? employmentData.ssn.substr(
                                employmentData.ssn.length - 3
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                  )}

                {employmentData &&
                  employmentData.tin &&
                  finInfo.useRegulatoryFieldsModel && (
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.TAX_IDENTITY_NO}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          ****&nbsp;
                          {employmentData && employmentData.tin
                            ? employmentData.tin.substr(
                                employmentData.tin.length - 3
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}

          {/* Compliance block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.COMPLIANCE) &&
            showCompliance(
              applicantData,
              applicationCompleteList,
              inSessionJointApplicant,
              activeProduct,
              finInfo,
              sessionCompletedProducts
            ) && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.COMPLIANCE.TITLE}
                      {!alreadyApplied && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.COMPLIANCE)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>

                {finInfo.showMembershipPurpose && isNewMember(applicantData) && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.ACCOUNT_PURPOSE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {complianceData &&
                        complianceData.member &&
                        complianceData.member.intendedUseOfMembership
                          ? getDescriptionFromArray(
                              accountPurposeList,
                              complianceData.member.intendedUseOfMembership
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                )}

                {employerPhoneSettings?.page ===
                  AppConstants.APPLICATIONSTEP.COMPLIANCE && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.EMPLOYER_PHONE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {employmentData && employmentData.employerContactNumber
                          ? this.formatPhoneNumber(
                              employmentData.employerContactNumber
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                )}

                {occupationCodeSettings?.page ===
                  AppConstants.APPLICATIONSTEP.COMPLIANCE && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.OCCUPATION}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {employmentData && employmentData.occupationCode
                          ? findCode(
                              employmentData.occupationCode,
                              occupationCodes
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                )}

                {sourceOfIncomeSettings?.page ===
                  AppConstants.APPLICATIONSTEP.COMPLIANCE && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.SOURCE_OF_INCOME}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {employmentData && employmentData.sourceOfIncome
                          ? getDescriptionFromArray(
                              incomeSourcesList,
                              employmentData.sourceOfIncome
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                )}

                {complianceData &&
                complianceData.regulatory?.memberHasThirdParty ? (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.THIRDPARTY_ACCOUNT}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {STRINGS.COMMON.CHOICE.YES}
                        </div>
                      </div>
                    </div>

                    {complianceData.thirdParties?.map((record) => (
                      <div key={record}>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.THIRDPARTYNAME}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {record.name}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.THIRDPARTYREL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {record.relationship}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.THIRDPARTY_ACCOUNT}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}

                {/* is applicant pep or hio start  */}
                {complianceData && complianceData.member?.pep ? (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.MEMBER_PEP_HIO_CHOICE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {STRINGS.COMMON.CHOICE.YES}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.APPLICANTTYPE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberIsHio && (
                            <div className="[ details-section__item-description ]">
                              {STRINGS.CONFIRM_CREATION.HIO_STATUS}
                            </div>
                          )}

                        {complianceData &&
                          complianceData.regulatory?.memberIsPedp && (
                            <div className="[ details-section__item-description ]">
                              {STRINGS.CONFIRM_CREATION.PEP_DOMESTIC}
                            </div>
                          )}
                        {complianceData &&
                          complianceData.regulatory?.memberIsPefp && (
                            <div className="[ details-section__item-description ]">
                              {STRINGS.CONFIRM_CREATION.PEP_FOREGIN}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.OFFICE_HELD_LABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberOfficeHeld && (
                            <div className="[ details-section__item-description ]">
                              {finInfo?.config?.officeHeld
                                ? finInfo.config?.officeHeld?.find(
                                    (item) =>
                                      item.code ===
                                      complianceData.regulatory.memberOfficeHeld
                                  )?.description
                                : complianceData.regulatory?.memberOfficeHeld}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.JURISDICTION_LABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberFundsSource && (
                            <div className="[ details-section__item-description ]">
                              {complianceData.regulatory.memberJurisdiction}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.SOURCE_OF_FUNDS_LABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberFundsSource && (
                            <div className="[ details-section__item-description ]">
                              {complianceData.regulatory.memberFundsSource}
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MEMBER_PEP_HIO_CHOICE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}
                {/* is applicant pep or hio end */}
                {/* PEP details start */}
                {complianceData && complianceData.peps?.length > 0 ? (
                  <>
                    {/* applicant details start */}

                    {/* applicant details end */}

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.PEP_HIO_CHOICE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {STRINGS.COMMON.CHOICE.YES}
                        </div>
                      </div>
                    </div>

                    {complianceData.peps.map((data) => (
                      <div key={data}>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.CLOSEPERSONDETAILS}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.name}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.THIRDPARTYREL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.relationship}
                            </div>
                          </div>
                        </div>

                        {data.jurisdictionType && (
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {
                                  STRINGS.CONFIRM_CREATION
                                    .CLOSEPERSONJURISDICTIONTYPE
                                }
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {data.jurisdictionType}
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.ISPEPORHIO}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.hio ? "true" : "false"}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.OFFICE_HELD_LABEL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {finInfo?.config?.officeHeld
                                ? finInfo.config?.officeHeld?.find(
                                    (item) => item.code === data.officeHeld
                                  )?.description
                                : data.officeHeld}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.JURISDICTION_LABEL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.jurisdiction}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.SOURCE_OF_FUNDS_LABEL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.fundsSource}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.PEP_HIO_CHOICE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}
                {/* PEP details end */}
              </div>
            )}
          {/* Compliance block end */}
          {/* Account Information block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.ACCOUNT_INFORMATION.TITLE}
                    {!alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.ACCOUNT_INFORMATION)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.ACCOUNT_PURPOSE_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {this.retrieveIntendedUse(
                      productInformationData,
                      activeProduct
                    )}
                  </div>
                </div>
              </div>

              {productInformationData.bankingContractNo > 0 && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.EXISTING_CONTRACT}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {productInformationData.bankingContractNo}
                    </div>
                  </div>
                </div>
              )}

              {finInfo.intendedUseOtherFlag &&
                findByDescription(
                  this.retrieveIntendedUse(
                    productInformationData,
                    activeProduct
                  ),
                  intendedUseList
                )?.code === "Other" && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.INTENDED_USE_OTHER}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {productInformationData?.udfList
                          ? productInformationData.udfList[
                              finInfo.intendedUseOtherFlag
                            ]
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
          {/* Account Information block end */}

          {show.includes(AppConstants.APPLICATIONSTEP.THIRD_PARTY) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.THIRD_PARTY.TITLE}
                    {!alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.THIRD_PARTY)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>

              {activeProduct.isThirdPartyAccount ? (
                <>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.THIRDPARTY_ACCOUNT}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.YES}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.THIRDPARTYNAME}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.thirdPartyName}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.THIRDPARTYREL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.thirdPartyRelationship}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.THIRDPARTY_ACCOUNT}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {STRINGS.COMMON.CHOICE.NO}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {show.includes(AppConstants.APPLICATIONSTEP.GIC_INFO) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ col-12 ]">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {STRINGS.GIC_INFO.TITLE}
                  {!alreadyApplied && (
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.GIC_INFO)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  )}
                </h3>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.GIC_INFO.FORM.AMOUNT}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    <AmountDisplay amount={GICInfo.amount} />
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.GIC_INFO.FORM.TERM}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct?.term}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.GIC_INFO.FORM.INTEREST}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {`${activeProduct?.rate} %`}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Overdraft Protection block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.OVERDRAFT_PROTECTION.TITLE}
                    {!alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.OVERDRAFT_PROTECTION)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.OVER_DRAFTPROTECTION__REQUIRED}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {productInformationData &&
                    productInformationData.hasOverdraftProtection
                      ? STRINGS.COMMON.CHOICE.YES
                      : STRINGS.COMMON.CHOICE.NO}
                  </div>
                </div>
              </div>
            </div>
          )}
          {showDebitCardSection && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.DEBIT_CARD.TITLE}
                    {!alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.DEBIT_CARD)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: STRINGS.DEBIT_CARD.CHOICE_MESSAGE,
                      }}
                    />
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  {inSessionJointApplicant && (
                    <div className="[ details-section__item-description ]">
                      {inSessionJointApplicant.enableDebitCard
                        ? STRINGS.COMMON.CHOICE.YES
                        : STRINGS.COMMON.CHOICE.NO}
                    </div>
                  )}
                  {!inSessionJointApplicant && (
                    <div className="[ details-section__item-description ]">
                      {activeProduct.enableDebitCard
                        ? STRINGS.COMMON.CHOICE.YES
                        : STRINGS.COMMON.CHOICE.NO}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {inSessionJointApplicant && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.JOINT_APPLICANT.TITLE}
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.JOINT_APPLICANT.FORM.CONFIRM_JOINT_APPLICANT}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {STRINGS.COMMON.CHOICE.YES}
                  </div>
                </div>
              </div>
            </div>
          )}

          {show.includes(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT) &&
            showJoint(
              finInfo,
              applicantData,
              applicationCompleteList,
              activeProduct
            ) && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.JOINT_APPLICANT.TITLE}
                      {!activeProduct.isJointProduct &&
                        !alreadyApplied &&
                        !(
                          finInfo.jointMembership && activeProduct.singleOnly
                        ) && (
                          <button
                            type="button"
                            className="[ btn edit-btn ]"
                            onClick={() =>
                              handleEditPage(ROUTES.JOINT_APPLICANT)
                            }
                            aria-label="edit"
                          >
                            <span className="[ edit-icon ]" />
                            <span className="[ edit-btn__label ]">
                              {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                            </span>
                          </button>
                        )}
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.JOINT_APPLICANT.FORM.CONFIRM_JOINT_APPLICANT}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    {activeProduct.isJointProduct && (
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.YES}
                      </div>
                    )}
                    {!activeProduct.isJointProduct && (
                      <div className="[ details-section__item-description ]">
                        {jointApplicants.length > 0
                          ? STRINGS.COMMON.CHOICE.YES
                          : STRINGS.COMMON.CHOICE.NO}
                      </div>
                    )}
                  </div>
                </div>

                {jointApplicants.map((joint, index) => (
                  <div
                    key={joint.id}
                    className="[ row ] [ details-section__item ]"
                  >
                    <div className="[ col-sm-3 ]">
                      <div className="[ details-section__item-description ]">
                        CO-APPLICANT NO. {index + 1}
                      </div>
                    </div>
                    <div className="[ col-sm-3 ]">
                      <div className="[ details-section__item-description email ]">
                        {joint.extra.email}
                      </div>
                    </div>
                    <div className="[ col-sm-3 ]">
                      <div className="[ details-section__item-description ]">
                        {this.formatPhoneNumber(
                          joint.extra.msisdn.substring(1)
                        )}
                      </div>
                    </div>
                    <div className="[ col-sm-3 ]">
                      <div className="[ details-section__item-description ]">
                        {this.getApplicantType(joint.joinInfo.joinType)}
                      </div>
                    </div>
                  </div>
                ))}

                {jointApplicants.length > 0 && !finInfo.disableJointInSession && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.JOINT_APPLICANT.FORM.JOINT_PRESENT_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {jointApplicants[0].extra.inSession
                          ? STRINGS.COMMON.CHOICE.YES
                          : STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

          {/* Overdraft Protection block end */}
          {/* Spousal Info block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.SPOUSAL_INFO) &&
            !activeProduct.hasExistingContract && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.SPOUSAL_INFO.SCREENTITLE}
                      {!alreadyApplied && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.SPOUSAL_INFO)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.SPOUSAL_INFO.SPOUSE_QUI}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {spousalInfoData && spousalInfoData.isSpousal
                        ? STRINGS.COMMON.CHOICE.YES
                        : STRINGS.COMMON.CHOICE.NO}
                    </div>
                  </div>
                </div>
                {spousalInfoData && spousalInfoData.isSpousal && (
                  <div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {spousalInfoData && spousalInfoData.spouseFirstName
                            ? spousalInfoData.spouseFirstName
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {spousalInfoData && spousalInfoData.spouseLastName
                            ? spousalInfoData.spouseLastName
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {spousalInfoData && spousalInfoData.spouseDob
                            ? spousalInfoData.spouseDob
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.EMPLOYMENT_AND_INCOME.FORM.SIN}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {spousalInfoData && spousalInfoData.spouseSin
                            ? `******${spousalInfoData.spouseSin.substr(
                                employmentData.sin.length - 3
                              )}`
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          {/* Spousal Info block end */}
          {/* Beneficiary Data block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.BENEFICIARIES) &&
            !activeProduct.hasExistingContract && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BENEFICIARIES_BREADCRUMB}
                      {!alreadyApplied && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.BENEFICIARIES)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BENEFICIARIES.FORM.BENEFICIARIES_PURPOSE_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {beneficiaryData.length === 0
                        ? STRINGS.COMMON.CHOICE.NO
                        : STRINGS.COMMON.CHOICE.YES}
                    </div>
                  </div>
                </div>

                {beneficiaryData.length > 0 &&
                  beneficiaryData.map((record, index) => (
                    <div key={record}>
                      <div className="[ row ] [ details-section__item ] [ mt-3 ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.BENEFICIARY}
                            {index + 1}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {
                              STRINGS.BENEFICIARIES.FORM
                                .FIRSTNAMELABELCONFIRMPAGE
                            }
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.firstName ? record.firstName : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {
                              STRINGS.BENEFICIARIES.FORM
                                .MIDDLENAMELABELCONFIRMPAGE
                            }
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.middleName
                              ? record.middleName
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {
                              STRINGS.BENEFICIARIES.FORM
                                .LASTNAMELABELCONFIRMPAGE
                            }
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.lastName ? record.lastName : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.DOBLABELCONFIRMPAGE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.dateOfBirth
                              ? record.dateOfBirth
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.RELATIONSHIPCONFIRMPAGE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.beneficiaryRelation
                              ? record.beneficiaryRelation
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {
                              STRINGS.BENEFICIARIES.FORM
                                .BENEFICIARY_TYPECONFIRMPAGE
                            }
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.beneficiaryType
                              ? record.beneficiaryType
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.ADDRESS1CONFIRMPAGE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.address1 ? record.address1 : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.ADDRESS2CONFIRMPAGE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.address2 ? record.address2 : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.CITYCONFIRMPAGE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.city ? record.city : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.PROVINCECONFIRMPAGE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.province
                              ? getDescriptionFromArray(
                                  provinceList,
                                  record.province
                                )
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.POSTALCODECONFIRMPAGE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.postalCode
                              ? record.postalCode
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {
                              STRINGS.BENEFICIARIES.FORM
                                .MOBILENOLABELCONFIRMPAGE
                            }
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.homeArea
                              ? this.formatPhoneNumber(
                                  `${record.homeArea}${record.homePhone}`
                                )
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.COUNTRYCONFIRMPAGE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.country ? record.country : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.BENEFICIARIES.FORM.SINCONFIRMPAGE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {record && record.sin
                              ? `******${record.sin.substr(
                                  employmentData.sin.length - 3
                                )}`
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          {/* Beneficiary Data block end */}

          {show.includes(AppConstants.APPLICATIONSTEP.LOAN_PURPOSE) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center capitalize ]">
                    {STRINGS.LOAN_PURPOSE.SUMMARY_TITLE}
                    {!activeProduct.isJointProduct && !alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.LOAN_PURPOSE)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>
              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.LOAN_PURPOSE.FORM.PURPOSE_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.loanPurposeDesc}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Loan Details block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.LOAN_DETAILS) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center capitalize ]">
                    {STRINGS.LOAN_DETAILS.TITLE}
                    {!activeProduct.isJointProduct && !alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.LOAN_DETAILS)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>
              <div className="[ row ] [ details-section__item ]">
                {activeProduct.category !==
                  AppConstants.LOAN_CATEGORY.HELOC && (
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT
                      }
                    </div>
                  </div>
                )}
                {activeProduct.category ===
                  AppConstants.LOAN_CATEGORY.HELOC && (
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.HELOC.REQUESTED_CREDIT_AMOUNT}
                    </div>
                  </div>
                )}
                {activeProduct.category !==
                  AppConstants.LOAN_CATEGORY.MORTGAGE && (
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-description ]">
                      <AmountDisplay amount={loanDetails.loanAmount} />
                    </div>
                  </div>
                )}
                {activeProduct.category ===
                  AppConstants.LOAN_CATEGORY.MORTGAGE && (
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-description ]">
                      <AmountDisplay amount={loanDetails.estimatedMortgage} />
                    </div>
                  </div>
                )}
              </div>

              {activeProduct.category !== AppConstants.LOAN_CATEGORY.LOC && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM
                      }
                    </div>
                  </div>
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-description ]">
                      {loanDetails.loanTerm === 0
                        ? AppConstants.LOANS.REVOLVING
                        : `${loanDetails.loanTerm} months`}
                    </div>
                  </div>
                </div>
              )}

              {activeProduct.category ===
                AppConstants.LOAN_CATEGORY.MORTGAGE && (
                <>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.LOAN_DETAILS.FORM.AMORTIZATION}
                      </div>
                    </div>
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-description ]">
                        {loanDetails.amortization} years
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-6 ]">
                  <div className="[ details-section__item-label ]">
                    {
                      STRINGS.LOAN_DETAILS.FORM
                        .PRODUCT_LABEL_LOAN_DETAILS_INTEREST_RATE
                    }
                  </div>
                </div>
                <div className="[ col-6 ]">
                  <div className="[ details-section__item-description ]">
                    {parseFloat(loanDetails.loanRate).toFixed(2)}%
                  </div>
                </div>
              </div>

              {activeProduct.category !== AppConstants.LOAN_CATEGORY.LOC && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.REPAYMENT_FREQUENCY_LABEL}
                    </div>
                  </div>
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-description ]">
                      {this.capitalize(loanDetails.paymentType)}
                    </div>
                  </div>
                </div>
              )}

              {activeProduct.category !== AppConstants.LOAN_CATEGORY.LOC &&
                activeProduct.category !== AppConstants.LOAN_CATEGORY.HELOC && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.LOAN_DETAILS.FORM.MONTHLY_PAYMENT}
                      </div>
                    </div>
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-description ]">
                        <AmountDisplay amount={loanDetails.monthlyPayment} />
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
          {/* Loan Details  block end */}

          {activeProduct.property && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center capitalize ]">
                    {STRINGS.PROPERTY_DETAILS.TITLE}
                    {!alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.PROPERTY_DETAILS)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>
              {!(
                activeProduct.property.unitNumber ||
                activeProduct.property.streetNumber ||
                activeProduct.property.streetType ||
                activeProduct.property.street ||
                activeProduct.property.city ||
                activeProduct.property.province ||
                activeProduct.property.postalCode
              ) && (
                <div className="[ details-section__item ] [ mb-3 ]">
                  {STRINGS.PROPERTY_DETAILS.REVIEW.NO}{" "}
                  {STRINGS.PROPERTY_DETAILS.TITLE.toLowerCase()}{" "}
                  {STRINGS.PROPERTY_DETAILS.REVIEW.WERE_ADDED}.
                </div>
              )}
              {(activeProduct.property.unitNumber ||
                activeProduct.property.streetNumber ||
                activeProduct.property.streetType ||
                activeProduct.property.street ||
                activeProduct.property.city ||
                activeProduct.property.province ||
                activeProduct.property.postalCode) && (
                <>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.UNIT_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.unitNumber}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.STREETNUMBER_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.streetNumber}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.STREETNAME_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.street}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.STREETTYPE_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {findCode(
                          activeProduct.property.streetType,
                          streetTypes
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.CITY_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.city}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.PROVINCE_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.province}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.POST_CODE_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.postalCode}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.OWNERSHIP}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.tenure}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.OCCUPANCY}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.occupancy}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {activeProduct.vehicle && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center capitalize ]">
                    {STRINGS.SECURITY_VEHICLE.TITLE}
                    {!alreadyApplied && (
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.SECURITY_VEHICLE)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    )}
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.SECURITY_VEHICLE.FORM.YEAR_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.vehicle.year}
                  </div>
                </div>
              </div>
              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.SECURITY_VEHICLE.FORM.MAKE_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.vehicle.make}
                  </div>
                </div>
              </div>
              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.SECURITY_VEHICLE.FORM.MODEL_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.vehicle.model}
                  </div>
                </div>
              </div>
              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.SECURITY_VEHICLE.FORM.VIN_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.vehicle.serial}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Financial Analysis List block start */}

          {/* INCOME block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.INCOME) &&
            !activeProduct.isJointProduct && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.INCOME.TITLE}
                      {!alreadyApplied && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.INCOME)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>
                {incomeList.length === 1 && (
                  <div className="[ details-section__item ] [ mb-3 ]">
                    {STRINGS.INCOME.REVIEW.NO}{" "}
                    {STRINGS.INCOME.TITLE.toLowerCase()}{" "}
                    {STRINGS.INCOME.REVIEW.WERE_ADDED}.
                  </div>
                )}
                {incomeList.length > 1 &&
                  incomeList.map((income, idx) => (
                    <>
                      {idx > 0 && (
                        <div className="[ details-section ] [ mb-3 ]" key={idx}>
                          <div className="[ row ] [ details-section__header">
                            <div className="[ form-subtitle ]">
                              {STRINGS.INCOME.REVIEW.INCOME_NO} {idx}
                            </div>
                          </div>
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.INCOME.FORM.INCOME_TYPE}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {income && income.subType ? income.subType : ""}
                              </div>
                            </div>
                          </div>
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.INCOME.FORM.DESCRIPTION}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {income && income.description
                                  ? income.description
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.INCOME.FORM.MONTHLY_AMOUNT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={income?.monthly} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            )}

          {/* ASSET block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.ASSET) &&
            !activeProduct.isJointProduct && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.ASSET.TITLE}
                      {!alreadyApplied && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.ASSET)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>

                {assetList.length === 0 && (
                  <div className="[ details-section__item ] [ mb-3 ]">
                    {STRINGS.ASSET.REVIEW.NO}{" "}
                    {STRINGS.ASSET.TITLE.toLowerCase()}{" "}
                    {STRINGS.ASSET.REVIEW.WERE_ADDED}.
                  </div>
                )}
                {assetList.length > 0 &&
                  assetList.map((asset, idx) => (
                    <div className="[ details-section ] [ mb-3 ]">
                      <div className="[ row ] [ details-section__header">
                        <div className="[ form-subtitle ]">
                          {STRINGS.ASSET.REVIEW.ASSET_NO} {idx + 1}
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.ASSET.FORM.ASSET_TYPE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {asset && asset.subType ? asset.subType : ""}
                          </div>
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.ASSET.FORM.DESCRIPTION}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {asset && asset.description
                              ? asset.description
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.ASSET.FORM.VALUE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            <AmountDisplay amount={asset?.value} />
                          </div>
                        </div>
                      </div>

                      {/* <div className="[ row ] [ details-section__item ]"> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-label ]"> */}
                      {/*      {STRINGS.ASSET.FORM.ORGANIZATION_TITLE} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-description ]"> */}
                      {/*      {asset && asset.institution ? asset.institution : ""} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}

                      {/* <div className="[ row ] [ details-section__item ]"> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-label ]"> */}
                      {/*      {STRINGS.ASSET.FORM.PRIMARY_RESIDENCE_TITLE} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-description ]"> */}
                      {/*      {asset && asset.primaryResidence ? "Yes" : "No"} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}
                    </div>
                  ))}
                {/* End Loop */}
              </div>
            )}

          {/* EXPENSE block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.EXPENSE) &&
            !activeProduct.isJointProduct && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.EXPENSE.TITLE}
                      {!alreadyApplied && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.EXPENSE)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>

                {expenseList.length === 0 && (
                  <div className="[ details-section__item ] [ mb-3 ]">
                    {STRINGS.EXPENSE.REVIEW.NO}{" "}
                    {STRINGS.EXPENSE.TITLE.toLowerCase()}{" "}
                    {STRINGS.EXPENSE.REVIEW.WERE_ADDED}.
                  </div>
                )}
                {expenseList.length > 0 &&
                  expenseList.map((expense, idx) => (
                    <div className="[ details-section ] [ mb-3 ]">
                      <div className="[ row ] [ details-section__header ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ form-subtitle ]">
                            {STRINGS.EXPENSE.REVIEW.EXPENSE_NO} {idx + 1}
                          </div>
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.EXPENSE.FORM.EXPENSE_TYPE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {expense && expense.subType ? expense.subType : ""}
                          </div>
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.EXPENSE.FORM.DESCRIPTION}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {expense && expense.description
                              ? expense.description
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.EXPENSE.FORM.MONTHLY_AMOUNT}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            <AmountDisplay amount={expense?.monthly} />
                          </div>
                        </div>
                      </div>

                      {/* <div className="[ row ] [ details-section__item ]"> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-label ]"> */}
                      {/*      {STRINGS.EXPENSE.FORM.PRIMARY_RESIDENCE_TITLE} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-description ]"> */}
                      {/*      {expense && expense.primaryResidence ? "Yes" : "No"} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}
                    </div>
                  ))}
                {/* End Loop */}
              </div>
            )}

          {/* LIABILITIES block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.LIABILITIES) &&
            !activeProduct.isJointProduct && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.LIABILITIES.TITLE}
                      {!alreadyApplied && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.LIABILITIES)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>

                {liabilitiesList.length === 0 && (
                  <div className="[ details-section__item ] [ mb-3 ]">
                    {STRINGS.LIABILITIES.REVIEW.NO}{" "}
                    {STRINGS.LIABILITIES.TITLE.toLowerCase()}{" "}
                    {STRINGS.LIABILITIES.REVIEW.WERE_ADDED}.
                  </div>
                )}
                {liabilitiesList.length > 0 &&
                  liabilitiesList.map((liabilities, idx) => (
                    <div className="[ details-section ] [ mb-3 ]">
                      <div className="[ row ] [ details-section__header ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ form-subtitle ]">
                            {STRINGS.LIABILITIES.REVIEW.LIABILITY_NO} {idx + 1}
                          </div>
                        </div>
                      </div>
                      <div className="[ row ] [ details-section__item ]">
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-label ]">
                            {STRINGS.LIABILITIES.FORM.LIABILITY_TYPE}
                          </div>
                        </div>
                        <div className="[ col-sm-6 ]">
                          <div className="[ details-section__item-description ]">
                            {liabilities && liabilities.subType
                              ? liabilities.subType
                              : ""}
                          </div>
                        </div>
                      </div>

                      {liabilities.subType === "Mortgage" ||
                      liabilities.subType === "Mortage" ? (
                        <div className="[ mb-3 ]">
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.MORTGAGE.DESCRIPTION}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {liabilities && liabilities.description
                                  ? liabilities.description
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.MORTGAGE.VALUE}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.value} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.MORTGAGE.LIMIT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.locLimit} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.monthly} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Vehicle Loan */}
                      {liabilities.subType === "Vehicle Loan" ? (
                        <div className="[ mb-3 ]">
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {
                                  STRINGS.LIABILITIES.FORM.VEHICLE_LOAN
                                    .DESCRIPTION
                                }
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {liabilities && liabilities.description
                                  ? liabilities.description
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.VEHICLE_LOAN.VALUE}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.value} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.VEHICLE_LOAN.LIMIT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.locLimit} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {
                                  STRINGS.LIABILITIES.FORM.VEHICLE_LOAN
                                    .TERM_OF_LOAN
                                }
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {liabilities && liabilities.description
                                  ? liabilities.description
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.monthly} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Personal Loan */}
                      {liabilities.subType === "Loan" ||
                      liabilities.subType === "Personal Loan" ? (
                        <div className="[ mb-3 ]">
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.LOAN.DESCRIPTION}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {liabilities && liabilities.description
                                  ? liabilities.description
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.LOAN.VALUE}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.value} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.LOAN.LIMIT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.locLimit} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.monthly} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Credit Card */}
                      {liabilities.subType === "CCard" ||
                      liabilities.subType === "Credit Card" ? (
                        <div className="[ mb-3 ]">
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {
                                  STRINGS.LIABILITIES.FORM.CREDIT_CARD
                                    .DESCRIPTION
                                }
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {liabilities && liabilities.description
                                  ? liabilities.description
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.CREDIT_CARD.VALUE}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.value} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.CREDIT_CARD.LIMIT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.locLimit} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.monthly} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Line of Credit or LOC */}
                      {liabilities.subType === "Line of Credit" ||
                      liabilities.subType === "LOC" ? (
                        <div className="[ mb-3 ]">
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {
                                  STRINGS.LIABILITIES.FORM.LINE_OF_CREDIT
                                    .DESCRIPTION
                                }
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {liabilities && liabilities.description
                                  ? liabilities.description
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.LINE_OF_CREDIT.VALUE}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.value} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.LINE_OF_CREDIT.LIMIT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.locLimit} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.monthly} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Line of Credit or LOC */}
                      {liabilities.subType === "Other" ||
                      liabilities.subType === "Others" ||
                      liabilities.subType === "Guarantor" ||
                      liabilities.subType === "Guar" ? (
                        <div className="[ mb-3 ]">
                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.DESCRIPTION}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                {liabilities && liabilities.description
                                  ? liabilities.description
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.VALUE}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.value} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.LIMIT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.locLimit} />
                              </div>
                            </div>
                          </div>

                          <div className="[ row ] [ details-section__item ]">
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-label ]">
                                {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                              </div>
                            </div>
                            <div className="[ col-sm-6 ]">
                              <div className="[ details-section__item-description ]">
                                <AmountDisplay amount={liabilities?.monthly} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* <div className="[ row ] [ details-section__item ]"> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-label ]"> */}
                      {/*      {STRINGS.LIABILITIES.FORM.ORGANIZATION_TITLE} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-description ]"> */}
                      {/*      {liabilities && liabilities.institution */}
                      {/*        ? liabilities.institution */}
                      {/*        : ""} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}

                      {/* <div className="[ row ] [ details-section__item ]"> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-label ]"> */}
                      {/*      {STRINGS.LIABILITIES.FORM.ADJUSTMENT} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-description ]"> */}
                      {/*      {liabilities && liabilities.adjustment */}
                      {/*        ? liabilities.adjustment */}
                      {/*        : ""} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}

                      {/* <div className="[ row ] [ details-section__item ]"> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-label ]"> */}
                      {/*      {STRINGS.LIABILITIES.FORM.SECURED_LABEL} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-description ]"> */}
                      {/*      {liabilities && liabilities.secType */}
                      {/*        ? liabilities.secType */}
                      {/*        : ""} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}

                      {/* <div className="[ row ] [ details-section__item ]"> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-label ]"> */}
                      {/*      {STRINGS.LIABILITIES.FORM.RATE} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-description ]"> */}
                      {/*      {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX} */}
                      {/*      {liabilities && liabilities.intRate */}
                      {/*        ? liabilities.intRate */}
                      {/*        : ""} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}

                      {/* <div className="[ row ] [ details-section__item ]"> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-label ]"> */}
                      {/*      {STRINGS.LIABILITIES.FORM.PAYMENT_TYPE} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-description ]"> */}
                      {/*      {liabilities && liabilities.paymentType */}
                      {/*        ? liabilities.paymentType */}
                      {/*        : ""} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}

                      {/* <div className="[ row ] [ details-section__item ]"> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-label ]"> */}
                      {/*      {STRINGS.LIABILITIES.FORM.PRIMARY_RESIDENCE_TITLE} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/*  <div className="[ col-sm-6 ]"> */}
                      {/*    <div className="[ details-section__item-description ]"> */}
                      {/*      {liabilities && liabilities.primaryResidence */}
                      {/*        ? "Yes" */}
                      {/*        : "No"} */}
                      {/*    </div> */}
                      {/*  </div> */}
                      {/* </div> */}
                    </div>
                  ))}
                {/* End Loop */}
              </div>
            )}

          {show.includes(AppConstants.APPLICATIONSTEP.DOCUMENT_UPLOAD) &&
            !activeProduct.isJointProduct && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.DOCUMENT_UPLOAD.TITLE}
                      {!alreadyApplied && (
                        <button
                          type="button"
                          className="[ btn edit-btn ]"
                          onClick={() => handleEditPage(ROUTES.DOCUMENT_UPLOAD)}
                          aria-label="edit"
                        >
                          <span className="[ edit-icon ]" />
                          <span className="[ edit-btn__label ]">
                            {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                          </span>
                        </button>
                      )}
                    </h3>
                  </div>
                </div>

                {(!documentList || documentList?.length === 0) && (
                  <div className="[ details-section__item ] [ mb-3 ]">
                    {STRINGS.DOCUMENT_UPLOAD.REVIEW.NO_DOCUMENTS}{" "}
                    {STRINGS.DOCUMENT_UPLOAD.REVIEW.WERE_ADDED}.
                  </div>
                )}
                {documentList?.length > 0 &&
                  documentList.map((doc, idx) => (
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-3 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.DOCUMENT_UPLOAD.REVIEW.DOCUMENT_NO} {idx + 1}
                        </div>
                      </div>
                      <div className="[ col-9 ]">
                        <div className="[ details-section__item-label ]">
                          {doc.documentName}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}

          {/* Financial Analysis List block end */}

          {/* check notice start */}

          {!alreadyApplied && (
            <div className="[ mb-3 ] [ alert alert-warning ]" role="alert">
              <div className="[ row ] [ mb-3 ]">
                <div className="[ col-12 ]">
                  <i className="[ fas fa-exclamation-circle ]" />
                  <span>
                    {STRINGS.CONFIRM_CREATION.ACCOUNT_DISCLAIMER}
                    <ul>
                      <li>{STRINGS.CONFIRM_CREATION.BUREAU_CHECK}</li>
                    </ul>
                    {!contKycFlag && (
                      <>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                STRINGS.CONFIRM_CREATION.ACCOUNT_TERMS_CHECK,
                            }}
                          />
                        </p>
                        <p>
                          <strong>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  STRINGS.CONFIRM_CREATION.ACCOUNT_TERMS_CHECK1,
                              }}
                            />
                          </strong>
                        </p>
                      </>
                    )}
                    {contKycFlag && (
                      <>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                STRINGS.CONFIRM_CREATION
                                  .ACCOUNT_TERMS_CHECK_KYC,
                            }}
                          />
                        </p>
                        <p>
                          <strong>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  STRINGS.CONFIRM_CREATION
                                    .ACCOUNT_TERMS_CHECK1_KYC,
                              }}
                            />
                          </strong>
                        </p>
                      </>
                    )}
                  </span>
                </div>
              </div>
              <div className="[ row ] [ align-items-center ]">
                <div className="[ col-12 ] [ d-flex align-items-center ]">
                  <div className=" [ checkbox custom-control custom-checkbox ]">
                    <input
                      type="checkbox"
                      defaultChecked={isAcceptedTerms}
                      onChange={this.handleChecked}
                      name="termscondition"
                      id="termsCheckbox"
                      className="[ custom-control-input ]"
                    />
                    <label
                      htmlFor="termsCheckbox"
                      className="[ custom-control-label ]"
                    >
                      <span className="[ form-checkbox__text ]">
                        {STRINGS.CONFIRM_CREATION.ACCOUNT_DISCLAIMER_LABEL}
                      </span>
                    </label>
                  </div>
                </div>

                <div className="[ error-msg ] [ col-12 ]">&nbsp;</div>
              </div>
            </div>
          )}
          <div className="[ confirm-details__footer ]">
            <div className="[ confirm-details-notice-wrapper ]">
              <div className="[ col-12 ] [ confirm-account-opening ]" />
              <div className="[ col-12 ] [ confirm-account-opening ]" />
              <div className="[ col-12 ] [ confirm-account-opening-question ]" />
            </div>

            {/* check notice end */}
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinueClick}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={actionCompnent}
          memberCreation
          showVault={showVault}
          showVaultClicked={() => showVaultClicked}
          handleBack={handleBack}
        />
        {showConsentMessage && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={STRINGS.CONFIRM_CREATION.CONSENT_MSG}
            toggleModal={this.hideConsentMessage}
            showModal={showConsentMessage}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.hideConsentMessage}
            closeBtnClick={this.hideConsentMessage}
          />
        )}
      </>
    );
  }
}

ConfirmAccountCreate.propTypes = {
  showModal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  showVaultClicked: PropTypes.func,
  showVault: PropTypes.bool,
  handleSubmitDetails: PropTypes.func,
  handleEditPage: PropTypes.func,
  gettingStartedBranchData: PropTypes.arrayOf(PropTypes.any),
  incomeSourcesList: PropTypes.arrayOf(PropTypes.any),
  statusList: PropTypes.arrayOf(PropTypes.any),
  provinceList: PropTypes.arrayOf(PropTypes.any),
  accountPurposeList: PropTypes.arrayOf(PropTypes.any),
  handlePopupModalBtnClick: PropTypes.func,
  handleBack: PropTypes.func,
  getDataFromServer: PropTypes.func.isRequired,
  processFlow: PropTypes.arrayOf(PropTypes.object),
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doGetProductInformation: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  vaultProductList: PropTypes.arrayOf(PropTypes.object).isRequired,
  doGetBeneficiaryData: PropTypes.func.isRequired,
  doGetSpousalInformation: PropTypes.func.isRequired,
  isDebitCardEnabled: PropTypes.bool,
  enableDebitCard: PropTypes.bool,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  debitCardShown: PropTypes.bool,
  inSessionJointApplicant: PropTypes.oneOfType([PropTypes.object]),
  applicationCompleteList: PropTypes.arrayOf(PropTypes.object),
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  intendedUseList: PropTypes.arrayOf(PropTypes.object),
  vaultMember: PropTypes.oneOfType([PropTypes.object]),
  alreadyApplied: PropTypes.bool,
  activeProduct: PropTypes.oneOfType([PropTypes.object]),
  countryList: PropTypes.arrayOf(PropTypes.object),
  sessionCompletedProducts: PropTypes.arrayOf(PropTypes.object),
  applicantData: PropTypes.oneOfType([PropTypes.object]),
};
ConfirmAccountCreate.defaultProps = {
  showVaultClicked: () => {},
  handleSubmitDetails: () => {},
  showVault: null,
  handleEditPage: () => {},
  handlePopupModalBtnClick: () => {},
  handleBack: () => {},
  gettingStartedBranchData: [],
  incomeSourcesList: [],
  statusList: [],
  provinceList: [],
  accountPurposeList: [],
  processFlow: [],
  steps: [],
  activeStepID: -1,
  isDebitCardEnabled: false,
  enableDebitCard: false,
  debitCardShown: false,
  inSessionJointApplicant: null,
  applicationCompleteList: [],
  intendedUseList: [],
  countryList: [],
  vaultMember: {},
  alreadyApplied: false,
  activeProduct: {},
  sessionCompletedProducts: [],
  applicantData: null,
  finInfo: {},
};

const mapStateToProps = (state) => ({
  processFlow: state.ApplicationReducer.processTemplate.flow,
  vaultProductList: state.VaultReducer.vaultProductList,
  isDebitCardEnabled: state.DebitCardReducer.isDebitCardEnabled,
  enableDebitCard: state.DebitCardReducer.enableDebitCard,
  debitCardShown: state.DebitCardReducer.debitCardShown,
  applicantData: state.ApplicationReducer.response,
  finInfo: state.MarketplaceReducer.finInfo,
  streetTypes: state.LoanDetailsReducer.streetTypes,
  occupationCodes: state.ApplicationReducer.occupationCodes,
  employerPositionCodes: state.EmploymentAndIncomeReducer.employerPositionCodes,
  reasonCodes: state.ApplicationReducer.reasonCodes,
  intendedUseList: state.AccountInformationReducer.intendedUseList,
  countryList: state.EmploymentAndIncomeReducer.countryList,
  vaultMember: state.ApplicationReducer.vaultMember,
  sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
});

export default connect(mapStateToProps)(ConfirmAccountCreate);
