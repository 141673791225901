import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import constants
import * as ROUTES from "Constants/Routes";
import MODALMESSAGES from "Constants/Messages";
import AppConstants from "Constants/AppConstants";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";

// import common actions
import {
  toggleEditFlag,
  getApplicationList,
} from "Redux/Actions/ApplicationAction";

import { getProductInformation } from "Redux/Actions/ProductInformationAction";

import { HTTP_STATUS } from "Communication/Constants";

import { fetchGettingStartedBranch } from "Components/GettingStarted/Actions/GettingStartedActions";

import { doGetProvinceList } from "Components/AboutYou/Actions/AboutYouActions";

import { getBeneficiaries } from "Components/Beneficiaries/Action/BeneficiariesAction";
import { updateProductToVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import {
  getIncomeSourcesList,
  getStatusList,
} from "Components/EmploymentAndIncome/Actions/EmploymentAndIncomeAction";

import {
  getAccountPurposeList,
  getIndicateWhoList,
} from "Components/Compliance/Actions/ComplianceActions";

import { getFinancialAnalysisFinancialList } from "Components/LoanDetails/Actions/LoanDetailsAction";

import {
  getApplicantType,
  getApplicationProperty,
} from "Components/JointApplicant/Actions/JointApplicantAction";
import { getSpousalInfo } from "Components/SpousalInfo/Actions/SpousalInfoAction";
import ConfirmAccountCreate from "./ConfirmAccountCreate";
// import actions
import {
  doConfirmApplication,
  doConfirmSuccess,
} from "./Actions/ConfirmAccountCreateAction";
// import component styles
import "./ConfirmAccountCreate.scss";
import { getDocumentList } from "../Documents/Actions/DocumentAction";
import { doConsent } from "../TermsAndConditions/Actions/TermsActions";
import { LENDING_PRODUCT, S_KYC } from "../../Communication/Constants";
import updateApplicantData from "../../Redux/Actions/UpdateApplicantAction";
import { getLoanLoanSecurity } from "../LoanDetails/Actions/LoanDetailsAction";
import { format } from "date-fns";
import {
  getCountryList,
  getEmployerPositionCodes,
} from "../EmploymentAndIncome/Actions/EmploymentAndIncomeAction";
import { getTermProduct } from "../GICInfo/Actions/GICActions";
import getAccount from "../../Redux/Actions/GetAccountAction";
import {
  allowKycEdit,
  allowKycEditForJointInSession,
  getNatureOfBusiness,
  getNatureOfBusinessForJoint,
  isNewMember,
  isPepDataEqual,
  isThirdPartyDataEqual,
} from "../../Utils/LogicUtilities";
import {
  getContract,
  updateContract,
} from "../../Redux/Actions/ApplicationAction";
import getIntendedUseList from "../AccountInformation/Actions/AccountInformationAction";
import STRINGS from "../../Constants/Strings";
import { addBundleProduct } from "../DebitCard/Actions/DebitCardAction";
import getBundleProductRelation from "../../Redux/Actions/GetBundleProductRelationAction";
import {
  getContinousKycRegulatorUpdates,
  isScanningAllowed,
} from "../../Utils/CommonUtilities";
import PopupModal from "../Common/PopupModal/PopupModal";
import Messages from "../../Constants/Messages";
import { deleteApplicationSlot } from "../JointApplicant/Actions/JointApplicantAction";
import { updateVaultMember } from "../AboutYou/Actions/AboutYouActions";

class ConfirmAccountCreateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
      erroCode: null,
      alreadyApplied: false,
      productIntendedUseList: [],
      showKycMessage: false,
      kycList: "",
      contKycFlag: false,
    };
  }

  /**
   * Populate data for select if data does not exist in reducers
   */
  componentDidMount() {
    const {
      provinceList,
      incomeSourcesList,
      statusList,
      accountPurposeList,
      indicateWhoList,
      gettingStartedBranchData,
      getActiveProduct,
      vaultProductList,
      doGetApplicationProperty,
      doDisableBack,
      inSessionJointApplicant,
      employerPositionCodes,
      doGetEmployerPositionCodes,
      account,
      doGetAccountDetails,
      doConfirmSuccess,
      intendedUseList,
      doGetIntendedUseList,
      vaultMember,
      applicantData,
      sessionCompletedProducts,
      finInfo,
      doDeleteApplicationSlot,
    } = this.props;
    console.log("componentDidMount");
    doConfirmSuccess({ successConfirmed: false });

    const activeProduct = getActiveProduct(vaultProductList);

    const kycMessageShown = sessionStorage.getItem(S_KYC);
    if (kycMessageShown !== activeProduct.applicationId) {
      const newOECD = vaultMember?.regulatoryFields?.find(
        (item) => item.Code === 1
      );
      const currentOECD = applicantData?.regulatory?.regulatoryFields?.find(
        (item) => item.Code === 1
      );
      let vaultData = vaultMember;
      if (inSessionJointApplicant) {
        vaultData = inSessionJointApplicant.vault;
      }
      if (
        (allowKycEdit(
          applicantData,
          sessionCompletedProducts,
          finInfo,
          inSessionJointApplicant
        ) ||
          (inSessionJointApplicant &&
            allowKycEditForJointInSession(applicantData, finInfo))) &&
        ((vaultData.name?.toLowerCase() !==
          applicantData.member.name?.toLowerCase() &&
          vaultData.name?.toLowerCase() !==
            vaultData.old?.name?.toLowerCase()) ||
          (vaultData.middleName?.toLowerCase() !==
            applicantData.member.middleName?.toLowerCase() &&
            vaultData.middleName?.toLowerCase() !==
              vaultData.old?.middleName?.toLowerCase()) ||
          (vaultData.surname?.toLowerCase() !==
            applicantData.member.surname?.toLowerCase() &&
            vaultData.surname?.toLowerCase() !==
              vaultData.old?.surname?.toLowerCase()) ||
          (vaultData.dob !== applicantData.member.dob &&
            vaultData.dob !== vaultData.old?.dob) ||
          (vaultData.sin !== applicantData.member.sin &&
            vaultData.sin !== vaultData.old?.sin) ||
          (vaultData.fatcaStatus?.toUpperCase() ===
            finInfo.noFatcaCrs.toUpperCase() &&
            applicantData.regulatory.fatcaStatus?.toUpperCase() !==
              finInfo.noFatcaCrs.toUpperCase() &&
            currentOECD.Value?.toUpperCase() ===
              finInfo.oecdCrs.toUpperCase() &&
            newOECD.Value?.toUpperCase() === finInfo.noFatcaCrs.toUpperCase()))
      ) {
        let list = "";
        if (
          vaultData.name?.toLowerCase() !==
          applicantData.member.name?.toLowerCase()
        ) {
          list = "<br/>- First Name";
        }
        if (
          vaultData.middleName?.toLowerCase() !==
          applicantData.member.middleName?.toLowerCase()
        ) {
          list += "<br/>- Middle Name";
        }
        if (
          vaultData.surname?.toLowerCase() !==
          applicantData.member.surname?.toLowerCase()
        ) {
          list += "<br/>- Last Name";
        }
        if (vaultData.dob !== applicantData.member.dob) {
          list += "<br/>- Date of Birth";
        }
        if (vaultData.sin !== applicantData.member.sin) {
          list += "<br/>- SIN";
        }
        if (
          vaultData.fatcaStatus?.toUpperCase() ===
            finInfo.noFatcaCrs?.toUpperCase() &&
          applicantData.regulatory.fatcaStatus?.toUpperCase() !==
            finInfo.noFatcaCrs?.toUpperCase() &&
          currentOECD.Value?.toUpperCase() === finInfo.oecdCrs?.toUpperCase() &&
          newOECD.Value?.toUpperCase() === finInfo.noFatcaCrs?.toUpperCase()
        ) {
          list += "<br/>- Tax information";
        }

        const kycList = Messages.KYC.LIST.split("_LIST_").join(list);
        sessionStorage.setItem(S_KYC, activeProduct.applicationId);
        this.setState({
          showKycMessage: true,
          kycList,
        });
      }
    }

    if (!account.consent) {
      doGetAccountDetails();
    }

    if (intendedUseList.length === 0) {
      doGetIntendedUseList();
    } else {
      this.getProductIntendedUseList();
    }

    if (!activeProduct.jointExternalApplicationId) {
      doGetApplicationProperty(
        { applicationId: activeProduct.applicationId },
        (appPropertyRes) => {
          if (inSessionJointApplicant) {
            const info = appPropertyRes.slots.find(
              (slot) => slot.id === inSessionJointApplicant.id
            );
            if (info.appliedAt) {
              this.setState({
                alreadyApplied: true,
              });
              doDisableBack(true);
            }
          } else {
            if (appPropertyRes.appliedAt || appPropertyRes.slots[0].appliedAt) {
              this.setState({
                alreadyApplied: true,
              });
              doDisableBack(true);
            }
            appPropertyRes.slots.map((slot) => {
              if (!slot.inviteDetail && slot.type !== "main") {
                doDeleteApplicationSlot(
                  {
                    applicationId: activeProduct.applicationId,
                    slotId: slot.id,
                  },
                  (deleteSlotRes) => {
                    console.log(deleteSlotRes);
                  }
                );
              }
            });
          }
        }
      );
    }

    if (gettingStartedBranchData && gettingStartedBranchData.length <= 0) {
      const { dofetchGettingStartedBranchs } = this.props;
      dofetchGettingStartedBranchs();
    }

    // province list is needed on about you page details
    if (provinceList && provinceList.length <= 0) {
      const { doGetProvinceData } = this.props;
      doGetProvinceData();
    }

    // income source list is needed on Employment and income page details
    if (incomeSourcesList && incomeSourcesList.length <= 0) {
      const { doGetIncomeSourcesList } = this.props;
      doGetIncomeSourcesList();
    }

    // Status list is needed for employment and income page details
    if (statusList && statusList.length <= 0) {
      const { doGetStatusList } = this.props;
      doGetStatusList();
    }

    if (accountPurposeList && accountPurposeList.length <= 0) {
      const { doGetAccountPurposeList } = this.props;
      doGetAccountPurposeList();
    }

    if (indicateWhoList && indicateWhoList.length <= 0) {
      const { doGetIndicateWhoList } = this.props;
      doGetIndicateWhoList();
    }

    if (employerPositionCodes.length == 0) {
      doGetEmployerPositionCodes();
    }

    if (
      allowKycEdit(
        applicantData,
        sessionCompletedProducts,
        finInfo,
        inSessionJointApplicant
      ) ||
      (inSessionJointApplicant &&
        allowKycEditForJointInSession(applicantData, finInfo))
    ) {
      this.setState({ contKycFlag: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { intendedUseList } = this.props;
    if (intendedUseList !== prevProps.intendedUseList) {
      this.getProductIntendedUseList();
    }
  }

  getProductIntendedUseList() {
    const { intendedUseList, vaultProductList } = this.props;
    const activeProduct = vaultProductList[0];
    // search first if there is custom list based on productId
    let productIntendedUseList = intendedUseList.filter(
      (item) =>
        item.productType === activeProduct.type &&
        item.productId &&
        item.productId === activeProduct.productId
    );
    if (productIntendedUseList.length === 0) {
      productIntendedUseList = intendedUseList.filter(
        (item) => item.productType === activeProduct.type && !item.productId
      );
    }
    this.setState({
      productIntendedUseList,
    });
  }

  /**
   * toggleModal handles popup modal
   * show / hide state
   */

  toggleModal = () => {
    const { showModal, erroCode } = this.state;
    this.setState({
      showModal: !showModal,
    });
    if (erroCode) {
      const { history } = this.props;
      history.push(ROUTES.EXISTING_MEMBER);
    }
  };

  getMarketingData = (marketingFlag) => {
    const { finInfo } = this.props;
    if (marketingFlag) {
      return {
        consentMarketing: finInfo.config.marketingEnabled?.consentMarketing,
        consentMarketingMethod:
          finInfo.config.marketingEnabled?.consentMarketingMethod,
        consentMarketingDate: finInfo.config.marketingEnabled
          ?.consentMarketingDate
          ? format(new Date(), "yyyy-MM-dd")
          : "",
      };
    }
    return {
      consentMarketing: finInfo.config.marketingDisabled?.consentMarketing,
      consentMarketingMethod:
        finInfo.config.marketingDisabled?.consentMarketingMethod,
      consentMarketingDate: finInfo.config.marketingDisabled
        ?.consentMarketingDate
        ? format(new Date(), "yyyy-MM-dd")
        : "",
    };
  };

  handleSubmitDetails = () => {
    const {
      doConfirmApp,
      getActiveProduct,
      vaultProductList,
      fillApplicationSlot,
      doGetApplicationProperty,
      inSessionJointApplicant,
      history,
      doFillApplicationSlot,
      doSetInSessionJointApplicant,
      doUpdateApplicant,
      account,
      applicantData,
      doAddBundleProduct,
      finInfo,
      sessionCompletedProducts,
      vaultMember,
    } = this.props;
    const { contKycFlag } = this.state;
    console.log("handleSubmitDetails");
    const activeProduct = getActiveProduct(vaultProductList);
    const isMobile = isScanningAllowed();
    let applicationConfirmRequest = {
      applicationId: activeProduct.applicationId,
      isDesktop: !isMobile && isNewMember(applicantData),
    };
    if (fillApplicationSlot && fillApplicationSlot.applicantId) {
      applicationConfirmRequest = {
        applicationId: fillApplicationSlot.applicationId,
        applicantId: fillApplicationSlot.applicantId,
        isThirdPartyAccount: activeProduct.isThirdPartyAccount,
        thirdPartyName: activeProduct.thirdPartyName,
        thirdPartyRelationship: activeProduct.thirdPartyRelationship,
        isDesktop: !isMobile && isNewMember(applicantData),
      };
    } else if (inSessionJointApplicant) {
      applicationConfirmRequest = {
        applicationId: activeProduct.applicationId,
        applicantId: inSessionJointApplicant.applicantId,
        isThirdPartyAccount: activeProduct.isThirdPartyAccount,
        thirdPartyName: activeProduct.thirdPartyName,
        thirdPartyRelationship: activeProduct.thirdPartyRelationship,
        isDesktop: !isMobile && isNewMember(applicantData),
      };
    } else {
      applicationConfirmRequest = {
        ...applicationConfirmRequest,
        applicantId: finInfo.jointMembership ? activeProduct.applicantId : null,
        isThirdPartyAccount: activeProduct.isThirdPartyAccount,
        thirdPartyName: activeProduct.thirdPartyName,
        thirdPartyRelationship: activeProduct.thirdPartyRelationship,
        isDesktop: !isMobile && isNewMember(applicantData),
      };
    }

    const { continuehandler } = this.props;
    if (inSessionJointApplicant) {
      const updateRequest = this.defaultAccountDetails(account, activeProduct);
      updateRequest.waitMessage = MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT;
      doUpdateApplicant(
        updateRequest,
        inSessionJointApplicant.applicantId,
        () => {
          doFillApplicationSlot(
            {
              applicantId: inSessionJointApplicant.applicantId,
              slotId: inSessionJointApplicant.id,
              applicationId: activeProduct.applicationId,
              newCustomer: applicantData?.acquireType !== "search",
              instntTxnId:
                inSessionJointApplicant.instntTxnId ||
                activeProduct.kycInsntTxnId,
            },
            () => {
              if (inSessionJointApplicant.enableDebitCard) {
                doAddBundleProduct(
                  activeProduct.applicationId,
                  activeProduct.bundleProducts[0].bundleProduct.id,
                  activeProduct.doximProductId,
                  inSessionJointApplicant.applicantId,
                  (res2) => {
                    if (res2 && res2.status === HTTP_STATUS.OK) {
                      const debitProduct =
                        res2.data.doximProducts[
                          res2.data.doximProducts.findLastIndex(
                            (item) =>
                              item.type ===
                              STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM
                          )
                        ];
                      inSessionJointApplicant.debitProductId = debitProduct.id;
                    }
                  }
                );
              }
              if (
                !isNewMember(applicantData) &&
                Array.isArray(finInfo.kycAllowedEdit)
              ) {
                applicationConfirmRequest.doKyc = true;
              }
              this.confirmJointInSession(
                applicationConfirmRequest,
                inSessionJointApplicant
              );
            }
          );
        }
      );
    } else if (activeProduct.isJointProduct) {
      this.confirmJointApplication(applicationConfirmRequest, activeProduct);
    } else {
      console.log("here");
      doGetApplicationProperty(
        {
          applicationId: activeProduct.applicationId,
          wait: MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT,
        },
        (appPropertyRes) => {
          if (
            appPropertyRes.appliedAt ||
            (appPropertyRes.slots && appPropertyRes.slots[0].appliedAt)
          ) {
            continuehandler({}, AppConstants.APPLICATIONSTEP.CONFIRM);
          } else {
            const jointAccount = appPropertyRes.slots.find(
              (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
            );
            if (!jointAccount && finInfo.singleSignerIntendedUse) {
              this.updateSingleApplication(activeProduct);
            }
            if (isNewMember(applicantData)) {
              const updateRequest = this.defaultAccountDetails(
                account,
                activeProduct
              );
              updateRequest.waitMessage =
                MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT;
              doUpdateApplicant(
                updateRequest,
                activeProduct.applicantId,
                () => {
                  this.confirmApplication(
                    applicationConfirmRequest,
                    jointAccount,
                    activeProduct
                  );
                }
              );
            } else {
              if (applicantData.regulatory.consentToCreditCheck) {
                if (contKycFlag) {
                  const updateRequest = {
                    regulatory: getContinousKycRegulatorUpdates(
                      {},
                      vaultMember,
                      applicantData,
                      finInfo
                    ),
                  };
                  updateRequest.waitMessage =
                    MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT;
                  doUpdateApplicant(
                    updateRequest,
                    activeProduct.applicantId,
                    () => {
                      this.confirmApplication(
                        applicationConfirmRequest,
                        jointAccount,
                        activeProduct
                      );
                    }
                  );
                } else {
                  this.confirmApplication(
                    applicationConfirmRequest,
                    jointAccount,
                    activeProduct
                  );
                }
              } else {
                const updateRequest = {
                  regulatory: {
                    consentToCreditCheck: true,
                  },
                  creditBanking: {
                    creditCheckConsentDate: format(new Date(), "yyyy-MM-dd"),
                    creditCheckConsentType: finInfo.creditCheckConsentType,
                  },
                };
                if (contKycFlag) {
                  updateRequest.regulatory = getContinousKycRegulatorUpdates(
                    updateRequest.regulatory,
                    vaultMember,
                    applicantData,
                    finInfo
                  );
                }
                updateRequest.waitMessage =
                  MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT;
                doUpdateApplicant(
                  updateRequest,
                  activeProduct.applicantId,
                  () => {
                    this.confirmApplication(
                      applicationConfirmRequest,
                      jointAccount,
                      activeProduct
                    );
                  }
                );
              }
            }
          }
        }
      );
    }
  };

  confirmJointInSession = (
    applicationConfirmRequest,
    inSessionJointApplicant
  ) => {
    const { doUpdateApplicant, applicantData, finInfo } = this.props;
    const { contKycFlag } = this.state;
    if (applicantData.regulatory.consentToCreditCheck) {
      if (contKycFlag) {
        const updateRequest = {
          regulatory: getContinousKycRegulatorUpdates(
            {},
            inSessionJointApplicant.vault,
            applicantData,
            finInfo
          ),
        };
        updateRequest.waitMessage = MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT;
        doUpdateApplicant(
          updateRequest,
          inSessionJointApplicant.applicantId,
          () => {
            this.confirmJointInSessionApplication(applicationConfirmRequest);
          }
        );
      } else {
        this.confirmJointInSessionApplication(applicationConfirmRequest);
      }
    } else {
      const updateRequest = {
        regulatory: {
          consentToCreditCheck: true,
        },
        creditBanking: {
          creditCheckConsentDate: format(new Date(), "yyyy-MM-dd"),
          creditCheckConsentType: finInfo.creditCheckConsentType,
        },
      };
      if (contKycFlag) {
        updateRequest.regulatory = getContinousKycRegulatorUpdates(
          updateRequest.regulatory,
          inSessionJointApplicant.vault,
          applicantData,
          finInfo
        );
      }
      updateRequest.waitMessage = MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT;
      doUpdateApplicant(
        updateRequest,
        inSessionJointApplicant.applicantId,
        () => {
          this.confirmJointInSessionApplication(applicationConfirmRequest);
        }
      );
    }
  };

  confirmJointInSessionApplication = (applicationConfirmRequest) => {
    const {
      doConfirmApp,
      doSetInSessionJointApplicant,
      history,
      finInfo,
      applicantData,
    } = this.props;
    let confirmRequest = {
      ...applicationConfirmRequest,
      doKyc: false,
    };
    if (!isNewMember(applicantData) && Array.isArray(finInfo.kycAllowedEdit)) {
      confirmRequest = {
        ...applicationConfirmRequest,
        doKyc: true,
      };
    }
    doConfirmApp(
      {
        ...confirmRequest,
        waitMessage: MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT,
      },
      (res) => {
        if (res.status === HTTP_STATUS.OK) {
          doSetInSessionJointApplicant(null);
          history.push(ROUTES.JOINT_APPLICANT_STATUS);
        }
      }
    );
  };

  confirmJointApplication = (applicationConfirmRequest, activeProduct) => {
    const {
      doConfirmApp,
      applicantData,
      doUpdateApplicant,
      account,
      vaultMember,
      finInfo,
      doUpdateProductToVault,
      products,
    } = this.props;
    console.log(
      "confirmJointApplication",
      applicationConfirmRequest,
      activeProduct
    );
    if (isNewMember(applicantData)) {
      const updateRequest = this.defaultAccountDetails(account, activeProduct);
      updateRequest.waitMessage = MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT;
      let { isDesktop } = activeProduct;
      if (isNewMember(applicantData) && !isScanningAllowed()) {
        isDesktop = true;
      }
      doUpdateApplicant(updateRequest, activeProduct.applicantId, () => {
        doConfirmApp(
          {
            ...applicationConfirmRequest,
            isDesktop,
            waitMessage: MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT,
          },
          (res) => {
            if (res.status === HTTP_STATUS.OK) {
              if (activeProduct.isJointProduct) {
                const updatedProductList = products.slice(1, products.length);
                doUpdateProductToVault(updatedProductList, () => {});
              }
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_JOINT_TITLE,
                  description: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_JOINT_MSG,
                },
              });
            }
          }
        );
      });
    } else {
      const updateRequest = {
        regulatory: getContinousKycRegulatorUpdates(
          {},
          vaultMember,
          applicantData,
          finInfo
        ),
      };
      updateRequest.waitMessage = MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT;
      doUpdateApplicant(updateRequest, activeProduct.applicantId, () => {
        let confirmRequest = applicationConfirmRequest;
        if (
          !isNewMember(applicantData) &&
          Array.isArray(finInfo.kycAllowedEdit)
        ) {
          confirmRequest = {
            ...applicationConfirmRequest,
            doKyc: true,
          };
        }
        doConfirmApp(
          {
            ...confirmRequest,
            waitMessage: MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT,
          },
          (res) => {
            if (res.status === HTTP_STATUS.OK) {
              if (activeProduct.isJointProduct) {
                const updatedProductList = products.slice(1, products.length);
                doUpdateProductToVault(updatedProductList, () => {});
              }
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_JOINT_TITLE,
                  description: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_JOINT_MSG,
                },
              });
            }
          }
        );
      });
    }
  };

  defaultAccountDetails = (account, activeProduct) => {
    const { finInfo } = this.props;
    const marketingData = this.getMarketingData(account?.consent?.marketing);
    console.log("defaultAccountDetails", account, activeProduct);
    const updateRequest = {
      member: {
        ...finInfo.defaults?.applicant?.member,
      },
      employment: {
        ...finInfo.defaults?.applicant?.employment,
      },
      address: {
        ...finInfo.defaults?.applicant?.address,
      },
      contact: {
        ...finInfo.defaults?.applicant?.contact,
      },
      regulatory: {
        ...finInfo.defaults?.applicant?.regulatory,
        ...marketingData,
        consentCommunicationDate: finInfo.defaults?.applicant?.regulatory
          ?.consentCommunicationDate
          ? format(new Date(), "yyyy-MM-dd")
          : "",
        consentSinDate: finInfo.defaults?.applicant?.regulatory?.consentSinDate
          ? format(new Date(), "yyyy-MM-dd")
          : "",
        consentToCreditCheck: true,
      },
      creditBanking: {
        ...finInfo?.applicant?.regulatory,
        creditCheckConsentDate: format(new Date(), "yyyy-MM-dd"),
        creditCheckConsentType: finInfo.creditCheckConsentType,
      },
    };
    const natureOfBusiness = getNatureOfBusinessForJoint(
      activeProduct,
      finInfo
    );
    if (finInfo.natureOfBusinessOther) {
      updateRequest.member[finInfo.natureOfBusinessOther] =
        natureOfBusiness.natureOfBusiness;
    } else {
      updateRequest.employment.natureOfBusiness =
        natureOfBusiness.natureOfBusiness;
    }
    return updateRequest;
  };

  confirmApplication = (
    applicationConfirmRequest,
    jointAccount,
    activeProduct
  ) => {
    const {
      doConfirmApp,
      applicantData,
      doGetApplicationProperty,
      doGetApplicationList,
      continuehandler,
      vaultMember,
      sessionCompletedProducts,
      finInfo,
      inSessionJointApplicant,
      doUpdateVaultMember,
    } = this.props;
    console.log(
      "confirmApplication",
      applicationConfirmRequest,
      jointAccount,
      activeProduct,
      sessionCompletedProducts
    );
    let request = applicationConfirmRequest;
    if (
      !isNewMember(applicantData) &&
      !inSessionJointApplicant &&
      Array.isArray(finInfo.kycAllowedEdit)
    ) {
      request = {
        ...applicationConfirmRequest,
        doKyc: true,
      };
    }
    doConfirmApp(
      {
        ...request,
        ...vaultMember,
        waitMessage: MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT,
      },
      (res) => {
        if (res.status === HTTP_STATUS.OK) {
          doUpdateVaultMember({ ...vaultMember, old: vaultMember });
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
              wait: MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT,
            },
            (applicationPropRes) => {
              console.log("applicationPropRes", applicationPropRes);
              if (applicationPropRes.id) {
                doGetApplicationList((listResponse) => {
                  console.log("listResponse", listResponse);
                  if (listResponse.status === HTTP_STATUS.OK) {
                    if (applicationPropRes.inReview) {
                      this.setState({
                        showModal: true,
                        modalType: AppConstants.MODALTYPE.SUCCESS,
                        modal: {
                          title: MODALMESSAGES.ON_REVIEW.TITLE,
                          description: MODALMESSAGES.ON_REVIEW.MSG,
                        },
                      });
                    } else if (applicationPropRes.uploadedAt) {
                      doConfirmSuccess({ successConfirmed: true });
                      this.setState({
                        showModal: true,
                        modalType: AppConstants.MODALTYPE.SUCCESS,
                        modal: {
                          title: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_TITLE,
                          description: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_MSG,
                        },
                      });
                    } else {
                      continuehandler({}, AppConstants.APPLICATIONSTEP.CONFIRM);
                    }
                  }
                });
              }
            }
          );
        }
      }
    );
  };

  updateSingleApplication = (activeProduct) => {
    const { doGetContract, doUpdateContract, finInfo } = this.props;
    console.log("updateSingleApplication ", finInfo.singleSignerIntendedUse);
    doGetContract(
      activeProduct.applicationId,
      activeProduct.type,
      activeProduct.doximProductId,
      MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT,
      (res) => {
        console.log("contract", res);
        if (res) {
          if (activeProduct.type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT) {
            doUpdateContract(
              activeProduct.applicationId,
              activeProduct.type,
              activeProduct.doximProductId,
              {
                intendedUse: finInfo.singleSignerIntendedUse,
                demandProductUDFDetails: {
                  ...res.data.udfList,
                  udf5: "",
                },
                waitMessage: MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT,
              },
              () => {}
            );
          } else {
            doUpdateContract(
              activeProduct.applicationId,
              activeProduct.type,
              activeProduct.doximProductId,
              {
                intendedUse: finInfo.singleSignerIntendedUse,
                udfList: {
                  ...res.data.udfList,
                  udf5: "",
                },
                waitMessage: MODALMESSAGES.CONFIRM_PAGE.PROCESS_WAIT,
              },
              () => {}
            );
          }
        }
      }
    );
  };

  /**
   *
   * @param {string} redirectToPage
   * handleEditPage makes URL navigation
   * using key as value
   */

  handleEditPage = (redirectToPage) => {
    const { history, doToggleEdit } = this.props;
    doToggleEdit(true);
    history.push(redirectToPage);
  };

  handlePopupModalBtnClick = () => {
    const {
      continuehandler,
      products,
      getActiveProduct,
      doUpdateProductToVault,
      history,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    continuehandler({}, AppConstants.APPLICATIONSTEP.CONFIRM);
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.CONFIRM);
  };

  render() {
    const {
      modalType,
      modal,
      showModal,
      alreadyApplied,
      productIntendedUseList,
      showKycMessage,
      kycList,
      contKycFlag,
    } = this.state;
    const {
      gettingStartedBranchData,
      incomeSourcesList,
      statusList,
      provinceList,
      accountPurposeList,
      indicateWhoList,
      getDataFromServer,
      steps,
      activeStepID,
      doGetProductInformation,
      getActiveProduct,
      doGetBeneficiaryData,
      doGetSpousalInformation,
      doGetApplicationProperty,
      doGetApplicantType,
      inSessionJointApplicant,
      doGetFinancialAnalysisFinancialList,
      products,
      applicationCompleteList,
      doGetDocumentList,
      doGetLoanLoanSecurity,
      config,
      doGetTermProduct,
      doGetCountryList,
    } = this.props;

    let activeProduct = getActiveProduct(products);
    if (inSessionJointApplicant) {
      activeProduct = {
        ...activeProduct,
        prospect: inSessionJointApplicant.prospect,
      };
    }

    return (
      <>
        <ConfirmAccountCreate
          key={1}
          handleEditPage={this.handleEditPage}
          type={modalType}
          modal={modal}
          showModal={showModal}
          toggleModal={this.toggleModal}
          handleSubmitDetails={this.handleSubmitDetails}
          gettingStartedBranchData={gettingStartedBranchData}
          incomeSourcesList={incomeSourcesList}
          statusList={statusList}
          provinceList={provinceList}
          accountPurposeList={accountPurposeList}
          indicateWhoList={indicateWhoList}
          handlePopupModalBtnClick={this.handlePopupModalBtnClick}
          handleBack={this.handleBack}
          getDataFromServer={(step, callback) =>
            getDataFromServer(step, callback)
          }
          steps={steps}
          activeStepID={activeStepID}
          doGetProductInformation={doGetProductInformation}
          getActiveProduct={getActiveProduct}
          doGetBeneficiaryData={doGetBeneficiaryData}
          doGetSpousalInformation={doGetSpousalInformation}
          doGetApplicationProperty={doGetApplicationProperty}
          doGetApplicantType={doGetApplicantType}
          inSessionJointApplicant={inSessionJointApplicant}
          doGetFinancialAnalysisFinancialList={
            doGetFinancialAnalysisFinancialList
          }
          activeProduct={activeProduct}
          applicationCompleteList={applicationCompleteList}
          doGetDocumentList={doGetDocumentList}
          doGetLoanLoanSecurity={doGetLoanLoanSecurity}
          alreadyApplied={alreadyApplied}
          config={config}
          doGetTermProduct={doGetTermProduct}
          intendedUseList={productIntendedUseList}
          doGetCountryList={doGetCountryList}
          contKycFlag={contKycFlag}
        />
        {showKycMessage && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            title={Messages.KYC.TITLE}
            description={kycList}
            description2={Messages.KYC.INFO1}
            description3={Messages.KYC.INFO2}
            showModal={showKycMessage}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  gettingStartedBranchData: state.GettingStartedBranchReducer.jsonData,
  incomeSourcesList: state.EmploymentAndIncomeReducer.incomeSourcesList,
  statusList: state.EmploymentAndIncomeReducer.statusList,
  indicateWhoList: state.ComplianceReducer.indicateWhoList,
  accountPurposeList: state.ComplianceReducer.accountPurposeList,
  provinceList: state.AboutYouReducer.provinceList,
  account: state.AboutYouReducer.account,
  // confirmApplicationState represents form data confirm application page actions
  confirmApplicationState: state.ConfirmApplicationReducer,
  vaultProductList: state.VaultReducer.vaultProductList,
  fillApplicationSlot:
    state.JoinApplicantReducer.fillApplicationSlot?.requestData,
  templates: state.ApplicationReducer.templates,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  config: state.LoanDetailsReducer.config,
  employerPositionCodes: state.ApplicationReducer.employerPositionCodes,
  finInfo: state.MarketplaceReducer.finInfo,
  applicantData: state.ApplicationReducer.response,
  intendedUseList: state.AccountInformationReducer.intendedUseList,
  bundleProductRelation: state.GetBundleProductRelationReducer.data,
  processTemplate: state.ApplicationReducer.processTemplate,
  sessionCompletedProducts: PropTypes.arrayOf(PropTypes.object),
  vaultMember: state.ApplicationReducer.vaultMember,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // doConfirmApplication takes applicationId
    // and calls confirm account create API
    doConfirmApp: (confirmRequest, callback) =>
      dispatch(doConfirmApplication(confirmRequest, callback)),
    doConfirmSuccess: (payLoad, callback) =>
      dispatch(doConfirmSuccess(payLoad, callback)),
    dofetchGettingStartedBranchs: () => dispatch(fetchGettingStartedBranch()),
    doGetProvinceData: () => dispatch(doGetProvinceList()),
    doGetIncomeSourcesList: () => dispatch(getIncomeSourcesList()),
    doGetStatusList: () => dispatch(getStatusList()),
    doGetEmployerPositionCodes: () => dispatch(getEmployerPositionCodes()),
    doGetAccountPurposeList: () => dispatch(getAccountPurposeList()),
    doGetIndicateWhoList: () => dispatch(getIndicateWhoList()),
    doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
    doGetProductInformation: (
      applicationId,
      productType,
      doximProductId,
      callback
    ) =>
      dispatch(
        getProductInformation(
          applicationId,
          productType,
          doximProductId,
          callback
        )
      ),

    doGetBeneficiaryData: (applicationId, reporductNo, callback) =>
      dispatch(getBeneficiaries(applicationId, reporductNo, callback)),
    doGetSpousalInformation: (
      applicationId,
      regidteredcontractproduct,
      reporductNo,
      callback
    ) =>
      dispatch(
        getSpousalInfo(
          applicationId,
          regidteredcontractproduct,
          reporductNo,
          callback
        )
      ),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doGetApplicantType: (callback) => dispatch(getApplicantType(callback)),
    doGetApplicationList: (callback) => dispatch(getApplicationList(callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doGetFinancialAnalysisFinancialList: (request, callback) =>
      dispatch(getFinancialAnalysisFinancialList(request, callback)),
    doGetDocumentList: (applicationId, callback) =>
      dispatch(getDocumentList(applicationId, callback)),
    doConsents: (consentDetails, callback) =>
      dispatch(doConsent(consentDetails, callback)),
    doGetLoanLoanSecurity: (applicationId, callback) =>
      dispatch(getLoanLoanSecurity(applicationId, callback)),
    doGetTermProduct: (request, callback) =>
      dispatch(getTermProduct(request, callback)),
    doGetAccountDetails: (callback) => dispatch(getAccount(callback)),
    doGetContract: (
      applicationId,
      applicationType,
      productId,
      waitMessage,
      callback
    ) =>
      dispatch(
        getContract(
          applicationId,
          applicationType,
          productId,
          waitMessage,
          callback
        )
      ),
    doUpdateContract: (
      applicationId,
      applicationType,
      productId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateContract(
          applicationId,
          applicationType,
          productId,
          payLoad,
          callback
        )
      ),
    doGetIntendedUseList: () => dispatch(getIntendedUseList()),
    doAddBundleProduct: (
      applicationId,
      bundleProductId,
      demandProductId,
      applicantId,
      callback
    ) =>
      dispatch(
        addBundleProduct(
          applicationId,
          bundleProductId,
          demandProductId,
          applicantId,
          callback
        )
      ),
    doGetBundleProductRelation: (activeProduct, callback) =>
      dispatch(getBundleProductRelation(activeProduct, callback)),
    doGetCountryList: () => dispatch(getCountryList()),
    doDeleteApplicationSlot: (request, callback) =>
      dispatch(deleteApplicationSlot(request, callback)),
    doUpdateVaultMember: (member, callback) =>
      dispatch(updateVaultMember(member, callback)),
  };
};
ConfirmAccountCreateContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any), // roter history object
  doConfirmApp: PropTypes.func,
  gettingStartedBranchData: PropTypes.arrayOf(PropTypes.any),
  incomeSourcesList: PropTypes.arrayOf(PropTypes.any),
  statusList: PropTypes.arrayOf(PropTypes.any),
  employerPositionCodes: PropTypes.arrayOf(PropTypes.any),
  provinceList: PropTypes.arrayOf(PropTypes.any),
  account: PropTypes.objectOf(PropTypes.any),
  accountPurposeList: PropTypes.arrayOf(PropTypes.any),
  indicateWhoList: PropTypes.arrayOf(PropTypes.any),
  dofetchGettingStartedBranchs: PropTypes.func,
  doGetProvinceData: PropTypes.func,
  doGetIncomeSourcesList: PropTypes.func,
  doGetStatusList: PropTypes.func,
  doGetAccountPurposeList: PropTypes.func,
  doGetIndicateWhoList: PropTypes.func,
  doToggleEdit: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  vaultProductList: PropTypes.arrayOf(PropTypes.any),
  doGetProductInformation: PropTypes.func.isRequired,
  doGetSpousalInformation: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  doGetBeneficiaryData: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  doGetApplicationList: PropTypes.func.isRequired,
  inSessionJointApplicant: PropTypes.objectOf(PropTypes.any),
  finInfo: PropTypes.objectOf(PropTypes.any),
  doGetAccountDetails: PropTypes.func.isRequired,
  doGetContract: PropTypes.func.isRequired,
  doUpdateContract: PropTypes.func.isRequired,
  intendedUseList: PropTypes.arrayOf(PropTypes.object),
  doAddBundleProduct: PropTypes.func.isRequired,
  applicantData: PropTypes.objectOf(PropTypes.any),
  doUpdateApplicant: PropTypes.func.isRequired,
  sessionCompletedProducts: PropTypes.arrayOf(PropTypes.object),
};
ConfirmAccountCreateContainer.defaultProps = {
  history: {},
  doConfirmApp: () => {},
  gettingStartedBranchData: [],
  incomeSourcesList: [],
  statusList: [],
  provinceList: [],
  accountPurposeList: [],
  indicateWhoList: [],
  dofetchGettingStartedBranchs: () => {},
  doGetProvinceData: () => {},
  doGetIncomeSourcesList: () => {},
  doGetStatusList: () => {},
  doGetAccountPurposeList: () => {},
  doGetIndicateWhoList: () => {},
  steps: [],
  activeStepID: -1,
  vaultProductList: [],
  employerPositionCodes: [],
  inSessionJointApplicant: null,
  finInfo: {},
  account: {},
  intendedUseList: [],
  applicantData: null,
  sessionCompletedProducts: [],
};

const ConfirmAccountWrapper = withApplicantHOC(ConfirmAccountCreateContainer);

const wrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmAccountWrapper);

export default withVaultHOC(wrapper);
