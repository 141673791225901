import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import base64url from "base64url";

import doRecoverPassword from "Redux/Actions/RecoverPasswordAction";
import doLogout from "Redux/Actions/LogoutAction";

import AppConstants from "Constants/AppConstants";
import MODALMESSAGES from "Constants/Messages";
import * as ROUTES from "Constants/Routes";

// import component
import { HTTP_STATUS } from "Communication/Constants";
import SetPassword from "./SetPassword";

// saga code
import { doGetPasswordCriteria } from "./Actions/SetPasswordAction";
import STRINGS from "../../Constants/Strings";

class RecoverPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
    };
  }

  componentDidMount = () => {
    const { getPasswordCriteria } = this.props;
    getPasswordCriteria();
  };

  componentDidUpdate = (prevProps) => {
    const { recoverPasswordStatus } = this.props;
    if (recoverPasswordStatus !== prevProps.recoverPasswordStatus) {
      if (recoverPasswordStatus.success.status === HTTP_STATUS.OK) {
        this.setModalState({
          showModal: true,
          modalType: AppConstants.MODALTYPE.SUCCESS,
          modal: {
            title: MODALMESSAGES.RECOVERPASSWORD.SUCCESSMSG,
            description: "",
          },
        });
      } else if (recoverPasswordStatus.success !== "") {
        this.setModalState({
          showModal: true,
          modalType: AppConstants.MODALTYPE.FAILURE,
          modal: {
            title: MODALMESSAGES.RECOVERPASSWORD.ERRORMSG,
            description: "",
          },
        });
      }
    }
  };

  componentWillUnmount() {
    const { recoverPasswordStatus, history } = this.props;
    if (recoverPasswordStatus.success === "") {
      if (history.location.pathname.includes(ROUTES.RECOVER_PASSWORD)) {
        history.push(ROUTES.SIGN_IN);
      }
    }
  }

  setModalState = (modal) => {
    this.setState(modal);
  };

  handleSetPasswordRequest = (password) => {
    const { match, doRecoverPswd, getPasswordCriteria } = this.props;
    const cread = base64url.decode(match.params.id);
    const credentials = cread.split(":");
    console.log("credentials", credentials);
    if (credentials.length > 3) {
      sessionStorage.setItem(
        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID,
        credentials[2]
      );
      sessionStorage.setItem(
        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID,
        credentials[3]
      );
    }
    doRecoverPswd({
      otpId: credentials[0],
      otp: credentials[1],
      newPassword: password,
    });

    getPasswordCriteria();
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  doLogout = () => {
    const { recoverPasswordStatus } = this.props;
    if (
      recoverPasswordStatus.success &&
      recoverPasswordStatus.success.status === HTTP_STATUS.OK
    ) {
      const jointApplicationId = sessionStorage.getItem(
        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
      );
      const jointApplicantSlot = sessionStorage.getItem(
        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
      );
      const { doLogOut, history } = this.props;
      doLogOut(
        (res) => {
          if (res.status === 200) {
            history.push(ROUTES.SIGN_IN);
          }
        },
        { jointApplicationId, jointApplicantSlot }
      );
    }
  };

  render() {
    const { history, passwdCriteria } = this.props;
    const { modalType, modal, showModal } = this.state;
    return (
      <div className="content-wrapper">
        <SetPassword
          handleSetPasswordRequest={this.handleSetPasswordRequest}
          history={history}
          type={modalType}
          modal={modal}
          showModal={showModal}
          toggleModal={this.toggleModal}
          passwdCriteria={passwdCriteria.passwordCriteria}
          nextComponent={ROUTES.SIGN_IN}
          doLogout={this.doLogout}
        />
      </div>
    );
  }
}

RecoverPasswordContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  passwdCriteria: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  recoverPasswordStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  doRecoverPswd: PropTypes.func.isRequired,
  getPasswordCriteria: PropTypes.func.isRequired,
  doLogOut: PropTypes.objectOf(PropTypes.any).isRequired,
};

RecoverPasswordContainer.defaultProps = {
  history: {},
  passwdCriteria: {},
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPasswordCriteria: () => dispatch(doGetPasswordCriteria()),
    doRecoverPswd: (userDetails) => dispatch(doRecoverPassword(userDetails)),
    doLogOut: (callback, data) => dispatch(doLogout(callback, data)),
  };
};
const mapStateToProps = (state) => ({
  recoverPasswordStatus: state.RecoverPasswordReducer,
  passwdCriteria: state.GetPasswordCriteriaReducer.success,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoverPasswordContainer);
