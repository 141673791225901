import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";
import {
  HTTP_STATUS,
  EXISTING_OR_SAME_PRODUCT_MESSAGE,
  S_APPLICANT_ID,
} from "Communication/Constants";
import {
  getProductList,
  updateVaultProductList,
  getProductFromVault,
  updateProductToVault,
  updateActiveTab,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import {
  getApplicant,
  getApplicationList,
} from "Redux/Actions/ApplicationAction";
import {
  storeFundProduct,
  addFundAmount,
  addFundMethod,
} from "Components/FundingAmount/Action/FundAction";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import VaultDashboard from "./VaultDashboard";
import {
  getApplicationProperty,
  setInSessionJointApplicant,
} from "../JointApplicant/Actions/JointApplicantAction";
import { getFIInfo } from "../MarketPlace/Action/MarketPlaceAction";
import {
  getVaultMember,
  updateVaultMember,
} from "../AboutYou/Actions/AboutYouActions";
import { checkVaultMemberData, isNewMember } from "../../Utils/LogicUtilities";
import { getCompleteApplicant } from "../../Redux/Actions/ApplicationAction";
import { searchMember } from "../../Redux/Actions/ApplicantAction";
import doLogout from "../../Redux/Actions/LogoutAction";
import * as ROUTES from "../../Constants/Routes";

class VaultDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      showModal: false,
      showModalEmail: false,
      modal: {
        title: "",
        description: "",
      },
      modalEmail: {
        title: "",
        description: "",
      },
      slots: [],
    };
  }

  componentDidMount() {
    const {
      doGetProductFromVault,
      doGetProductList,
      doUpdateActiveTab,
      doGetApplicationList,
      doGetApplicantListAction,
      doGetApplicant,
      doUpdateProductToVault,
      doStoreFundProduct,
      prepareVaultProductList,
      doAddFundMethod,
      doAddFundAmount,
      doSetInSessionJointApplicant,
      doGetFinInfo,
      doGetApplicationProperty,
      doGetCompleteApplicant,
      finInfo,
      doSearchMember,
      doGetVaultMember,
      doUpdateVaultMember,
      doLogoutAction,
    } = this.props;
    console.log("VaultDashboardContainer");
    doGetFinInfo();
    doStoreFundProduct({});
    doAddFundMethod("");
    doAddFundAmount("");
    doSetInSessionJointApplicant(null);
    const title = sessionStorage.getItem(EXISTING_OR_SAME_PRODUCT_MESSAGE);
    const centralMessage = sessionStorage.getItem(
      AppConstants.SESSION.CENTRAL_MESSAGE
    );
    if (centralMessage) {
      sessionStorage.removeItem(AppConstants.SESSION.CENTRAL_MESSAGE);
      sessionStorage.removeItem(EXISTING_OR_SAME_PRODUCT_MESSAGE);
    } else if (title) {
      sessionStorage.removeItem(EXISTING_OR_SAME_PRODUCT_MESSAGE);
      const modal = {
        title,
        description: "",
      };
      this.setState({ showModal: true, modal });
    }

    const applicantId = sessionStorage.getItem(S_APPLICANT_ID);
    if (applicantId === null) {
      doGetApplicantListAction((res) => {
        if (res.status === HTTP_STATUS.OK) {
          if (res.data.length === 0) {
            this.setState({
              userName: "",
            });
          } else {
            // get first applicantID from the list
            let selfApplication = res.data.find(
              (app) => app.relationship === AppConstants.RELATIONSHIP.SELF
            );
            let getFirstApplicantID = selfApplication.id;
            if (finInfo.jointMembership) {
              selfApplication = res.data.filter(
                (app) => app.relationship === AppConstants.RELATIONSHIP.SELF
              );
              getFirstApplicantID =
                selfApplication[selfApplication.length - 1]?.id;
            }
            sessionStorage.setItem(S_APPLICANT_ID, getFirstApplicantID);
            doGetCompleteApplicant(getFirstApplicantID, (responseResult) => {
              if (responseResult.status === HTTP_STATUS.OK) {
                if (!responseResult.data.member.email) {
                  const modal = {
                    description:
                      "Please reach out to the branch and update your email address",
                  };
                  doLogoutAction();
                  this.setState({
                    showModalEmail: true,
                    modalEmail: modal,
                    showModal: false,
                  });
                }
                if (finInfo.jointMembership) {
                  doSearchMember(
                    {
                      cifId: responseResult.data.cifId,
                    },
                    (res) => {
                      console.log(res);
                    }
                  );
                }
                const userName = responseResult.data.member.name;
                checkVaultMemberData(
                  responseResult.data,
                  doGetVaultMember,
                  doUpdateVaultMember,
                  finInfo
                );
                this.setState({ userName });
              }
            });
          }
        }
      });
    } else {
      doGetCompleteApplicant(applicantId, (responseResult) => {
        if (responseResult.status === HTTP_STATUS.OK) {
          if (finInfo?.jointMembership) {
            doSearchMember(
              {
                cifId: responseResult.data.cifId,
              },
              (res) => {
                console.log(res);
              }
            );
          }
          checkVaultMemberData(
            responseResult.data,
            doGetVaultMember,
            doUpdateVaultMember,
            finInfo
          );
          const userName = responseResult.data.member.name;
          this.setState({ userName });
          if (centralMessage) {
            const modal = {
              title: centralMessage
                .split(AppConstants.TEMPLATE.NAME)
                .join(userName),
              description: "",
            };
            this.setState({ showModal: true, modal });
          }
        }
      });
    }

    doGetProductList((response) => {
      if (response && response.status === HTTP_STATUS.OK) {
        doGetProductFromVault((res) => {
          if (res && res.status === HTTP_STATUS.OK) {
            if (res.data && res.data.length !== 0) {
              const vaultProductList = res.data;
              vaultProductList[0].active = false;
              delete vaultProductList[0].kycStatus;
              delete vaultProductList[0].kycQrCode;
              delete vaultProductList[0].kycInsntTxnId;
              if (
                vaultProductList[0].applicationId &&
                !vaultProductList[0].isJointProduct
              ) {
                doGetApplicationProperty(
                  { applicationId: vaultProductList[0].applicationId },
                  (res) => {
                    this.setState({
                      slots: {
                        data: res.slots,
                        applicationId: vaultProductList[0].applicationId,
                      },
                    });
                  }
                );
              } else {
                this.setState({
                  slots: [],
                });
              }
              console.log("update vault 5");
              doUpdateProductToVault(vaultProductList, () => {});
            }
            doGetApplicationList((result) => {
              if (result && result.status === HTTP_STATUS.OK) {
                if (
                  result.data.length === 0 &&
                  (res.data === "" || res.data.length === 0)
                ) {
                  doUpdateActiveTab(1);
                } else if (window.location.search.endsWith("marketplace")) {
                  doUpdateActiveTab(1);
                } else {
                  doUpdateActiveTab(0);
                }
              }
            });
          }
        });
      }
    });
  }

  handleMarketplaceClicked = () => {
    const { doGetProductList } = this.props;
    doGetProductList(() => {});
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  toggleModalEmail = () => {
    const { showModalEmail } = this.state;
    const { history } = this.props;
    if (showModalEmail) {
      history.push(ROUTES.SIGN_IN);
    }
    this.setState({
      showModalEmail: !showModalEmail,
    });
  };

  render() {
    const {
      doUpdateVaultProductList,
      doGetProductFromVault,
      proceedWithApplication,
      deleteApplication,
      applicationCompleteList,
      productList,
      categoryList,
      doUpdateProductToVault,
      activeTab,
      doUpdateActiveTab,
      doGetApplicationList,
      doGetProductList,
      doSetInSessionJointApplicant,
      applicationList,
    } = this.props;

    const {
      showModal,
      showModalEmail,
      modal,
      modalEmail,
      userName,
      slots,
    } = this.state;
    return (
      <>
        {showModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            title={modal.title}
            toggleModal={this.toggleModal}
            description={modal.description}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showModalEmail && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            title={modalEmail.title}
            toggleModal={this.toggleModalEmail}
            description={modalEmail.description}
            showModal={showModalEmail}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <VaultDashboard
          applicationCompleteList={applicationCompleteList}
          productList={productList}
          categoryList={categoryList}
          handleMarketplaceClicked={this.handleMarketplaceClicked}
          doUpdateVaultProductList={doUpdateVaultProductList}
          deleteApplication={deleteApplication}
          doGetProductFromVault={doGetProductFromVault}
          proceedWithApplication={(callback) =>
            proceedWithApplication(callback)
          }
          doUpdateProductToVault={doUpdateProductToVault}
          activeTab={activeTab}
          doUpdateActiveTab={doUpdateActiveTab}
          doGetApplicationList={doGetApplicationList}
          doGetProductList={doGetProductList}
          userName={userName}
          doSetInSessionJointApplicant={doSetInSessionJointApplicant}
          applicationList={applicationList}
          slots={slots}
        />
      </>
    );
  }
}

VaultDashboardContainer.propTypes = {
  doGetProductList: PropTypes.func,
  doUpdateVaultProductList: PropTypes.func,
  doGetProductFromVault: PropTypes.func,
  deleteApplication: PropTypes.func,
  doGetApplicationList: PropTypes.func,
  applicationCompleteList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  categoryList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  proceedWithApplication: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
  activeTab: PropTypes.number.isRequired,
  doUpdateActiveTab: PropTypes.func,
  doGetApplicantListAction: PropTypes.func,
  doGetApplicant: PropTypes.func,
  doStoreFundProduct: PropTypes.func,
  prepareVaultProductList: PropTypes.func.isRequired,
  doAddFundMethod: PropTypes.func.isRequired,
  doAddFundAmount: PropTypes.func.isRequired,
  doSetInSessionJointApplicant: PropTypes.func.isRequired,
  doGetVaultMember: PropTypes.func.isRequired,
  doUpdateVaultMember: PropTypes.func.isRequired,
};

VaultDashboardContainer.defaultProps = {
  doGetProductList: () => {},
  doUpdateVaultProductList: () => {},
  doGetProductFromVault: () => {},
  deleteApplication: () => {},
  doGetApplicationList: () => {},
  applicationCompleteList: [],
  productList: [],
  categoryList: [],
  proceedWithApplication: () => {},
  doUpdateProductToVault: () => {},
  doUpdateActiveTab: () => {},
  doGetApplicantListAction: () => {},
  doGetApplicant: () => {},
  doStoreFundProduct: () => {},
};

const mapStateToProps = (state) => ({
  productList: state.MarketplaceReducer.productList,
  categoryList: state.MarketplaceReducer.categoryList,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  applicationList: state.ApplicationReducer.applicationList,
  activeTab: state.MarketplaceReducer.activeTab,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doGetProductList: getProductList,
      doUpdateVaultProductList: updateVaultProductList,
      doGetProductFromVault: getProductFromVault,
      doGetApplicationList: getApplicationList,
      doUpdateProductToVault: updateProductToVault,
      doUpdateActiveTab: updateActiveTab,
      doGetApplicant: getApplicant,
      doStoreFundProduct: storeFundProduct,
      doAddFundMethod: addFundMethod,
      doAddFundAmount: addFundAmount,
      doSetInSessionJointApplicant: setInSessionJointApplicant,
      doGetFinInfo: getFIInfo,
      doGetApplicationProperty: getApplicationProperty,
      doGetVaultMember: getVaultMember,
      doUpdateVaultMember: updateVaultMember,
      doGetCompleteApplicant: getCompleteApplicant,
      doSearchMember: searchMember,
      doLogoutAction: doLogout,
    },
    dispatch
  );
};

const VaultDashboardWrapper = withVaultHOC(VaultDashboardContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VaultDashboardWrapper);
