import React, { PureComponent } from "react";
import NumberFormat from "react-number-format";
import STRINGS from "Constants/Strings";
import FIInformation from "Constants/FIInformation";
import MODALMESSAGES from "Constants/Messages";
import AppConstants from "Constants/AppConstants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AuthBody from "../Common/AuthBody/AuthBody";
import { QuestionCircle } from "react-bootstrap-icons";
import PopupModal from "Components/Common/PopupModal/PopupModal";

class Register extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      showHelp: false,
      helpText: "",
    };
  }

  componentDidMount() {
    const { finInfo } = this.props;
    console.log("Component mounted, finInfo found:", finInfo);
  }

  componentDidUpdate(prevProps) {
    const { finInfo } = this.props;
    if (prevProps.finInfo !== finInfo) {
      console.log("finInfo updated:", finInfo);
    }
  }

  handleCentralSignIn = () => {
    window.location.href = process.env.REACT_APP_CENTRAL;
  };

  setModalState = (modal) => {
    this.setState(modal);
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  handleCloseHelp = () => {
    this.setState({
      showHelp: false,
    });
  };

  handleOpenHelp = (text) => {
    console.log("handleOpenHelp");
    this.setState(
      {
        showHelp: true,
        helpText: text,
      },
      () => {
        console.log("showHelp state:", this.state.showHelp); // Verify state update
        console.log("helpText state:", this.state.helpText); // Verify state update
      }
    );
  };

  render() {
    const {
      emailError,
      emailConfirmError,
      email,
      emailConfirm,
      mobileNoError,
      mobileNo,
      termsConditionError,
      isChecked,
      showVaultClicked,
      signinHandler,
      submitData,
      onChangeHandler,
      handleChecked,
      inputFocus,
      bankingType,
      finInfo,
    } = this.props;

    console.log("finInfo in render:", finInfo);
    console.log("bankingType:", bankingType);

    const { showHelp, helpText } = this.state;

    console.log("Rendering modal: ", showHelp);

    console.log("enableCentralSignIn:", finInfo?.enableCentralSignIn);
    console.log("sso:", finInfo?.sso);
    console.log(
      "bankingType check:",
      !bankingType || bankingType !== AppConstants.BANKING_TYPE.BUSINESS
    );

    const RegisterComponent = (
      <form className="[ register-container ]">
        <div className="[ form-title-register ]">
          {STRINGS.REGISTRATION.TITLE.split("_BANKING_TYPE_").join(
            bankingType.toUpperCase()
          )}
        </div>
        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.REGISTRATION.FORM.EMAILLABEL}
            </div>
            <input
              type="email"
              name="email"
              className={
                emailError && emailError.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              placeholder={`${STRINGS.REGISTRATION.FORM.EMAILPLACEHOLDER}`}
              value={email}
              ref={inputFocus}
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="[ error-msg ]">{emailError[0]}</div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.REGISTRATION.FORM.EMAILLABEL_CONFIRM}
            </div>
            <input
              type="email"
              name="emailConfirm"
              className={
                emailConfirmError && emailConfirmError.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              autoComplete="off"
              placeholder={`${STRINGS.REGISTRATION.FORM.EMAILPLACEHOLDER_CONFIRM}`}
              value={emailConfirm}
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="[ error-msg ]">{emailConfirmError[0]}</div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.REGISTRATION.FORM.MOBILENOLABEL}
            </div>
            <NumberFormat
              format={AppConstants.REGISTRATION.NUMBERFORMAT}
              className={
                mobileNoError && mobileNoError.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              type="tel"
              name="mobileNo"
              value={mobileNo}
              placeholder={`${STRINGS.REGISTRATION.FORM.MOBILENOPLACEHOLDER}`}
              onValueChange={(values) => onChangeHandler(values)}
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="[ error-msg ]">{mobileNoError[0]}</div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ] [ align-items-center ]">
              <div className="[ col-12 ] [ d-flex align-items-center ]">
                <div className=" [ checkbox custom-control custom-checkbox ]">
                  <input
                    type="checkbox"
                    defaultChecked={isChecked}
                    onChange={handleChecked}
                    name="termscondition"
                    id="termsCheckbox"
                    className={
                      termsConditionError && termsConditionError.length > 0
                        ? "[ invalid-input ] [ custom-control-input ]"
                        : "[ custom-control-input ]"
                    }
                  />
                  <label
                    htmlFor="termsCheckbox"
                    className="[ custom-control-label ]"
                  >
                    <span className="[ form-checkbox__text ]">
                      {STRINGS.REGISTRATION.FORM.TERMSLABEL1}
                      <a
                        href={FIInformation.FI_CONDITIONS}
                        className="[ text-primary ]"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {STRINGS.REGISTRATION.FORM.TERMSLABEL2}
                      </a>
                      {STRINGS.REGISTRATION.FORM.TERMSLABEL3}
                    </span>
                  </label>
                </div>
              </div>

              <div className="[ error-msg ] [ col-12 ]">
                {termsConditionError[0]
                  ? MODALMESSAGES.REGISTRATION.TERMSERROR
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-xl-6 ] [ offset-xl-3 ]">
              <button
                type="submit"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={submitData}
              >
                {STRINGS.REGISTRATION.FORM.REGISTRATIONBTN}
              </button>
            </div>
          </div>
        </div>

        {finInfo.enableCentralSignIn &&
          finInfo?.sso &&
          (!bankingType ||
            bankingType !== AppConstants.BANKING_TYPE.BUSINESS) && (
            <>
              <div className="[ form-container sso-signin-container ]">
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <div className="[ divider d-flex align-items-center ]">
                        <p className="[ text-center mb-0 mx-3 ]">
                          {STRINGS.REGISTRATION.FORM.OR}
                        </p>
                      </div>
                      <div className="[ alert alert-info ]" role="alert">
                        <h4 className="[ alert-heading ]">
                          {STRINGS.REGISTRATION.FORM.CENTRAL_SIGNIN_HEADER}
                          <QuestionCircle
                            className="[ question-icon ]"
                            onClick={() =>
                              this.handleOpenHelp(
                                STRINGS.REGISTRATION.FORM
                                  .SSO_MORE_INFO_DESCRIPTION
                              )
                            }
                          />
                        </h4>
                        <a
                          href="#"
                          className="[ link ]"
                          onClick={() => this.handleCentralSignIn()}
                          role="presentation"
                        >
                          <span>
                            {STRINGS.REGISTRATION.FORM.CENTRAL_SIGNIN}
                          </span>
                          <span className="[ icon external-link-icon ]"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </form>
    );

    const infoContainer = (
      <div className="[ register-secondary-container ]">
        <div className="[ info-title ]">
          {STRINGS.REGISTRATION.INFOBLOCK.SIGNINTOCONTINUE}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <button
                type="button"
                className="[ btn info-btn ] [ w-100 ]"
                onClick={signinHandler}
              >
                {STRINGS.REGISTRATION.INFOBLOCK.SIGNINTOCONTINUEBTN}
              </button>
            </div>
          </div>
        </div>

        <div className="[ row ][ info-message ] [ p-3 ]">
          {STRINGS.REGISTRATION.INFOBLOCK.SIGNINTOCONTINUEINFO1}
        </div>
      </div>
    );

    return (
      <>
        {showHelp && (
          <PopupModal
            modalType={AppConstants.MODALTYPE.CUSTOM}
            title={STRINGS.REGISTRATION.FORM.SSO_MORE_INFO_TITLE}
            description={helpText}
            toggleModal={this.handleCloseHelp}
            showModal={showHelp}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}

        <AuthBody
          actionComponent={RegisterComponent}
          infoComponent={infoContainer}
          securityText={STRINGS.REGISTRATION.SECURESESSIONTEXT}
          showVaultClicked={() => showVaultClicked}
          handleBack={signinHandler}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bankingType: state.MarketplaceReducer?.bankingType,
});

Register.propTypes = {
  bankingType: PropTypes.string.isRequired,
  emailError: PropTypes.array.isRequired,
  emailConfirmError: PropTypes.array.isRequired,
  mobileNoError: PropTypes.array.isRequired,
  termsConditionError: PropTypes.array.isRequired,
  submitData: PropTypes.func.isRequired,
  signinHandler: PropTypes.func.isRequired,
  showVaultClicked: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  handleChecked: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  emailConfirm: PropTypes.string.isRequired,
  mobileNo: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  inputFocus: PropTypes.object.isRequired,
  finInfo: PropTypes.oneOfType([PropTypes.object]),
};
Register.defaultProps = {
  finInfo: {},
};

export default connect(mapStateToProps)(Register);
