import AppConstants from "Constants/AppConstants";
import STRINGS from "../Constants/Strings";
import {
  S_APPLICANT_ID,
  S_APPLICANT_MEMBER_NO,
  S_PERSONAL,
  S_QR_KYC,
  TOKEN_KEY,
} from "../Communication/Constants";
import { format } from "date-fns";
import axios from "axios";
import { isNil } from "lodash";
// import { termsAndConditionStyle } from "../Components/OverdraftProtection/OverdraftProtection.style";
import html2pdf from "html2pdf.js";

export const togglePassword = (toggleFlag) => {
  return toggleFlag ? "text" : "password";
};

export const usernameCredentials = (username) => {
  if (!Number.isNaN(username)) {
    const pattern = AppConstants.SIGNIN.USERNAME_PATTERN;

    const checkpattern = new RegExp(pattern).test(username);
    let credentialNumber;

    if (checkpattern) {
      credentialNumber = AppConstants.SIGNIN.NUMBERPREFIX + username;
    } else {
      credentialNumber = username;
    }
    return credentialNumber;
  }
  return username;
};

export const removeLinkTag = () => {
  if (window.IDMetricsCaptureFramework === undefined) {
    setTimeout(removeLinkTag, 50); // wait 50 millisecnds then recheck
    return;
  }
  const instntDiv = document.getElementById("instnt-form-generator");
  for (let i = 0; i < instntDiv.children.length; i += 1) {
    const linkNode =
      instntDiv.children[i].nodeName === AppConstants.NODE_NAME
        ? instntDiv.children[i]
        : null;
    if (linkNode) {
      instntDiv.removeChild(linkNode);
    }
  }
};
/**
 *
 * @param {array} searchInArray represents array
 * to be passed for searching
 * @param {string} searchForCode represents code
 * using which we can search for it's respective
 * description value
 */
export const getDescriptionFromArray = (searchInArray, searchForCode) => {
  const result = searchInArray?.find((search) => search.code === searchForCode);
  return result?.description;
};

/** ORIGINAL AGENT STRING FOR MOBILE CHECK */

/**
 * Name: isDeviceMobile
 * Description: Checking for device type using JS window object
 *              Returns true only if the device is mobile or tablet.
 * Device List: ["Android","webOS","iPhone","iPad","iPod","BlackBerry","Windows Phone"]
 */

// export const isDeviceMobile = () => {
//   if (
//     window.navigator.userAgent.match(/Android/i) ||
//     window.navigator.userAgent.match(/webOS/i) ||
//     window.navigator.userAgent.match(/iPhone/i) ||
//     window.navigator.userAgent.match(/iPod/i) ||
//     window.navigator.userAgent.match(/BlackBerry/i) ||
//     window.navigator.userAgent.match(/Windows Phone/i) ||
//     window.navigator.userAgent.match(/Mobile/i)
//   ) {
//     return true;
//   }
//   return false;
// };

/**
 * Name: isDeviceTablet
 * Description: Checking for device type using JS window object
 *              Returns true only if the device is mobile or tablet.
 * Device List: ["iPad"]
 */

// export const isDeviceTablet = () => {
//   if (window.navigator.userAgent.match(/iPad/i)) {
//     return true;
//   }
//   return false;
// };

/**
 * Name: isDeviceMobile
 * Description: Checking for device type using JS window object
 *              Returns true only if the device is mobile or tablet.
 * Device List: ["Android","webOS","iPhone","iPad","iPod","BlackBerry","Windows Phone"]
 */

// export const isScanningAllowed = () => {
//   if (isDeviceMobile() || isDeviceTablet()) {
//     return true;
//   }
//   return false;
// };

/** END ORIGINAL AGENT STRING FOR MOBILE CHECK */

/** UPDATED MOBILE CHECK - CHRIS */

/**
 * Name: isDeviceMobile
 * Description: Checks if the device is a mobile phone using the JS window object.
 *              Returns true if the device is a mobile phone.
 */
export const isDeviceMobile = () => {
  const userAgent =
    window.navigator.userAgent || window.navigator.vendor || window.opera;

  // Regex patterns to capture a wide range of mobile devices and browsers
  const mobileRegex = /Android|webOS|iPhone|iPod|BlackBerry|Windows Phone|Opera Mini|Mobile Safari|Kindle|Silk/i;

  const isMobileAgent = mobileRegex.test(userAgent);
  const maxMobileWidth = 767; // Maximum width for mobile devices
  const isMobileScreenSize = window.screen.width <= maxMobileWidth;

  return isMobileAgent || isMobileScreenSize;
};

/**
 * Name: isDeviceTablet
 * Description: Checks if the device is a tablet using the JS window object and screen size.
 *              Returns true if the device is a tablet.
 */
export const isDeviceTablet = () => {
  const userAgent =
    window.navigator.userAgent || window.navigator.vendor || window.opera;

  // Regex patterns to capture a wide range of tablets
  const tabletRegex = /iPad|Android(?!.*Mobile)|Kindle|Silk|PlayBook|Tab/i;

  const isTabletAgent = tabletRegex.test(userAgent);
  const minTabletWidth = 768; // Minimum width for tablets
  const maxTabletWidth = 1366; // Maximum width for most tablets
  const isTabletScreenSize =
    window.screen.width >= minTabletWidth &&
    window.screen.width <= maxTabletWidth;
  const highDPI = window.devicePixelRatio > 1;

  // Special case for iPad Pro
  const isIpadPro =
    (window.screen.width === 1024 && window.screen.height === 1366) ||
    (window.screen.width === 1366 && window.screen.height === 1024);

  return isTabletAgent || (isTabletScreenSize && highDPI) || isIpadPro;
};

/**
 * Name: isIOS
 * Description: Detects if the device is running iOS 13 or above on a Mac.
 *              Returns true if the device is an iOS device or Mac with touch support.
 */
export const isIOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

/**
 * Name: isTouchEnabled
 * Description: Checks if the device has touch capabilities.
 *              Returns true if the device supports touch events.
 */
export const isTouchEnabled = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

/**
 * Name: isScanningAllowed
 * Description: Determines if scanning is allowed based on device type.
 *              Returns true if the device is a mobile phone, tablet, a Mac running iOS 13 or above, or has touch capabilities.
 */
export const isScanningAllowed = () => {
  // return isDeviceMobile() || isDeviceTablet() || isIOS() || isTouchEnabled();
  return isDeviceMobile() || isDeviceTablet() || isIOS();
};

/** END UPDATED MOBILE CHECK - CHRIS */

/**
 * Name: convertBase64ImageToString
 * @param {*} dataUri
 * Description: Removes prefix from the base 64 scanned images.
 */
export const convertBase64ImageToString = (dataUri) => {
  let byteString;
  if (dataUri.split(",")[0].indexOf("base64") <= 0)
    byteString = atob(dataUri.split(",")[1]);
  else byteString = unescape(dataUri.split(",")[1]);

  return byteString;
};

export const getProductCategoryList = (products) => {
  return products.map((item, index) => {
    return {
      code: index + 1,
      description: item.id,
      checked: true,
      type: item.type,
      show: item.show,
    };
  });
};

export const getVaultCardProductList = (productList, vaultProductList) => {
  const vaultCardProductList = [];
  let list = [];

  productList.forEach((element) => {
    const { products } = { ...element };
    if (list.length !== 0) {
      list = list.concat(products);
    } else {
      list = products;
    }
  });

  vaultProductList.forEach((product) => {
    let card = {};
    const index = list.findIndex(
      (item) => item.productId === product.productId
    );
    if (index !== -1) {
      card = { ...product, ...list[index] };
      card.productIndex = product.productIndex;
      card.applicationId = product.applicationId;
      card.bankingType =
        product.bankingType || AppConstants.BANKING_TYPE.PERSONAL;
      const existAlready = vaultCardProductList.find(
        (item) => item.productIndex === card.productIndex
      );
      if (!existAlready) {
        vaultCardProductList.push(card);
      }
    }
  });
  return vaultCardProductList;
};

export const getProduct = (productList, vaultProductList) => {
  let list = [];
  let product = { productName: "" };
  if (vaultProductList.length === 0) return product;
  const productobject = vaultProductList[0];
  productList.forEach((element) => {
    const { products } = { ...element };
    if (list.length !== 0) {
      list = list.concat(products);
    } else {
      list = products;
    }
  });
  const index = list.findIndex(
    (item) => item.productId === productobject.productId
  );
  if (index !== -1) {
    product = { ...list[index] };
    return product;
  }
  return product;
};

/**
 * Function to dynamically replace placeholders in an API URL with actual values.
 * @param {String} url with palceholders prefixed with '%'. Eg. %1
 * @param {Array} paramList
 * returns String URL with dynamic params
 */
export const formURL = (url, paramList) => {
  let newUrl = url.replace(/%(\d+)/g, (_, n) => paramList[+n - 1]);
  if (newUrl.includes("&")) {
    newUrl = `${newUrl}&t=${new Date().getTime()}`;
  } else {
    newUrl = `${newUrl}?t=${new Date().getTime()}`;
  }
  return newUrl;
};
/**
 * Sort vaultProductList in ascending order on field property.
 * @param {*} listToSort
 * @param {*} field
 */
export const sortList = (listToSort, field) => {
  return listToSort.sort(
    (productOne, productTwo) => productOne[field] - productTwo[field]
  );
};

export const getGettingStarted = (data, callback) => {
  const gettingStartedData = {
    branch: "",
    accountStatementType: "",
    reasonCode: "",
    promoCode: "",
    referralCode: "",
  };
  gettingStartedData.branch = data.member.branch;
  gettingStartedData.reasonCode = data.member.reasonOpened;

  if (data.regulatory.eStmt) {
    gettingStartedData.accountStatementType = AppConstants.GETTINGSTARTED.EMAIL;
  } else {
    gettingStartedData.accountStatementType = AppConstants.GETTINGSTARTED.PAPER;
  }
  if (data.regulatory.regulatoryFields) {
    gettingStartedData.promoCode = data.regulatory?.regulatoryFields?.find(
      (item) => item.Code === 506
    )?.Value;
    gettingStartedData.referralCode = data.regulatory?.regulatoryFields?.find(
      (item) => item.Code === 507
    )?.Value;
  }
  callback(gettingStartedData);
};

export const commaFormatted = (amount) => {
  if (amount) {
    if (typeof amount === "number") {
      return amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "";
};

export const commaFormattedNoDecimal = (amount) => {
  if (amount) {
    if (typeof amount === "number") {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "";
};

export const capitalize = (s) => {
  if (typeof s !== "string") return s;
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const findCode = (code, lookup) => {
  let result = "";
  if (code && lookup) {
    const x = lookup.find((item) => item.code === code);
    if (x) {
      result = x.description;
    }
  }
  return result;
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "";
};

export const getApplicantStatus = (applicant) => {
  let status = "";
  if (applicant.appliedAt) {
    status = "Completed";
  } else {
    if (applicant.inviteDetail) {
      status = "Pending";
    } else {
      status = "Pending";
    }
  }
  return status;
};

export const getApplicantType = (applicantTypes, type) => {
  let description = "";
  applicantTypes.forEach((item) => {
    if (item.code === type) {
      description = item.description;
    }
  });
  return description;
};

export const getProductInfo = (productid, productList) => {
  let product = {};
  console.log("getProductInfo", productid, productList);
  productList.forEach((item) => {
    item.products.forEach((x) => {
      if (x.productId === productid || x.productId.includes(productid)) {
        product = x;
      }
    });
  });
  console.log(product);
  return product;
};

export const searchCategoryInCompleteList = (
  category,
  productList,
  applicationCompleteList
) => {
  let product = null;
  console.log(
    "searchCategoryInCompleteList",
    category,
    productList,
    applicationCompleteList
  );
  applicationCompleteList.forEach((item) => {
    item.doximProducts.forEach((x) => {
      productList.forEach((y) => {
        y.products.forEach((z) => {
          console.log("z", z);
          if (
            // productId can have multiple products delimited by |
            z.productCategory === category &&
            z.productId.includes(x.bundleProductId)
          ) {
            product = z;
          }
        });
      });
    });
  });
  console.log("result", product);
  return product;
};

export const findByDescription = (desc, lookup) => {
  console.log("findByDescription", desc, lookup);
  return lookup?.find((item) => desc === item.description);
};

/**
 * Returns active product if not found then returns first object from the array
 * @param {*} products
 */
export const getActiveProduct = (products) => {
  let activeProduct = products?.find((product) => product?.active);
  if (!activeProduct) {
    if (!activeProduct && products?.length) {
      activeProduct = products[0];
    }
    if (!activeProduct?.applicantId) {
      const jointSlotId = sessionStorage.getItem(
        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
      );
      if (jointSlotId) {
        const applicantId = sessionStorage.getItem(S_APPLICANT_ID);
        if (applicantId) {
          activeProduct.applicantId = applicantId;
        }
      }
    }
  }
  return activeProduct;
};

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return navigator.clipboard.writeText(text);
  }
  return document.execCommand("copy", true, text);
}

/**
 * returns the step object's index from the template flow which matches the param step
 * @param {*} template
 * @param {*} step
 */
export const getCurrentStepIndexFromTemplateFlowByStep = (template, step) => {
  return template?.flow?.findIndex(
    (applicantStep) => applicantStep.componentName === step
  );
};

export const getContinousKycRegulatorUpdates = (
  regulatoryRequest,
  vaultMember,
  applicantData,
  finInfo
) => {
  console.log(
    "getContinousKycRegulatorUpdates",
    regulatoryRequest,
    vaultMember,
    applicantData,
    finInfo
  );
  let newRegulatoryRequest = regulatoryRequest;

  if (!newRegulatoryRequest) {
    newRegulatoryRequest = {};
  }

  const newOECD = vaultMember?.regulatoryFields?.find(
    (item) => item.Code === 1
  );
  const currentOECD = applicantData.regulatory.regulatoryFields?.find(
    (item) => item.Code === 1
  );

  console.log("preview", newOECD, currentOECD);
  let updateRegulatoryFieldsInputModel = [];
  if (
    vaultMember?.fatcaStatus?.toUpperCase() ===
      finInfo.noFatcaCrs?.toUpperCase() &&
    applicantData.regulatory.fatcaStatus?.toUpperCase() !==
      finInfo.noFatcaCrs?.toUpperCase() &&
    currentOECD.Value?.toUpperCase() === finInfo.oecdCrs?.toUpperCase() &&
    newOECD.Value?.toUpperCase() === finInfo.noFatcaCrs?.toUpperCase()
  ) {
    newRegulatoryRequest.fatcaStatus = applicantData.regulatory.fatcaStatus;
    newRegulatoryRequest.fatcaType = applicantData.regulatory.fatcaType;
    newRegulatoryRequest.ssn = applicantData.regulatory.ssn;
    updateRegulatoryFieldsInputModel = [
      ...applicantData.regulatory.regulatoryFields,
    ];
  } else if (
    vaultMember?.regulatoryFields &&
    Array.isArray(vaultMember?.regulatoryFields)
  ) {
    for (const item of vaultMember?.regulatoryFields) {
      if (!finInfo.kycComplianceRegulatoryUpdate) {
        updateRegulatoryFieldsInputModel.push({
          ...item,
          Text: item.Text.split("-").join(" "),
        });
      } else if (item.Code !== 12) {
        updateRegulatoryFieldsInputModel.push({
          ...item,
          Text: item.Text.split("-").join(" "),
        });
      }
    }
  }
  newRegulatoryRequest.updateRegulatoryFieldsInputModel = updateRegulatoryFieldsInputModel;

  if (finInfo.kycComplianceRegulatoryUpdate) {
    // check if code 12 already exist
    const code12Idx = newRegulatoryRequest.updateRegulatoryFieldsInputModel?.findIndex(
      (item) => item.Code === 12
    );
    if (code12Idx && code12Idx > -1) {
      newRegulatoryRequest.updateRegulatoryFieldsInputModel[
        code12Idx
      ].Value = format(new Date(), "yyyy-MM-dd");
    } else {
      newRegulatoryRequest.updateRegulatoryFieldsInputModel.push({
        ApplicantID: applicantData.id,
        Code: 12,
        Value: format(new Date(), "yyyy-MM-dd"),
        Text: "Compliance Updated Date",
        Type: "date",
      });
    }
  }

  console.log("newRegulatoryRequest", newRegulatoryRequest);
  return newRegulatoryRequest;
};

export const pepExist = (applicantData) => {
  let result = false;

  if (applicantData) {
    if (
      applicantData.regulatory.memberFundsSource ||
      applicantData.regulatory.memberHasThirdParty ||
      applicantData.regulatory.memberIsHio ||
      applicantData.regulatory.memberIsPedp ||
      applicantData.regulatory.memberIsPefp ||
      applicantData.regulatory.memberJurisdiction ||
      applicantData.regulatory.memberOfficeHeld ||
      (Array.isArray(applicantData.peps) && applicantData.peps.length > 0) ||
      (Array.isArray(applicantData.thirdParties) &&
        applicantData.thirdParties.length > 0)
    ) {
      result = true;
    }
  }

  return result;
};

export const getCountryCode = (finInfo, idName, issuerCode) => {
  let result = issuerCode;

  if (finInfo.cardIssuerCode && Array.isArray(finInfo.cardIssuerCode)) {
    const cardIssuer = finInfo.cardIssuerCode.find((x) => x.type === idName);
    if (cardIssuer) {
      const countryCode = cardIssuer.find((card) => card.code === issuerCode);
      if (countryCode) {
        result = countryCode.newCode;
      }
    }
  }

  return result;
};

export const getLicenseCountryCode = (finInfo, idName, issuerCode) => {
  let result = "";

  if (finInfo.cardIssuerCode && Array.isArray(finInfo.cardIssuerCode)) {
    const cardIssuer = finInfo.cardIssuerCode.find((x) => x.type === idName);
    if (cardIssuer) {
      const countryCode = cardIssuer.countryIssuerCode.find(
        (card) => card.code === issuerCode
      );
      if (countryCode) {
        result = countryCode.newCode;
      }
    }
  }

  if (!result) {
    result = finInfo.idCountry1;
  }

  return result;
};

export const getLicenseProvinceCode = (finInfo, idName, issuerCode) => {
  let result = issuerCode;

  if (finInfo.cardIssuerCode && Array.isArray(finInfo.cardIssuerCode)) {
    const cardIssuer = finInfo.cardIssuerCode.find((x) => x.type === idName);
    if (cardIssuer) {
      result = "";
    }
  }

  return result;
};

export const getSingularMemberships = (
  membershipsData,
  applicationCompleteList,
  applicantData
) => {
  console.log(
    "getSingularMemberships",
    membershipsData,
    applicationCompleteList
  );
  let result = [];

  if (membershipsData) {
    const singularMemberships = membershipsData.filter(
      (x) => x.Category === "n"
    );
    if (singularMemberships.length > 0) {
      result = [...singularMemberships];
    }
  }

  const sessionApplicantId = sessionStorage.getItem(S_APPLICANT_ID);
  const sessionMemberNumber = sessionStorage.getItem(S_APPLICANT_MEMBER_NO);
  applicationCompleteList.forEach((x) => {
    if (
      !(x.slots?.length > 1) &&
      x.inReview &&
      x.memberNumber.startsWith("P") &&
      x.applicantId === sessionApplicantId
    ) {
      const existingApp = result.find((app) => app.Member === x.memberNumber);
      if (!existingApp) {
        result.push({
          applicantId: x.applicantId,
          FirstName: x.firstName,
          LastName: x.lastName,
          Branch: x.branch,
          Member: x.memberNumber,
        });
      }
    }
  });

  if (sessionMemberNumber?.startsWith("P")) {
    applicationCompleteList.forEach((x) => {
      if (
        !(x.slots?.length > 1) &&
        x.uploadedAt &&
        x.applicantId === sessionApplicantId
      ) {
        const existingApp = result.find((app) => app.Member === x.memberNumber);
        if (!existingApp) {
          result.push({
            applicantId: x.applicantId,
            FirstName: x.firstName,
            LastName: x.lastName,
            Branch: x.branch,
            Member: x.memberNumber,
          });
        }
      }
    });
  }

  return result;
};

export const getJointMemberships = (
  membershipsData,
  applicationCompleteList,
  applicantData
) => {
  let result = [];

  if (membershipsData) {
    const jointMemberships = membershipsData.filter((x) => x.Category !== "n");
    console.log("jointMemberships", jointMemberships);
    if (jointMemberships.length > 0) {
      result = [...jointMemberships];
    }
  }
  const sessionApplicantId = sessionStorage.getItem(S_APPLICANT_ID);
  const sessionMemberNumber = sessionStorage.getItem(S_APPLICANT_MEMBER_NO);
  applicationCompleteList.forEach((x) => {
    if (
      x.slots?.length > 1 &&
      x.inReview &&
      x.memberNumber.startsWith("P") &&
      x.applicantId === sessionApplicantId
    ) {
      const existingApp = result.find((app) => app.Member === x.memberNumber);
      if (!existingApp) {
        const membershipMember = membershipsData.find(
          (member) => member.Member === x.memberNumber
        );
        if (
          !membershipMember ||
          (membershipMember && membershipMember.Category !== "n")
        ) {
          result.push({
            applicantId: x.applicantId,
            FirstName: x.firstName,
            LastName: x.lastName,
            Branch: x.branch,
            Member: x.memberNumber,
          });
        }
      }
    }
  });

  if (sessionMemberNumber?.startsWith("P")) {
    applicationCompleteList.forEach((x) => {
      if (
        x.slots?.length > 1 &&
        x.uploadedAt &&
        x.applicantId === sessionApplicantId
      ) {
        const existingApp = result.find((app) => app.Member === x.memberNumber);
        if (!existingApp) {
          const membershipMember = membershipsData.find(
            (member) => member.Member === x.memberNumber
          );
          if (
            !membershipMember ||
            (membershipMember && membershipMember.Category !== "n")
          ) {
            result.push({
              applicantId: x.applicantId,
              FirstName: x.firstName,
              LastName: x.lastName,
              Branch: x.branch,
              Member: x.memberNumber,
            });
          }
        }
      }
    });
  }
  console.log("result", result);
  return result;
};

export const findNaicsCode = (array, code, parent, parentCode) => {
  let result = [];

  if (array) {
    if (parent) {
      array.map((x) => {
        if (x[parent] === parentCode) {
          const findItem = result.find((curr) => curr === x[code]);
          if (!findItem) {
            result.push(x[code]);
          }
        }
      });
    } else {
      array.map((x) => {
        const findItem = result.find((curr) => curr === x[code]);
        if (!findItem) {
          result.push(x[code]);
        }
      });
    }
  }

  return result;
};

export const getPersonalId = () => {
  let result = null;

  const personalId = sessionStorage.getItem(S_PERSONAL);

  if (personalId && personalId !== "null") {
    result = personalId;
  }

  return result;
};

export const getBusinessAddress = (addresses) => {
  let result = null;
  if (addresses != null && Array.isArray(addresses)) {
    result = {
      currentAddressLine1: addresses.find((x) => x.type === "Registered Office")
        ?.streetName,
      currentAddressLine2: addresses.find((x) => x.type === "Registered Office")
        ?.unitNumber,
      currentAddressUnitNumber: addresses.find(
        (x) => x.type === "Registered Office"
      )?.unitNumber,
      currentAddressLine3: addresses.find((x) => x.type === "Registered Office")
        ?.city,
      currentAddressCity: addresses.find((x) => x.type === "Registered Office")
        ?.city,
      currentAddressProvince: addresses.find(
        (x) => x.type === "Registered Office"
      )?.province,
      currentAddressPostalCode: addresses.find(
        (x) => x.type === "Registered Office"
      )?.postalCode,
      // currentAddressCountry: addresses.find(
      //     (x) => x.type === "Registered Office"
      // )?.country,
      mailingAddressLine1: addresses.find((x) => x.type === "Mailing Address")
        ?.streetName,
      mailingAddressLine2: addresses.find((x) => x.type === "Mailing Address")
        ?.unitNumber,
      mailingUnitNumber: addresses.find((x) => x.type === "Mailing Address")
        ?.unitNumber,
      mailingAddressLine3: addresses.find((x) => x.type === "Mailing Address")
        ?.city,
      mailingCity: addresses.find((x) => x.type === "Mailing Address")?.city,
      mailingProvince: addresses.find((x) => x.type === "Mailing Address")
        ?.province,
      mailingPostalCode: addresses.find((x) => x.type === "Mailing Address")
        ?.postalCode,
      // mailingCountry: addresses.find(
      //     (x) => x.type === "Mailing Address"
      // )?.country,
    };
  }
  return result;
};

export const cleanPhone = (raw) => {
  let result = raw;

  result = result.split("+").join("");
  result = result.split(" ").join("");
  result = result.split("(").join("");
  result = result.split(")").join("");
  result = result.split("-").join("");

  return result;
};

export const closeTab = () => {
  window.opener = null;
  window.open("", "_self");
  window.close();
};

export const fetchHtmlContent = async (url) => {
  const response = await axios.get(url, { responseType: "text" });
  return response.data;
};

export const downloadTermsAndConditions = async (url) => {
  console.log("~~ downloadTermsAndConditions a", url);
  if (!isNil(url)) {
    console.log("~~ downloadTermsAndConditions b");
    try {
      const htmlContent = await fetchHtmlContent(url);

      // Create a container to hold the fetched HTML content without adding it to the DOM
      const container = document.createElement("div");
      container.setAttribute("type", "hidden");
      container.innerHTML = htmlContent;

      // Extract the filename from the URL, remove extension, and append .pdf
      const urlParts = url.split("/");
      let fileName = urlParts[urlParts.length - 1].split(".")[0];
      fileName = `${fileName}.pdf`;

      // Apply styles for better PDF rendering
      // CHRIS: REDUNDANT AS EACH FI WILL HAVE THEIR OWN STYLES
      // const styles = `
      //   <style>
      //     ${termsAndConditionStyle}
      //   </style>
      // `;
      // container.insertAdjacentHTML("beforeend", styles);

      // Set options for html2pdf
      const opt = {
        margin: [0.5, 0.5, 0.5, 0.5], // Top, right, bottom, left
        filename: fileName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak: {
          mode: ["avoid-all", "css", "legacy"],
          before: ".page-break",
        }, // Ensure breaks before .page-break
      };

      // Use html2pdf to generate the PDF and trigger the download
      html2pdf().from(container).set(opt).save();
    } catch (error) {
      console.error("Error fetching or converting HTML:", error);
    }
  }
};

export const printTermsAndConditions = async (url, frameId) => {
  console.log("printTermsAndConditions", url, frameId);
  if (!isNil(url)) {
    try {
      const htmlContent = await fetchHtmlContent(url);
      const iframe = document.frames
        ? document.frames[frameId]
        : document.getElementById(frameId);

      const iframeWindow = iframe.contentWindow || iframe;

      iframe.focus();
      iframeWindow.print();

      // iframe.onload = () => {
      //   iframe.contentWindow.focus(); // Ensure the iframe window is focused
      //   iframe.contentWindow.print(); // Trigger the print dialog
      //   document.body.removeChild(iframe); // Clean up the iframe after printing
      // };
    } catch (error) {
      console.error("Error fetching or converting HTML:", error);
    }
  }
};

export const ciEquals = (a, b) => {
  return typeof a === "string" && typeof b === "string"
    ? a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
    : a === b;
};
