import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import STRINGS from "Constants/Strings";
import "./ConfirmPopupModal.scss";

class ConfirmPopupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.showModal,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    const {
      title,
      description,
      toggleModal,
      yesBtnClick,
      noBtnClick,
      closeBtnClick,
    } = this.props;

    const { show } = this.state;

    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            this.handleClose();
            toggleModal();
            closeBtnClick();
          }}
          className="[ confirm-popup-modal__container ]"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="[ confirm-popup-modal__header ]" closeButton>
            <Modal.Title className="[ icon confirm-popup-modal-delete-icon ]">
              {title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="[ confirm-popup-modal__body ]">
            <div className="[ confirm-popup-modal-message__title ]">
              {description}
            </div>
          </Modal.Body>
          <Modal.Footer className="[ confirm-popup-modal-footer ]">
            <div className="[ row ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  onClick={() => {
                    this.handleClose();
                    toggleModal();
                    noBtnClick();
                  }}
                  className="[ btn btn-info w-100 ] [ confirm-popup-modal-footer__okBtn submit-btn ] "
                >
                  {STRINGS.VAULT.DELETE_CANCEL}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="button"
                  onClick={() => {
                    this.handleClose();
                    toggleModal();
                    yesBtnClick();
                  }}
                  className="[ btn btn-info w-100 ] [ confirm-popup-modal-footer__okBtn submit-btn ]"
                >
                  {STRINGS.VAULT.DELETE_CONFIRM}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

ConfirmPopupModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  toggleModal: PropTypes.func,
  yesBtnClick: PropTypes.func,
  noBtnClick: PropTypes.func,
  closeBtnClick: PropTypes.func,
};

ConfirmPopupModal.defaultProps = {
  showModal: false,
  title: "",
  description: "",
  toggleModal: () => {},
  yesBtnClick: () => {},
  noBtnClick: () => {},
  closeBtnClick: () => {},
};

export default withRouter(ConfirmPopupModal);
