import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import "./PopupModal.scss";
import AppConstants from "Constants/AppConstants";
import MODALMESSAGES from "Constants/Messages";
import STRINGS from "Constants/Strings";
import { PRODUCT_IMAGE_URL, COMMON_BUCKET_URL } from "Communication/ApiUrls";
import {
  formatPhoneNumber,
  getApplicantStatus,
  getApplicantType,
  getProductInfo,
} from "../../../Utils/CommonUtilities";

class PopupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.showModal,
      showLoading: true,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  handleClose = () => {
    this.setState({ show: false });
  };

  onIframeLoad = () => {
    setInterval(() => {
      this.setState({ showLoading: false });
    }, 500);
  };

  getRequiredProductMessage = (product, productList) => {
    console.log("getProductImageUrl", product);
    let logoUrl = PRODUCT_IMAGE_URL;
    let productInfo = {};
    if (product) {
      if (Array.isArray(product)) {
        productInfo = getProductInfo(product[0].default, productList);
        logoUrl += productInfo.productLogo;
      }
    }
    return (
      <>
        <div className="[ required_product ]">
          <p>{MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_MSG1}</p>
          <div className="[ card ]">
            {/* if the product's 'productLogo' key/value pair is missing an image reference or if the literal is missing completely, then the
            image block needs to be hidden and depending on the account type, either savings or chequings, the pig or cheque icon need to be shown, (<div className="[ promo-icon ]">), in its place. */}
            <p>
              <strong>
                {MODALMESSAGES.PRODUCTREQUIRED.ADD_PRODUCT_AND_CONTINUE_PT1}{" "}
                {`${productInfo.productName}`}{" "}
                {MODALMESSAGES.PRODUCTREQUIRED.ADD_PRODUCT_AND_CONTINUE_PT1_2}
              </strong>
            </p>
            {productInfo.productLogo && (
              <img
                src={logoUrl}
                className="[ w-100 ] [ product-icon-container__img ]"
                alt={productInfo.productName}
              />
            )}
            {!productInfo.productLogo && (
              <div className="[ promo-icon ]">
                <i className="[ fas fa-piggy-bank ]" />
              </div>
            )}
            {!productInfo.promoDetails &&
              product[0].category === AppConstants.PRODUCT_CATEGORY.SAVINGS && (
                <div className="[ promo-icon ]">
                  <i className="[ fas fa-piggy-bank ]" />
                </div>
              )}
            {!productInfo.promoDetails &&
              product[0].category ===
                AppConstants.PRODUCT_CATEGORY.CHEQUING && (
                <div className="[ promo-icon ]">
                  <i className="[ fas fa-money-check-alt ]" />
                </div>
              )}

            <hr />

            {productInfo.promoDetails && (
              <div
                dangerouslySetInnerHTML={{ __html: productInfo.promoDetails }}
              />
            )}
          </div>
          <p>{MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_MSG2}</p>
          <p>{MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_MSG3}</p>
        </div>
        <div className="[ popup-modal-message__title ]">
          <span>{MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_MSG_2}</span>
        </div>
      </>
    );
  };

  getRecommendProductMessage = (product, productList) => {
    console.log("getRecommendProductMessage", product);
    let logoUrl = PRODUCT_IMAGE_URL;
    let productInfo = {};
    if (product) {
      productInfo = getProductInfo(product, productList);
      logoUrl += productInfo.productLogo;
    }
    return (
      <>
        <div className="[ recommended_product ]">
          <p>{MODALMESSAGES.RECOMMEND.REQUIRED_MSG1}</p>
          <div className="[ card ]">
            <p>
              <strong>
                {MODALMESSAGES.RECOMMEND.ADD_PRODUCT_AND_CONTINUE_PT1}{" "}
                {`${productInfo.productName}`}{" "}
                {MODALMESSAGES.RECOMMEND.ADD_PRODUCT_AND_CONTINUE_PT1_2}
              </strong>
            </p>
            {/* if the product's 'productLogo' key/value pair is missing an image reference or if the literal is missing completely, then the
            image block needs to be hidden and depending on the account type, either savings or chequings, the pig or cheque icon need to be shown, (<div className="[ promo-icon ]">), in its place. */}
            <img
              src={logoUrl}
              className="[ w-100 ] [ product-icon-container__img ]"
              alt={productInfo.productName}
            />

            {!productInfo.promoDetails &&
              productInfo.category ===
                AppConstants.PRODUCT_CATEGORY.SAVINGS && (
                <div className="[ promo-icon ]">
                  <i className="[ fas fa-piggy-bank ]" />
                </div>
              )}
            {!productInfo.promoDetails &&
              productInfo.category ===
                AppConstants.PRODUCT_CATEGORY.CHEQUING && (
                <div className="[ promo-icon ]">
                  <i className="[ fas fa-money-check-alt ]" />
                </div>
              )}

            <hr />
            {productInfo.promoDetails && (
              <p>
                <div
                  dangerouslySetInnerHTML={{ __html: productInfo.promoDetails }}
                />
              </p>
            )}
          </div>
          <p>{MODALMESSAGES.RECOMMEND.RECOMMEND_MESSAGE1}</p>
        </div>
      </>
    );
  };

  render() {
    const {
      modalType,
      customBtnClick,
      customBtnText,
      product,
      title,
      description,
      description2,
      description3,
      type,
      toggleModal,
      popupBtnClick,
      btnText,
      // btnTextToMarketplace,
      showPopupModalButtons,
      showPopupFooterActionButtons,
      showPopupHeaderCloseButton,
      iframeModal,
      iframeUrl,
      footer,
      closeBtnClick,
      subtitle,
      addToVault,
      tag,
      seeDetailsClick,
      shortDescription,
      productLogo,
      slots,
      applicantTypes,
      requiredList,
      productList,
      addProduct,
      recommendProduct,
      ageRestriction,
      popupFooterActionContent,
    } = this.props;
    console.log(
      "render",
      product,
      slots,
      description,
      description2,
      description3,
      modalType,
      title
    );

    const popUpHeaderClassNames = ["popup-icon"];
    if (modalType === AppConstants.MODALTYPE.SUCCESS) {
      popUpHeaderClassNames.push("popup-modal-success-icon");
    } else if (modalType === AppConstants.MODALTYPE.FAILURE) {
      popUpHeaderClassNames.push("popup-modal-failure-icon");
    } else if (modalType === AppConstants.MODALTYPE.INFORMATION) {
      popUpHeaderClassNames.push("popup-modal-info-icon");
    }

    const { show, showLoading } = this.state;

    let productStatusContainerClass = "";
    let productStatusTitleClass = "";
    let productStatusText = "";
    if (product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IN_REVIEW) {
      productStatusContainerClass = "[ review-container ]";
      productStatusTitleClass = "[ review-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.IN_REVIEW;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IS_COMPLETED
    ) {
      productStatusContainerClass = "[ completed-container ]";
      productStatusTitleClass = "[ completed-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.COMPLETED;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IS_REJECTED
    ) {
      productStatusContainerClass = "[ rejected-container ]";
      productStatusTitleClass = "[ rejected-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.REJECTED;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IN_PROGRESS
    ) {
      productStatusContainerClass = "[ progress-container ]";
      productStatusTitleClass = "[ progress-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.IN_PROGRESS;
    }

    console.log("~~ popupModal overdraft a", "~~ type", type);
    console.log("~~ popupModal overdraft b", "~~ iframeModal", iframeModal);
    console.log(
      "~~ popupModal overdraft c",
      "~~ showPopupModalButtons",
      showPopupModalButtons
    );

    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            this.handleClose();
            toggleModal();
            closeBtnClick();
          }}
          className={
            seeDetailsClick
              ? "[ popup-modal__container product-description ]"
              : "[ popup-modal__container ]"
          }
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" // this prevents the pop up from being closed when clicked outside of the pop up
          keyboard={false}
        >
          <Modal.Header
            className="[ popup-modal__header ]"
            closeButton={showPopupModalButtons || showPopupHeaderCloseButton}
          >
            {tag && (
              <div className="[ added-container ]">
                <span className="[ added-title ]">
                  {STRINGS.MARKETPLACE.BODY.ADDED}
                </span>
              </div>
            )}

            <div className={`${productStatusContainerClass}`}>
              <span className={`${productStatusTitleClass}`}>
                {productStatusText}
              </span>
            </div>

            <Modal.Title className={popUpHeaderClassNames.join(" ")}>
              {!requiredList && !recommendProduct && (
                <>
                  {iframeModal || modalType === AppConstants.MODALTYPE.CUSTOM
                    ? `${title}`
                    : `${type}`}
                </>
              )}

              {requiredList && (
                <span>{MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_TITLE}</span>
              )}

              {recommendProduct && (
                <span>{MODALMESSAGES.RECOMMEND.POPUP_TITLE}</span>
              )}
            </Modal.Title>
          </Modal.Header>

          {iframeModal ? (
            <Modal.Body className="[ text-left ] [ overflow-auto ] [ iframe-popup-modal__body ]">
              {showLoading ? (
                <div className="[ progress-indicator-container ]">
                  <div className="[ icon loading-icon fa-spin ]" />
                </div>
              ) : null}
              <iframe
                title="iframe"
                src={iframeUrl}
                className="[ iframe-popup-modal__iframe ]"
                onLoad={this.onIframeLoad}
              />
            </Modal.Body>
          ) : (
            <Modal.Body className="[ overflow-auto ] [ popup-modal__body ]">
              <div className="[ popup-modal-message__sub-title ]">
                {/* CHRIS: This should be the category the product belongs to */}
                {subtitle}

                {recommendProduct && (
                  <div className="[ recommended_product ]">
                    <h4>{MODALMESSAGES.RECOMMEND.POPUP_SUBTITLE}</h4>
                  </div>
                )}
              </div>
              {recommendProduct &&
                this.getRecommendProductMessage(recommendProduct, productList)}

              {requiredList &&
                this.getRequiredProductMessage(requiredList, productList)}

              {!seeDetailsClick && !recommendProduct && !requiredList && (
                <div
                  className={
                    modalType === AppConstants.MODALTYPE.CUSTOM
                      ? "[ popup-modal-custom-message__title ]"
                      : "[ popup-modal-message__title ]"
                  }
                >
                  {modalType === AppConstants.MODALTYPE.CUSTOM ? (
                    shortDescription
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  )}
                </div>
              )}

              <div
                className={
                  modalType === AppConstants.MODALTYPE.CUSTOM && seeDetailsClick
                    ? "[ popup-modal-custom-message__description ]"
                    : "[ popup-modal-message__description ]"
                }
              >
                {seeDetailsClick && (
                  <div className="[ row ]">
                    <div className="[ col-md-4 ] [ product-icon-container__img-container ]">
                      <img
                        src={`${PRODUCT_IMAGE_URL}${
                          productLogo ? productLogo : product.productLogo
                        }`}
                        className="[ w-100 ] [ product-icon-container__img ]"
                        alt="Image associated with the product"
                      />
                    </div>
                    <div className="[ col-md-8 ] [ popup-modal-message__description-container ]">
                      {description}
                      {description2 && (
                        <div className="[ popup-modal-message__description ]">
                          {description2}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!seeDetailsClick && (
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      {requiredList && (
                        <p>{MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_MSG_3}</p>
                      )}
                    </div>
                    {description && !requiredList && !recommendProduct && (
                      <div className="[ col-12 ]">
                        {typeof description === "object" && (
                          <p>{description}</p>
                        )}
                        {typeof description !== "object" && (
                          <p
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        )}
                      </div>
                    )}
                    {description2 && !requiredList && !recommendProduct && (
                      <div className="[ col-12 ]">
                        <p>{description2}</p>
                      </div>
                    )}
                    {description3 && !requiredList && !recommendProduct && (
                      <div className="[ col-12 ]">
                        <p>{description3}</p>
                      </div>
                    )}
                  </div>
                )}

                {slots?.data &&
                  slots?.data.length > 1 &&
                  product.applicationId === slots.applicationId && (
                    <>
                      <hr />
                      <div className="[ details-section ]">
                        <div className="[ row ] [ details-section__header">
                          <div className="[ col-12 ]">
                            <h3 className="[ form-subtitle ]">
                              {STRINGS.JOINT_APPLICANT_STATUS.TITLE}
                            </h3>
                          </div>
                        </div>
                      </div>

                      {slots.data.map((slot, idx) => (
                        <>
                          {idx > 0 && (
                            <div className="[ details-section ] [ summary-tab ] [ mb-3 ]">
                              <div className="[ row ] [ details-section__item ]">
                                <div className="[ col-12 ]">
                                  <div className="[ details-section__item-label ]">
                                    CO-APPLICANT NO. {idx}
                                  </div>
                                </div>
                              </div>
                              <div className="[ row ] [ details-section__item ]">
                                <div className="[ col-sm-6 ]">
                                  <div className="[ details-section__item-label ]">
                                    {STRINGS.JOINT_APPLICANT_STATUS.EMAIL_LABEL}
                                  </div>
                                </div>
                                <div className="[ col-sm-6 ]">
                                  <div className="[ details-section__item-description ] [ details-section__item-description-nocap ]">
                                    {slot.extra.email}
                                  </div>
                                </div>
                              </div>
                              <div className="[ row ] [ details-section__item ]">
                                <div className="[ col-sm-6 ]">
                                  <div className="[ details-section__item-label ]">
                                    {
                                      STRINGS.JOINT_APPLICANT_STATUS
                                        .MOBILENO_LABEL
                                    }
                                  </div>
                                </div>
                                <div className="[ col-sm-6 ]">
                                  <div className="[ details-section__item-description ]">
                                    {formatPhoneNumber(
                                      slot.extra.msisdn.substring(1)
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="[ row ] [ details-section__item ]">
                                <div className="[ col-sm-6 ]">
                                  <div className="[ details-section__item-label ]">
                                    {
                                      STRINGS.JOINT_APPLICANT_STATUS
                                        .APPLICANT_TYPE_LABEL
                                    }
                                  </div>
                                </div>
                                <div className="[ col-sm-6 ]">
                                  <div className="[ details-section__item-description ]">
                                    {getApplicantType(
                                      applicantTypes,
                                      slot.joinInfo.joinType
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="[ row ] [ details-section__item ]">
                                <div className="[ col-sm-6 ]">
                                  <div className="[ details-section__item-label ]">
                                    {
                                      STRINGS.JOINT_APPLICANT_STATUS
                                        .APPLICANT_TYPE_STATUS
                                    }
                                  </div>
                                </div>
                                <div className="[ col-sm-6 ]">
                                  <div className="[ details-section__item-description ]">
                                    {getApplicantStatus(slot)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </>
                  )}

                {/* CHRIS: ACCOUNT PRODUCT STATUS AND FUNDING BLOCK START 
NOTE: This block only shows when the application for an account product opening is successfully pushed to core banking in which the account info is relayed to us, and the user has been exposed to paysafe.
See 'if' comments within code block. 
This block will only be seen when 'see details' link is clicked on the product card 1) when product card is in the 'applications completed' section and not the cart 3) whe product is a fundable account type that has successfully uploaded to core banking*/}
                {true === false && (
                  <>
                    <div className="[ mb-3 ]">
                      <div className="[ form-subtitle ]">
                        Account Information
                      </div>
                      <div className="[ details-section ] [ summary-tab ]">
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Account status:
                          </div>
                          <div className="[ details-section__item-description ]">
                            Active
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Transit no.:
                          </div>
                          <div className="[ details-section__item-description ]">
                            03680
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Institution no.:
                          </div>
                          <div className="[ details-section__item-description ]">
                            809
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Full account no.:
                          </div>
                          <div className="[ details-section__item-description ]">
                            100011303999
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            *Initial deposit:
                          </div>
                          <div className="[ details-section__item-description ]">
                            $ 50.00
                          </div>
                        </div>

                        {/* HIDE this 'row' if initial deposit is $0 */}
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Funding method:
                          </div>
                          <div className="[ details-section__item-description ]">
                            CREDIT CARD **** 0789
                          </div>
                        </div>

                        {/* HIDE this 'row' if initial deposit is $0 */}
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Date and time:
                          </div>
                          <div className="[ details-section__item-description ]">
                            2021-05-26 13:55:55
                          </div>
                        </div>

                        {/* HIDE this 'row' if initial deposit is $0 */}
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            **PaySafe&trade; transaction ref no.:
                          </div>
                          <div className="[ details-section__item-description ]">
                            ADBD38392DJ
                          </div>
                        </div>

                        <div className="[ row ] [ mt-3 ]">
                          <div className="[ col-12 ]">
                            <small className="[ footnotes form-text text-muted ]">
                              *Your initial deposit is the amount you deposited
                              when you created the account. This is not an
                              account balance.
                            </small>
                          </div>

                          {/* HIDE this 'col' if initial deposit is $0 */}
                          <div className="[ col-12 ]">
                            <small className="[ footnotes form-text text-muted ]">
                              **Deposit transactions are facilitated via
                              PaySafe&trade;. Should you have a question about
                              your deposit, or need assistance, use the
                              PaySafe&trade; transaction reference above when
                              speaking with customer service.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
              </div>

              {/* CHRIS: END...ACCOUNT PRODUCT STATUS AND FUNDING BLOCK */}
            </Modal.Body>
          )}

          {!showPopupModalButtons &&
            showPopupHeaderCloseButton &&
            showPopupFooterActionButtons &&
            popupFooterActionContent && (
              <>
                <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
                  <div className="[ mb-3 ]">
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div>{popupFooterActionContent}</div>
                      </div>
                    </div>
                  </div>
                </Modal.Footer>
              </>
            )}

          {showPopupModalButtons && (
            <>
              {ageRestriction && (
                <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
                  <>
                    <div className="[ mb-3 ]">
                      <div className="[ row ]">
                        <div className="[ col-12 ]">
                          <div>
                            <button
                              type="button"
                              className="[ btn submit-btn ] [ w-100 ]"
                              onClick={() => ageRestriction()}
                            >
                              {MODALMESSAGES.PRODUCTREQUIRED.HELP_ME_CHOOSE}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </Modal.Footer>
              )}
              {requiredList && (
                <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
                  <>
                    <div className="[ mb-3 ]">
                      <div className="[ row ]">
                        <div className="[ col-12 ]">
                          <div id={requiredList[0]?.default}>
                            <button
                              type="button"
                              className="[ btn submit-btn ] [ w-100 ]"
                              onClick={() =>
                                addProduct(requiredList[0]?.default)
                              }
                            >
                              {
                                MODALMESSAGES.PRODUCTREQUIRED
                                  .REQUIRED_BUTTON_PROCEED
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </Modal.Footer>
              )}

              {/* CHRIS - PROMO RECOMMENDED PRODUCT FOOTER AND CTAs */}
              {recommendProduct && (
                <Modal.Footer className="[ popup-modal-footer ] [ d-block ]">
                  <>
                    <div className="[ mb-3 ]">
                      <div className="[ row ]">
                        <div className="[ col-12 ]">
                          <div>
                            <button
                              type="button"
                              className="[ btn submit-btn ] [ w-100 ]"
                              onClick={() => addProduct(recommendProduct)}
                            >
                              {MODALMESSAGES.RECOMMEND.RECOMMEND_BUTTON_PROCEED}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="[ mb-3 ]">
                      <div className="[ row ]">
                        <div className="[ col-12 text-center ]">
                          <div>
                            <p>
                              <span
                                className="[ link ]"
                                role="presentation"
                                onClick={() => {
                                  this.handleClose();
                                  toggleModal();
                                  popupBtnClick();
                                }}
                              >
                                {MODALMESSAGES.RECOMMEND.RECOMMEND_BUTTON_SKIP}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </Modal.Footer>
              )}

              {iframeModal ? (
                <Modal.Footer className="[ iframe-popup-modal-footer ]">
                  <div className="[ iframe-popup-modal-footer__text ]">
                    {footer}
                  </div>
                </Modal.Footer>
              ) : (
                <Modal.Footer className="[ popup-modal-footer ]">
                  {customBtnText && (
                    <button
                      type="button"
                      onClick={() => {
                        this.handleClose();
                        toggleModal();
                        customBtnClick();
                      }}
                      className="[ btn submit-btn ] [ w-100 ]"
                    >
                      Continue Logout
                    </button>
                  )}
                  {addToVault && !requiredList && !recommendProduct && (
                    <button
                      type="button"
                      onClick={() => {
                        this.handleClose();
                        toggleModal();
                        popupBtnClick();
                      }}
                      className="[ btn submit-btn ] [ w-100 ]"
                    >
                      {btnText}
                    </button>
                  )}
                </Modal.Footer>
              )}
            </>
          )}
        </Modal>
      </>
    );
  }
}

PopupModal.propTypes = {
  showModal: PropTypes.bool,
  type: PropTypes.string,
  modalType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  btnText: PropTypes.string,
  toggleModal: PropTypes.func,
  popupBtnClick: PropTypes.func,
  closeBtnClick: PropTypes.func,
  showPopupModalButtons: PropTypes.bool,
  showPopupFooterActionButtons: PropTypes.bool,
  showPopupHeaderCloseButton: PropTypes.bool,
  iframeModal: PropTypes.bool,
  iframeUrl: PropTypes.string,
  footer: PropTypes.string,
  subtitle: PropTypes.string,
  addToVault: PropTypes.bool,
  tag: PropTypes.bool,
  seeDetailsClick: PropTypes.bool,
  shortDescription: PropTypes.string,
  customBtnClick: PropTypes.func,
  customBtnText: PropTypes.string,
  product: PropTypes.oneOfType([PropTypes.object]),
  popupFooterActionContent: PropTypes.node,
};

PopupModal.defaultProps = {
  showModal: false,
  type: "success",
  modalType: "",
  title: "",
  description: "",
  btnText: "OK",
  toggleModal: () => {},
  popupBtnClick: () => {},
  closeBtnClick: () => {},
  showPopupModalButtons: true,
  showPopupFooterActionButtons: false,
  showPopupHeaderCloseButton: false,
  iframeModal: false,
  iframeUrl: "",
  footer: "",
  subtitle: "",
  addToVault: true,
  tag: false,
  seeDetailsClick: false,
  shortDescription: "",
  product: {},
  customBtnClick: () => {},
  customBtnText: "",
  popupFooterActionContent: null,
};

export default withRouter(PopupModal);
