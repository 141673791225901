import * as actionTypes from "Redux/Actions/actionTypes";

const doLogout = (callback, data) => {
  return {
    type: actionTypes.LOGOUT_REQUEST,
    callback,
    data: data,
  };
};

export default doLogout;
