/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import * as ROUTES from "Constants/Routes";
import {
  TOKEN_KEY,
  HTTP_STATUS,
  S_APPLICANT_ID,
  EXISTING_OR_SAME_PRODUCT_MESSAGE,
  SORT_ON_PRIORITY,
} from "Communication/Constants";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import MODALMESSAGES from "Constants/Messages";
import { sortList } from "Utils/CommonUtilities";

import {
  updateVaultProductList,
  updateProductToVault,
  getProductFromVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import doLogout from "Redux/Actions/LogoutAction";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { deleteApplication } from "Redux/Actions/ApplicationAction";
import { doGetApplicantList } from "Redux/Actions/GetApplicantListAction";
import getAccount from "Redux/Actions/GetAccountAction";
import { getGlobalVault } from "Components/JointApplicant/Actions/JointApplicantAction";
import { getProductTemplate } from "../../Redux/Actions/ProductTemplateActions";
import {
  disableBack,
  getApplicant,
  getApplicationList,
  toggleEditFlag,
  toggleScanFlag,
} from "../../Redux/Actions/ApplicationAction";
import {
  getActiveProduct,
  getProductInfo,
  searchCategoryInCompleteList,
  searchProductInCompleteList,
} from "../../Utils/CommonUtilities";
import { LENDING_PRODUCT, S_BANKING_TYPE } from "../../Communication/Constants";
import { isNewMember } from "../../Utils/LogicUtilities";
import { submitQuestionnaire } from "../../Components/Questionnaire/Actions/QuestionnaireAction";
import {
  getApplicationProperty,
  setInSessionJointApplicant,
} from "../../Components/JointApplicant/Actions/JointApplicantAction";

export default function withVaultHOC(WrappedComponent) {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showModal: false,
        modalType: "",
        nextComponent: "",
        modal: {
          title: "",
          description: "",
        },
        showVaultLimitError: false,
      };
    }

    setModalState = (modal) => {
      this.setState(modal);
    };

    /**
     *
     * @param  selectedProduct product to delete from vault
     * If we have access token then
     *  1)Get vaultProductList from productVault from server.
     *  2)Remove selectedProduct from  vaultProductList
     *  3)Update productVault at server side with new updated vaultProductList
     *  4)If selectedProduct has applicationId then delete that application
     *  5)If selectProduct has active flag true means that it is first product in list the redirect to Dashboard
     * else if we dont have access token
     *   1)Remove selectedProduct from  vaultProductList
     *   2)Update vaultProductList into VaultReducer
     */

    deleteApplication = (selectedProduct) => {
      const accessToken = sessionStorage.getItem(TOKEN_KEY);
      const { vaultProductList, history } = this.props;
      const {
        doUpdateVaultProductList,
        doUpdateProductToVault,
        doDeleteApplication,
        doGetProductFromVault,
        doGetApplicantList,
        applicationCompleteList,
      } = this.props;

      if (accessToken) {
        let applicationProduct = null;
        doGetProductFromVault((getProductFromVaultResponse) => {
          if (
            getProductFromVaultResponse &&
            getProductFromVaultResponse.status === HTTP_STATUS.OK
          ) {
            const resultIndex = this.getArrayIndexOfProduct(
              getProductFromVaultResponse.data,
              selectedProduct
            );
            applicationProduct = {
              ...getProductFromVaultResponse.data[resultIndex],
            };
            const updatedVaultProductList = this.removeSelectedProductFromList(
              getProductFromVaultResponse.data,
              selectedProduct
            );
            console.log("update vault 6");
            doUpdateProductToVault(
              updatedVaultProductList,
              (udateProductToVaultResponse) => {
                if (
                  udateProductToVaultResponse &&
                  udateProductToVaultResponse.status === HTTP_STATUS.OK
                ) {
                  const completedProduct = applicationCompleteList.find(
                    (x) => x.id === applicationProduct.applicationId
                  );
                  console.log("completedProduct", completedProduct);
                  if (
                    applicationProduct.applicationId &&
                    !applicationProduct.jointExternalApplicationId &&
                    (!completedProduct || !completedProduct?.uploadedAt)
                  ) {
                    doDeleteApplication(
                      applicationProduct.applicationId,
                      () => {
                        doGetApplicantList(() => {
                          if (applicationProduct.active) {
                            history.push(ROUTES.VAULT_DASHBOARD);
                          }
                        });
                      }
                    );
                  } else if (applicationProduct.active) {
                    history.push(ROUTES.VAULT_DASHBOARD);
                  }
                }
              }
            );
          }
        });
      } else {
        const updatedVaultProductList = this.removeSelectedProductFromList(
          vaultProductList,
          selectedProduct
        );
        doUpdateVaultProductList(updatedVaultProductList);
      }
    };

    /**
     * Helper method
     */
    getArrayIndexOfProduct = (products, selectedProduct) => {
      const index = products.findIndex(
        (product) => product.productIndex === selectedProduct.productIndex
      );
      return index;
    };

    /**
     * Helper method
     */
    removeSelectedProductFromList = (products, selectedProduct) => {
      const updatedVaultProductList = products.filter((product) => {
        return product.productIndex !== selectedProduct.productIndex;
      });
      return updatedVaultProductList;
    };

    toggleModal = () => {
      this.setState({
        showModal: false,
        showRequired: false,
      });
    };

    goToMarketPlace = () => {
      console.log("goToMarketPlace");
      this.setState(
        {
          showModal: false,
          showRequired: false,
        },
        () => {
          const { history } = this.props;
          history.push(`${ROUTES.VAULT_DASHBOARD}?marketplace`);
          window.location.reload();
        }
      );
    };

    /**
     * @param  isLogin Is true if call from login page and false if call from register page
     * Merged previous session and ongoing session products.
     * Update new vaultProductList to productVault at server side.
     * If user dont have products in vault then redirect to Dashboard
     * If user have only products in ongoing session then get applicant List
     * If user have products in both session or only in previous session then generate appropriate popup message
     */
    prepareVaultProductList = (isLogin, authCode, productId, isQrKyc) => {
      const {
        doGetProductFromVault,
        doUpdateProductToVault,
        history,
        vaultProductList,
        doGetAccountAction,
        doGetGlobalVault,
        processTemplate,
        doGetProductTemplate,
        applicantData,
        doSubmitQuestionnaire,
        doSetInSessionJointApplicant,
        doGetApplicationProperty,
        doGetApplicant,
      } = this.props;
      console.log(
        "prepareVaultProductList",
        isLogin,
        vaultProductList,
        productId
      );
      const bankingType = sessionStorage.getItem(S_BANKING_TYPE);
      const currentSessionVaultProductList = [...vaultProductList];
      let lastSessionVaultProductList = [];
      let updatedVaultProductList = [];
      doGetProductFromVault((getProductFromVaultResponse) => {
        if (
          getProductFromVaultResponse &&
          getProductFromVaultResponse.status === HTTP_STATUS.OK
        ) {
          updatedVaultProductList.push(
            ...vaultProductList.filter(
              (item) => item.bankingType === bankingType
            )
          );
          if (isLogin) {
            lastSessionVaultProductList = getProductFromVaultResponse.data
              ? getProductFromVaultResponse.data
              : [];
            console.log(
              "lastSessionVaultProductList",
              lastSessionVaultProductList
            );
            if (authCode) {
              if (lastSessionVaultProductList?.length > 0) {
                sessionStorage.setItem(
                  AppConstants.SESSION.CENTRAL_MESSAGE,
                  MODALMESSAGES.SIGNIN.SOMEPRODUCTS
                );
              } else {
                sessionStorage.setItem(
                  AppConstants.SESSION.CENTRAL_MESSAGE,
                  MODALMESSAGES.CENTRAL.MSG
                );
              }
            }
            if (productId) {
              const { productList, loanProducts } = this.props;
              const selectedCentralProduct = getProductInfo(
                productId,
                productList
              );
              console.log("selectedCentralProduct", selectedCentralProduct);
              if (selectedCentralProduct) {
                const product = {
                  ...selectedCentralProduct,
                  productDetails: null,
                  active: false,
                  applicantId: null,
                  applicationId: null,
                  applicationStep: {
                    index: null,
                    step: "",
                  },
                };
                const rand = Math.floor(Math.random() * 1000);
                product.productIndex = rand;

                if (product.type === LENDING_PRODUCT) {
                  const loanProduct = loanProducts.find(
                    (item) => product.productId === item.id
                  );
                  product.category = loanProduct.category;
                  product.subCategory = loanProduct.subCategory;
                  product.productType = loanProduct.productType;
                  product.productCode = loanProduct.productCode;
                  product.cutoff = loanProduct.cutoff;
                  product.applicationType = loanProduct.applicationType;
                } else {
                  product.category = "";
                  product.subCategory = "";
                }
                lastSessionVaultProductList.push(product);
              }
            }
            for (const item of lastSessionVaultProductList) {
              const obj = updatedVaultProductList.find(
                (search) =>
                  search.applicationId === item.applicationId &&
                  search.productId === item.productId
              );
              if (!obj) {
                updatedVaultProductList.push(item);
              }
            }
          }
          updatedVaultProductList = sortList(
            updatedVaultProductList,
            SORT_ON_PRIORITY
          );
          if (updatedVaultProductList.length !== 0) {
            const applicantId = sessionStorage.getItem(S_APPLICANT_ID);
            console.log("update vault 1", applicantId);
            if (updatedVaultProductList[0].questionnaire?.sendAnswers) {
              doSubmitQuestionnaire(updatedVaultProductList[0].questionnaire);
            }
            if (applicantId) {
              for (let x = 0; x < updatedVaultProductList.length; x++) {
                updatedVaultProductList[x].applicantId = applicantId;
              }
            }
            doUpdateProductToVault(
              updatedVaultProductList,
              (updateProductToVaultResponse) => {
                if (updateProductToVaultResponse.status !== HTTP_STATUS.OK) {
                  this.setRedirectpage(
                    null,
                    AppConstants.MODALTYPE.FAILURE,
                    MODALMESSAGES.SIGNIN.FAILURE
                  );
                }
              }
            );
          }
        }
        if (isQrKyc) {
          if (getProductFromVaultResponse.data[0].inSessionJointApplicant) {
            doSetInSessionJointApplicant(
              getProductFromVaultResponse.data[0].inSessionJointApplicant
            );
            doGetApplicant(
              { id: getProductFromVaultResponse.data[0].applicantId },
              () => {
                doGetApplicationProperty(
                  {
                    applicationId:
                      getProductFromVaultResponse.data[0].applicationId,
                  },
                  () => {
                    history.push(ROUTES.ABOUT_YOU);
                  }
                );
              }
            );
          } else {
            history.push(ROUTES.ABOUT_YOU);
          }
        } else {
          if (
            lastSessionVaultProductList.length === 0 &&
            currentSessionVaultProductList.length !== 0
          ) {
            this.callGetApplicantList(updatedVaultProductList);
          } else {
            if (lastSessionVaultProductList.length !== 0 && !productId) {
              this.checkExistingProducts(
                currentSessionVaultProductList,
                lastSessionVaultProductList
              );
            }
            // TODO what if there is multiple joint initiated for the same applicant
            const jointApplicant = sessionStorage.getItem(
              STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
            );

            if (jointApplicant && jointApplicant !== "") {
              const jointApp = jointApplicant.split("-");
              const slotId = jointApp[0];
              const type = jointApp[1];

              doGetAccountAction((getAccountActionRes) => {
                if (getAccountActionRes.status === HTTP_STATUS.OK) {
                  const mobileNo = getAccountActionRes.data.msisdn;
                  const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${mobileNo}`;
                  doGetGlobalVault({ vaultKey: vKey }, (gobalVaultRes) => {
                    if (
                      gobalVaultRes.vaultProducts &&
                      gobalVaultRes.vaultProducts.length > 0
                    ) {
                      const { vaultProducts } = gobalVaultRes;
                      const jointApplicationId = sessionStorage.getItem(
                        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
                      );
                      // TODO we need to check if the invitation was already processed
                      const applicantId = sessionStorage.getItem(
                        S_APPLICANT_ID
                      );
                      vaultProducts[0].applicationId = jointApplicationId;
                      vaultProducts[0].active = false;
                      vaultProducts[0].slotId = slotId;
                      vaultProducts[0].jointExternalApplicationId = jointApplicationId;
                      vaultProducts[0].jointType = type;
                      vaultProducts[0].applicantId = applicantId;
                      updatedVaultProductList.push(vaultProducts[0]);
                      console.log("update vault 2");
                      doUpdateProductToVault(
                        updatedVaultProductList,
                        (prodVaultRes) => {
                          console.log(prodVaultRes);
                          sessionStorage.removeItem(
                            STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
                          );
                          if (applicantData?.id) {
                            history.push(ROUTES.VAULT_DASHBOARD);
                          } else {
                            sessionStorage.setItem(
                              EXISTING_OR_SAME_PRODUCT_MESSAGE,
                              MODALMESSAGES.SIGNIN.SOMEPRODUCTS
                            );
                            if (processTemplate?.flow.length == 0) {
                              doGetProductTemplate(() => {
                                history.push(ROUTES.VAULT_DASHBOARD);
                                // start processing application
                                // history.push(ROUTES.EXISTING_MEMBER);
                              }, vaultProducts[0]);
                            } else {
                              history.push(ROUTES.VAULT_DASHBOARD);
                              // start processing application
                              // history.push(ROUTES.EXISTING_MEMBER);
                            }
                          }
                        }
                      );
                    }
                  });
                }
              });
            } else {
              console.log("going to vault dashboard");
              history.push(ROUTES.VAULT_DASHBOARD);
            }
          }
        }
      });
    };

    /**
     * @param  currentSessionVaultProductList list contains on going session products
     * @param  lastSessionVaultProductList list contains previous session products
     * Generate popup message by comparing products from both vault productList
     *
     */
    checkExistingProducts = (
      currentSessionVaultProductList,
      lastSessionVaultProductList
    ) => {
      let message = MODALMESSAGES.SIGNIN.SOMEPRODUCTS;
      if (
        lastSessionVaultProductList &&
        Array.isArray(lastSessionVaultProductList)
      ) {
        lastSessionVaultProductList.forEach((oldProduct) =>
          currentSessionVaultProductList.forEach((newProduct) => {
            if (newProduct.productId === oldProduct.productId) {
              message = MODALMESSAGES.SIGNIN.SAMEPRODUCTS;
            }
          })
        );
      }
      sessionStorage.setItem(EXISTING_OR_SAME_PRODUCT_MESSAGE, message);
    };

    /**
     * Fetch applicantId from server side.
     * If get applicantId redirect to EXISTING_MEMBER
     * else get account details.
     */
    callGetApplicantList = (currentVaultProductList) => {
      console.log("callGetApplicantList", currentVaultProductList);
      const {
        doGetApplicantListAction,
        bankingType,
        vaultProductList,
        finInfo,
      } = this.props;

      let activeProduct = getActiveProduct(vaultProductList);
      if (!!currentVaultProductList) {
        activeProduct = getActiveProduct(currentVaultProductList);
      }

      doGetApplicantListAction((GetApplicantListActionResponse) => {
        if (GetApplicantListActionResponse.status === HTTP_STATUS.OK) {
          console.log("call get banking type", bankingType);
          if (GetApplicantListActionResponse.data.length === 0) {
            if (bankingType === AppConstants.BANKING_TYPE.BUSINESS) {
              this.setRedirectpage(ROUTES.BUSINESS_EXIST, null, null);
            } else {
              this.setRedirectpage(ROUTES.EXISTING_MEMBER, null, null);
            }
          } else {
            // get first applicantID from the list
            let selfApplication = GetApplicantListActionResponse.data.find(
              (app) => app.relationship === AppConstants.RELATIONSHIP.SELF
            );
            let getFirstApplicantID = selfApplication.id;
            if (finInfo.jointMembership) {
              selfApplication = GetApplicantListActionResponse.data.filter(
                (app) => app.relationship === AppConstants.RELATIONSHIP.SELF
              );
              getFirstApplicantID =
                selfApplication[selfApplication.length - 1]?.id;
            }
            sessionStorage.setItem(S_APPLICANT_ID, getFirstApplicantID);
            this.getAccountDetails(
              GetApplicantListActionResponse,
              selfApplication.applicationIds
            );
          }
        }
      });
    };

    getAccountDetails = (callback, applicationIds = () => {}) => {
      console.log("getAccountDetails", applicationIds);
      // call getaccount api to check terms and condition accepted or not
      const {
        doGetAccountAction,
        sessionCompletedProducts,
        applicantData,
        applicationCompleteList,
        doGetApplicantList,
        vaultProductList,
        bankingType,
      } = this.props;
      const activeProduct = getActiveProduct(vaultProductList);
      if (sessionCompletedProducts && sessionCompletedProducts.length <= 0) {
        if (
          applicantData?.acquireType === "search" ||
          applicationCompleteList?.length > 0 ||
          (applicationIds && applicationIds.length > 0)
        ) {
          if (
            activeProduct?.bankingType === AppConstants.BANKING_TYPE.BUSINESS
          ) {
            this.setRedirectpage(ROUTES.BUSINESS_COMPLIANCE, null, null);
          } else {
            this.setRedirectpage(ROUTES.ABOUT_YOU, null, null);
          }
        } else {
          doGetAccountAction((getAccountActionResponse) => {
            if (
              activeProduct?.bankingType === AppConstants.BANKING_TYPE.BUSINESS
            ) {
              if (activeProduct.applicantId) {
                this.setRedirectpage(ROUTES.BUSINESS_COMPLIANCE, null, null);
              } else {
                this.setRedirectpage(ROUTES.BUSINESS_EXIST, null, null);
              }
            } else if (activeProduct.applicantId) {
              this.setRedirectpage(ROUTES.ABOUT_YOU, null, null);
            } else if (getAccountActionResponse.data.consent === null) {
              this.setRedirectpage(ROUTES.MEMBER_CREATION, null, null);
            } else {
              this.setRedirectpage(ROUTES.GETTING_STARTED, null, null);
            }
          });
        }
      } else {
        console.log("doing a callback", callback);
        callback();
      }
    };

    goToNextStep = (vaultProductList, callback) => {
      const {
        doGetProductTemplate,
        history,
        bankingType,
        applicantData,
        finInfo,
      } = this.props;
      console.log("goToNextStep", vaultProductList, applicantData);
      const activeProduct = getActiveProduct(vaultProductList);
      console.log("activeProduct", activeProduct);
      if (activeProduct.applicationStep.index) {
        // zero value result to false
        doGetProductTemplate((response) => {
          console.log(response);
          let currentTemplate = response.find(
            (template) => template.templateId === activeProduct.templateId
          );
          if (applicantData?.isSoleProprietor) {
            const soleTemplateId = finInfo.config.businessTypes?.find(
              (x) => x.code === finInfo.soleBusinessType
            )?.templateId;
            console.log("soleTemplateId", soleTemplateId);
            if (soleTemplateId) {
              currentTemplate = response.find(
                (template) => template.templateId === soleTemplateId
              );
            }
          }
          console.log("currentTemplate", currentTemplate);
          const currentStep = currentTemplate.flow.find(
            (step) => step.index === activeProduct.applicationStep.index
          );
          console.log("currentStep", currentStep);
          if (
            AppConstants.COMPONENTSTEPTYPE.APPLICATION ===
            currentStep.componentType
          ) {
            if (activeProduct.isJointProduct) {
              history.push(ROUTES[AppConstants.APPLICATIONSTEP.CONFIRM]);
            } else {
              let nextStep = currentTemplate.flow.find(
                (step) => step.index === activeProduct.applicationStep.index + 1
              );
              if (nextStep) {
                if (
                  activeProduct.hasExistingContract &&
                  (nextStep.componentName ===
                    AppConstants.APPLICATIONSTEP.BENEFICIARIES ||
                    nextStep.componentName ===
                      AppConstants.APPLICATIONSTEP.SPOUSAL_INFO)
                ) {
                  nextStep = currentTemplate.flow.find(
                    (step) =>
                      step.index === activeProduct.applicationStep.index + 2
                  );
                  if (nextStep) {
                    history.push(ROUTES[nextStep.componentName]);
                  } else {
                    history.push(ROUTES[currentStep.componentName]);
                  }
                } else {
                  history.push(ROUTES[nextStep.componentName]);
                }
              } else {
                history.push(ROUTES[currentStep.componentName]);
              }
            }
          } else {
            const nextStep = currentTemplate.flow.find(
              (step) => step.index === activeProduct.applicationStep.index + 1
            );
            console.log("nextStep", nextStep);
            if (
              activeProduct.isJointProduct &&
              nextStep.componentType ===
                AppConstants.COMPONENTSTEPTYPE.APPLICATION
            ) {
              // check if debit is on some next step
              const debitStep = currentTemplate.flow.find(
                (step) =>
                  step.index > activeProduct.applicationStep.index + 1 &&
                  step.componentName === AppConstants.APPLICATIONSTEP.DEBIT_CARD
              );
              if (debitStep) {
                history.push(ROUTES[debitStep.componentName]);
              } else {
                history.push(ROUTES[AppConstants.APPLICATIONSTEP.CONFIRM]);
              }
            } else {
              history.push(ROUTES[nextStep.componentName]);
            }
          }
        }, activeProduct);
      } else {
        if (sessionStorage.getItem(S_APPLICANT_ID)) {
          this.getAccountDetails(callback);
        } else {
          doGetProductTemplate(() => {
            this.callGetApplicantList();
          }, activeProduct);
        }
      }
    };

    proceedWithApplication = (callback = () => {}) => {
      console.log("proceedWithApplication", callback);
      const {
        history,
        doGetProductTemplate,
        processTemplate,
        applicantData,
        applicationCompleteList,
        productList,
        doToggleEdit,
        doSubmitQuestionnaire,
        bankingType,
        doDisableBack,
      } = this.props;
      console.log("proceedWithApplication");

      const accessToken = sessionStorage.getItem(TOKEN_KEY);
      doToggleEdit(false);
      doDisableBack(false);
      if (accessToken) {
        const { doUpdateProductToVault, doGetProductFromVault } = this.props;
        doGetProductFromVault((getProductFromVaultResponse) => {
          if (
            getProductFromVaultResponse &&
            getProductFromVaultResponse.status === HTTP_STATUS.OK
          ) {
            if (
              getProductFromVaultResponse.data &&
              getProductFromVaultResponse.data.length !== 0
            ) {
              const vaultProductList = getProductFromVaultResponse.data;
              const activeProduct = getActiveProduct(
                vaultProductList,
                bankingType
              );
              console.log("active product", activeProduct);
              let requireProductMissing = false;
              if (applicantData && isNewMember(applicantData)) {
                if (
                  activeProduct.required &&
                  activeProduct.required.length > 0
                ) {
                  const requiredList = [];
                  let oneRequiredProductFound = false;
                  activeProduct.required.forEach((req) => {
                    const product = searchCategoryInCompleteList(
                      req.category,
                      productList,
                      applicationCompleteList
                    );
                    if (product) {
                      oneRequiredProductFound = true;
                    } else {
                      requiredList.push(req);
                    }
                  });
                  if (!oneRequiredProductFound && requiredList.length > 0) {
                    requireProductMissing = true;
                    this.setState({
                      showRequired: true,
                      requiredList,
                      modal: {
                        title: MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_PRODUCT,
                        description: MODALMESSAGES.PRODUCTREQUIRED.REQUIRED_MSG,
                      },
                    });
                  }
                }
              }

              if (!requireProductMissing) {
                vaultProductList[
                  vaultProductList.findIndex((x) => x === activeProduct)
                ].active = true;
                for (let i = 0; i < vaultProductList.length; i += 1) {
                  if (!vaultProductList[i].applicantId) {
                    vaultProductList[i].applicantId = sessionStorage.getItem(
                      S_APPLICANT_ID
                    );
                  }
                }
                console.log("update vault 3", vaultProductList[0]);
                if (vaultProductList[0].questionnaire?.sendAnswers) {
                  doSubmitQuestionnaire(vaultProductList[0].questionnaire);
                }
                doUpdateProductToVault(vaultProductList, () => {
                  console.log("doGetProductTemplate", processTemplate);
                  if (processTemplate?.flow.length == 0) {
                    doGetProductTemplate((res) => {
                      this.goToNextStep(vaultProductList, callback);
                    }, vaultProductList[0]);
                  } else {
                    this.goToNextStep(vaultProductList, callback);
                  }
                });
              }
            }
          }
        });
      } else {
        history.push(ROUTES.SIGN_IN);
      }
    };

    setRedirectpage = (redirectUrl, type, title) => {
      const { forgotPasswordState, history } = this.props;
      if (type === null) {
        if (forgotPasswordState && forgotPasswordState.success) {
          this.setModalState({
            showModal: true,
            modalType: AppConstants.MODALTYPE.SUCCESS,
            modal: {
              title:
                forgotPasswordState.success.status === HTTP_STATUS.OK
                  ? MODALMESSAGES.RECOVERPASSWORD.SUCCESSMSG
                  : MODALMESSAGES.SETPASSWORD.SUCCESSMSG,
              description: "",
            },
            nextComponent:
              forgotPasswordState.success.status === HTTP_STATUS.OK
                ? ROUTES.SIGN_IN
                : redirectUrl,
          });
        } else {
          history.push(redirectUrl);
        }
      } else {
        this.setModalState({
          showModal: true,
          modalType: type,
          modal: {
            title,
            description: "",
          },
          nextComponent: ROUTES.VAULT_DASHBOARD,
        });
      }
    };

    handlePopupModalBtnClick = () => {
      const { history, doLogOutAction } = this.props;
      const { nextComponent } = this.state;
      if (nextComponent === ROUTES.SIGN_IN) {
        doLogOutAction((res) => {
          if (res.status === HTTP_STATUS.OK) {
            history.push(ROUTES.SIGN_IN);
          }
        });
      } else {
        history.push(nextComponent);
      }
    };

    addProduct = (productId) => {
      console.log("addProduct", productId);
      const {
        loanProducts,
        finInfo,
        vaultProductList,
        productList,
        doUpdateProductToVault,
        history,
        doGetProductTemplate,
        processTemplate,
        bankingType,
      } = this.props;
      this.setState({
        showRequired: false,
      });
      if (vaultProductList && vaultProductList.length >= finInfo.vaultLimit) {
        this.setState({
          showVaultLimitError: true,
        });
      }

      const selectedProduct = getProductInfo(productId, productList);
      console.log("selectedProduct", selectedProduct, this.props);
      const product = {
        ...selectedProduct,
        // don't store product details as it makes the data too big
        productDetails: null,
        active: true,
        applicantId: null,
        applicationId: null,
        applicationStep: {
          index: null,
          step: "",
        },
        bankingType,
      };
      const rand = Math.floor(Math.random() * 1000);
      product.productIndex = rand;

      if (product.type === LENDING_PRODUCT) {
        const loanProduct = loanProducts.find(
          (item) => product.productId === item.id
        );
        product.category = loanProduct.category;
        product.subCategory = loanProduct.subCategory;
        product.productType = loanProduct.productType;
        product.productCode = loanProduct.productCode;
        product.cutoff = loanProduct.cutoff;
        product.applicationType = loanProduct.applicationType;
      } else {
        product.category = "";
        product.subCategory = "";
      }

      const newProductList = [product].concat(vaultProductList);
      console.log("newProductList", newProductList);
      if (newProductList.length > 1) {
        newProductList[1].active = false;
      }
      console.log("update vault 4");
      doUpdateProductToVault(newProductList, () => {
        if (processTemplate?.flow.length == 0) {
          doGetProductTemplate(() => {}, newProductList[0]);
        }
        this.goToNextStep(newProductList, {});
      });
    };

    render() {
      const {
        showModal,
        modalType,
        modal,
        showRequired,
        requiredList,
      } = this.state;
      const { productList } = this.props;

      return (
        <>
          {showRequired && (
            <PopupModal
              type={modalType}
              title={modal.title}
              description={modal.description}
              toggleModal={this.goToMarketPlace}
              showModal={showRequired}
              btnText={MODALMESSAGES.PRODUCTREQUIRED.GO_TO_MARKETPLACE}
              popupBtnClick={this.goToMarketPlace}
              requiredList={requiredList}
              productList={productList}
              addProduct={this.addProduct}
            />
          )}
          {showModal && (
            <PopupModal
              type={modalType}
              title={modal.title}
              description={modal.description}
              toggleModal={this.toggleModal}
              showModal={showModal}
              popupBtnClick={this.handlePopupModalBtnClick}
              btnText={STRINGS.POPUPMODAL.OKBUTTON}
            />
          )}
          <WrappedComponent
            deleteApplication={(selectedProduct) =>
              this.deleteApplication(selectedProduct)
            }
            prepareVaultProductList={this.prepareVaultProductList}
            proceedWithApplication={(callback) =>
              this.proceedWithApplication(callback)
            }
            {...this.props}
          />
        </>
      );
    }
  }

  HOC.propTypes = {
    vaultProductList: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.string,
    ]),
    doUpdateVaultProductList: PropTypes.func.isRequired,
    doUpdateProductToVault: PropTypes.func.isRequired,
    doDeleteApplication: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object]),
    doGetProductFromVault: PropTypes.func,
    productList: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.string,
    ]),
    doGetAccountAction: PropTypes.func,
    doGetApplicantListAction: PropTypes.func,
    forgotPasswordState: PropTypes.objectOf(PropTypes.any),
    doLogOutAction: PropTypes.func.isRequired,
    doGetGlobalVault: PropTypes.func.isRequired,
    sessionCompletedProducts: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
  };

  HOC.defaultProps = {
    productList: [],
    vaultProductList: [],
    doGetProductFromVault: () => {},
    doGetAccountAction: () => {},
    doGetApplicantListAction: () => {},
    history: {},
    forgotPasswordState: {},
  };

  const mapStateToProps = (state) => ({
    productList: state.MarketplaceReducer.productList,
    vaultProductList: state.VaultReducer.vaultProductList,
    jointApplicant: state.JoinApplicantReducer.jointApplicant,
    sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
    applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
    applicantData: state.ApplicationReducer.response,
    processTemplate: state.ApplicationReducer.processTemplate,
    loanProducts: state.LoanDetailsReducer.loanProducts,
    finInfo: state.MarketplaceReducer.finInfo,
    bankingType: state.MarketplaceReducer.bankingType,
  });

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        doUpdateVaultProductList: updateVaultProductList,
        doUpdateProductToVault: updateProductToVault,
        doDeleteApplication: deleteApplication,
        doGetProductTemplate: getProductTemplate,
        doGetProductFromVault: getProductFromVault,
        doGetApplicantListAction: doGetApplicantList,
        doGetAccountAction: getAccount,
        doLogOutAction: doLogout,
        doGetGlobalVault: getGlobalVault,
        doGetApplicantList: getApplicationList,
        doToggleEdit: toggleEditFlag,
        doSubmitQuestionnaire: submitQuestionnaire,
        doDisableBack: disableBack,
        doToggleScanFlag: toggleScanFlag,
        doSetInSessionJointApplicant: setInSessionJointApplicant,
        doGetApplicationProperty: getApplicationProperty,
        doGetApplicant: getApplicant,
      },
      dispatch
    );
  };
  return compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HOC);
}
