import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  toggleBackFlag,
  getApplication,
  getContract,
  updateApplication,
  updateContract,
} from "Redux/Actions/ApplicationAction";
import STRINGS from "../../Constants/Strings";
import {
  HTTP_STATUS,
  S_APPLICANT_CIF_ID,
  S_APPLICANT_ID,
  S_APPLICANT_MEMBER_NO,
  S_BANKING_TYPE,
  S_EMAIL,
  S_INSTNT_TXN_ID,
  S_MSISDN,
  S_SSO,
} from "../../Communication/Constants";
import AppConstants from "../../Constants/AppConstants";
import withApplicantHOC from "../../Hoc/ApplicantHOC/ApplicantHOC";
import JointApplicant from "./JointApplicant";
import getAccount from "../../Redux/Actions/GetAccountAction";
import {
  getFIInfo,
  getProductFromVault,
  updateProductToVault,
} from "../MarketPlace/Action/MarketPlaceAction";
import {
  getApplicantType,
  addGlobalVault,
  getApplicationProperty,
  deleteApplicationSlot,
  setInSessionJointApplicant,
  addJointApplicants,
} from "./Actions/JointApplicantAction";
import {
  getProductIdByAge,
  getProductIdInActiveProduct,
  isNewMember,
  showJoint,
} from "../../Utils/LogicUtilities";
import {
  getJointMemberships,
  getSingularMemberships,
  isScanningAllowed,
} from "../../Utils/CommonUtilities";
import Membership from "../Membership/Membership";
import membership from "../Membership/Membership";
import { searchMember } from "../../Redux/Actions/ApplicantAction";
import { deleteApplication } from "../../Redux/Actions/ApplicationAction";
import {
  getProductInformation,
  updateProductInformation,
} from "../../Redux/Actions/ProductInformationAction";
import { getFinancialList } from "../LoanDetails/Actions/LoanDetailsAction";
import PopupModal from "../Common/PopupModal/PopupModal";
import doCheckExistingUser from "../Register/Actions/ExistingUserActions";

class JointApplicantContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      jointAccounts: [],
      jointExistingAccounts: [],
      jointInSession: false,
      inSessionGlobalVault: [],
      ownershipList: [],
      ownership: null,
      survivorList: [],
      survivor: null,
      origUdfList: {},
      ssoLogin: false,
      showSingularMembership: false,
      membership: null,
      jointMembershipSelected: false,
      addJointMembers: false,
      enterJointMembers: false,
      dataIssue: null,
      jointMembership: [],
      singularMembership: [],
      selectedApplicantId: null,
      oldProductInformation: null,
      inviteErrorEncountered: false,
      showError: null,
      showMessage: null,
    };
  }

  componentDidMount() {
    const {
      doGetApplicantType,
      handleBack,
      getActiveProduct,
      products,
      actionBack,
      continuehandler,
      finInfo,
      doGetApplication,
      doGetContract,
      doGetAccountDetails,
      applicantData,
      applicationCompleteList,
      membershipsData,
      doGetFinInfo,
      doSearchMember,
    } = this.props;
    console.log("JointApplicantContainer mount", actionBack, finInfo);
    const sso = sessionStorage.getItem(S_SSO);
    if (sso === "yes") {
      this.setState({ ssoLogin: true });
    }
    const activeProduct = getActiveProduct(products);
    this.setState({
      inviteErrorEncountered: activeProduct.inviteErrorEncountered,
    });
    if (finInfo?.name) {
      if (
        showJoint(
          finInfo,
          applicantData,
          applicationCompleteList,
          activeProduct
        )
      ) {
        if (finInfo.jointMembership) {
          doSearchMember(
            {
              cifId:
                sessionStorage.getItem(S_APPLICANT_CIF_ID) ||
                applicantData.cifId,
            },
            (res) => {
              console.log(res);
            }
          );
        }
        doGetAccountDetails();
        doGetApplicantType();
        this.getJointApplicants(true);
        if (activeProduct.applicationId) {
          doGetContract(
            activeProduct.applicationId,
            activeProduct.type,
            activeProduct.doximProductId,
            null,
            (res) => {
              console.log("contract", res);
              if (res) {
                if (
                  activeProduct.type ===
                  AppConstants.PRODUCT_TYPE.LENDING_PRODUCT
                ) {
                  this.setState({
                    // ownership: res.data.intendedUse,
                    survivor: res.data.lpudfDetails.udf5,
                    origUdfList: res.data.lpudfDetails,
                  });
                } else {
                  this.setState({
                    ownership: res.data.intendedUse,
                    survivor: res.data.udfList?.udf5,
                    origUdfList: res.data.udfList,
                  });
                }
              }
            }
          );
        }
        this.checkProductJoint(activeProduct, finInfo);
      } else if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
      }
    } else {
      doGetFinInfo();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      finInfo,
      products,
      getActiveProduct,
      doGetAccountDetails,
      doGetApplicantType,
      doGetContract,
      applicantData,
      applicationCompleteList,
    } = this.props;
    console.log("componentDidUpdate");
    if (prevProps.finInfo !== finInfo) {
      this.setState({
        ownershipList: finInfo.config?.jointOwnership,
        survivorList: finInfo.config?.jointSurvivorship,
      });
      const activeProduct = getActiveProduct(products);
      if (
        showJoint(
          finInfo,
          applicantData,
          applicationCompleteList,
          activeProduct
        )
      ) {
        doGetAccountDetails();
        doGetApplicantType();
        this.getJointApplicants();
        if (activeProduct.applicationId) {
          doGetContract(
            activeProduct.applicationId,
            activeProduct.type,
            activeProduct.doximProductId,
            null,
            (res) => {
              console.log("contract", res);
              if (res) {
                if (
                  activeProduct.type ===
                  AppConstants.PRODUCT_TYPE.LENDING_PRODUCT
                ) {
                  this.setState({
                    // ownership: res.data.intendedUse,
                    survivor: res.data.lpudfDetails.udf5,
                    origUdfList: res.data.lpudfDetails,
                  });
                } else {
                  this.setState({
                    ownership: res.data.intendedUse,
                    survivor: res.data.udfList?.udf5,
                    origUdfList: res.data.udfList,
                  });
                }
              }
            }
          );
        }
      }
      this.checkProductJoint(activeProduct, finInfo);
    }
  }

  checkProductJoint = (activeProduct, finInfo) => {
    const {
      membershipsData,
      applicationCompleteList,
      applicantData,
    } = this.props;
    console.log("evaluate", finInfo.jointMembership, activeProduct.singleOnly);
    if (finInfo.jointMembership && activeProduct.singleOnly) {
      const singularMembership = getSingularMemberships(
        membershipsData,
        applicationCompleteList,
        applicantData
      );
      if (singularMembership.length > 0) {
        console.log("showing singular", singularMembership);
        this.setState({
          showSingularMembership: true,
          singularMembership,
          page: 2,
        });
      } else {
        if (activeProduct.applicationId) {
          this.processJoints({});
        } else {
          this.handleCreateNewMembership({});
        }
      }
    }
  };

  getJointApplicants = (duringMount) => {
    const {
      doGetApplicationProperty,
      getActiveProduct,
      products,
      finInfo,
      membershipsData,
      applicationCompleteList,
      applicantData,
    } = this.props;
    const { membership } = this.state;
    console.log("getJointApplicants", duringMount);
    const activeProduct = getActiveProduct(products);
    if (
      finInfo?.config?.jointOwnership &&
      finInfo?.config?.jointOwnership.length > 0
    ) {
      this.setState({
        ownershipList: finInfo.config.jointOwnership,
      });
    }
    if (
      finInfo?.config?.jointSurvivorship &&
      finInfo?.config?.jointSurvivorship.length > 0
    ) {
      this.setState({
        survivorList: finInfo.config.jointSurvivorship,
      });
    }
    if (activeProduct.applicationId) {
      doGetApplicationProperty(
        { applicationId: activeProduct.applicationId },
        (res) => {
          console.log("after get app property");
          const jointAccounts = res.slots?.filter(
            (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
          );
          if (finInfo.jointMembership) {
            if (res.slots?.length > 1) {
              const jointMembership = getJointMemberships(
                membershipsData,
                applicationCompleteList
              );
              const jointExistingAccounts = [];
              if (activeProduct?.membership?.startsWith("P")) {
                const pendingApplication = applicationCompleteList.find(
                  (x) => x.memberNumber === activeProduct.membership
                );
                pendingApplication.slots.forEach((x) => {
                  if (x.type === AppConstants.JOINT_APPLICANT.TYPE) {
                    jointExistingAccounts.push({
                      firstName: x.firstName,
                      lastName: x.lastName,
                      email: x.email,
                      msisdn: x.inviteDetail.msisdn,
                      phoneNumber: x.phoneNumber,
                      joinType: x.joinInfo.joinType,
                    });
                  }
                });
              } else {
                console.log("jointMembership", jointMembership);
                if (activeProduct.inviteErrorEncountered) {
                  activeProduct.applicants.forEach((x) => {
                    jointExistingAccounts.push({
                      firstName: x.firstName,
                      lastName: x.lastName,
                      email: x.emailAddress,
                      msisdn: "",
                      phoneNumber: x.mobilePhone,
                      joinType: "",
                    });
                  });
                } else {
                  res.slots.forEach((x) => {
                    if (x.type === AppConstants.JOINT_APPLICANT.TYPE) {
                      jointExistingAccounts.push({
                        firstName: x.extra?.firstName,
                        lastName: x.extra?.lastName,
                        email: x.extra?.email,
                        msisdn: x.inviteDetail.msisdn,
                        phoneNumber: x.extra?.phoneNumber,
                        joinType: x.joinInfo.joinType,
                      });
                    }
                  });
                }
              }
              this.setState({
                isAddApplicant: membership === "NEW",
                jointMembershipSelected: true,
                jointExistingAccounts,
                jointAccounts:
                  activeProduct.membership === "NEW" ? jointAccounts : [],
                jointInSession: res.slots[1].extra.inSession, // all joints has the same value
                page: 2,
                jointMembership,
                membership: activeProduct.membership,
                addJointMembers: true,
                enterJointMembers: activeProduct.applicationId
                  ? true
                  : jointMembership.length === 0,
              });
            } else {
              const singularMembership = getSingularMemberships(
                membershipsData,
                applicationCompleteList,
                applicantData
              );
              const isNewMembership = singularMembership.find(
                (x) => x.Member === applicantData.member.memberNumber
              );
              console.log("singularMembership => ", singularMembership);
              this.setState({
                showSingularMembership: singularMembership?.length > 0,
                singularMembership: singularMembership,
                page: singularMembership?.length > 0 ? 2 : 1,
                membership: activeProduct.membership,
              });
            }
          } else if (res.slots.length > 1) {
            this.setState({
              jointAccounts,
              jointInSession: res.slots[1].extra.inSession, // all joints has the same value
            });
          }

          return jointAccounts;
        }
      );
    } else {
      // TODO what if new
      console.log("getJointApplicants without application");
      this.setState({
        page: 1,
      });
    }
  };

  getUniqueID = () => {
    this.id += 1;
    return this.id;
  };

  handleChange = (event) => {
    console.log(
      "handleChange",
      event.currentTarget.name,
      event.currentTarget.value
    );
    if (event.currentTarget.name === "ownership") {
      this.setState({
        ownership: event.currentTarget.value,
      });
    }
    if (event.currentTarget.name === "survivor") {
      this.setState({
        survivor: event.currentTarget.value,
      });
    }
    if (event.currentTarget.name === "membership") {
      this.setState({
        membership: event.currentTarget.value,
      });
    }
  };

  handleCreateApplicationWithSelectedMembership = (param) => {
    const { selectedApplicantId } = this.state;
    console.log("handleCreateApplicationWithSelectedMembership");
    if (selectedApplicantId) {
      this.handleCreateNewMembership(param, selectedApplicantId);
    } else {
      console.error("no selected applicant");
    }
  };

  deleteCurrentMembership = (param, callback) => {
    const { origUdfList, jointAccounts } = this.state;
    const {
      products,
      getActiveProduct,
      doDeleteApplication,
      doDeleteApplicationSlot,
      doUpdateContract,
      finInfo,
      doGetProductInformation,
    } = this.props;
    const activeProduct = getActiveProduct(products);

    doGetProductInformation(
      activeProduct.applicationId,
      activeProduct.type,
      activeProduct.doximProductId,
      (getProductInformationRes) => {
        if (getProductInformationRes.status === HTTP_STATUS.OK) {
          console.log("oldProductInformation", getProductInformationRes.data);
          this.setState(
            { oldProductInformation: getProductInformationRes.data },
            () => {
              if (jointAccounts.length > 0) {
                jointAccounts.forEach((joint) => {
                  doDeleteApplicationSlot({
                    applicationId: activeProduct.applicationId,
                    slotId: joint.id,
                  });
                });
                this.setState({ jointAccounts: [] }, () => {
                  if (
                    activeProduct.type ===
                    AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT
                  ) {
                    doUpdateContract(
                      activeProduct.applicationId,
                      activeProduct.type,
                      activeProduct.doximProductId,
                      {
                        intendedUse: finInfo.singleSignerIntendedUse,
                        demandProductUDFDetails: {
                          ...origUdfList,
                          udf5: "",
                        },
                      },
                      () => {
                        doDeleteApplication(
                          activeProduct.applicationId,
                          callback(param)
                        );
                      }
                    );
                  } else {
                    doUpdateContract(
                      activeProduct.applicationId,
                      activeProduct.type,
                      activeProduct.doximProductId,
                      {
                        intendedUse: finInfo.singleSignerIntendedUse,
                        udfList: {
                          ...origUdfList,
                          udf5: "",
                        },
                      },
                      () => {
                        doDeleteApplication(
                          activeProduct.applicationId,
                          callback(param)
                        );
                      }
                    );
                  }
                });
              } else {
                doDeleteApplication(
                  activeProduct.applicationId,
                  callback(param)
                );
              }
            }
          );
        }
      }
    );
  };

  handleCreateNewMembership = (param, applicantId) => {
    const {
      products,
      doCreateNewApplicant,
      applicantData,
      doGetBundleProductRelation,
      doUploadQuestionnaire,
      getActiveProduct,
      doUpdateApplicant,
      doUpdateProductToVault,
      finInfo,
      membershipsData,
    } = this.props;
    console.log("handleCreateNewMembership", param, applicantId);
    const {
      membership,
      jointMembershipSelected,
      oldProductInformation,
    } = this.state;
    const activeProduct = getActiveProduct(products);
    let bundleProductId = activeProduct.productId;
    if (activeProduct.productList && activeProduct.productList.length > 0) {
      bundleProductId = getProductIdByAge(
        applicantData.member.dob,
        activeProduct.productList
      );
    }
    let instntTxnId =
      activeProduct.instntTxnId || sessionStorage.getItem(S_INSTNT_TXN_ID);
    if (finInfo.jointMembership && !instntTxnId) {
      instntTxnId = param.instnttxnid;
    }
    const mainApplicantId = sessionStorage.getItem(S_APPLICANT_ID);
    let fingerprint =
      activeProduct.fingerprint ||
      param.fingerprint ||
      window.instnt?.fingerprint_txt;
    if (!fingerprint) {
      const fingerprint_txt = document.getElementById("fingerprint_txt");
      fingerprint = fingerprint_txt?.value;
    }
    doCreateNewApplicant(
      {
        applicantId,
        memberNumber: membership === "NEW" ? null : membership,
        mainApplicantId,
        createNewApplicant: !applicantId,
        firstName: applicantData.member.name,
        middleName: applicantData.member.middleName,
        surname: applicantData.member.surname,
        dateOfBirth: applicantData.member.dob,
        city: applicantData.address.currentAddressCity,
        state: applicantData.address.currentAddressProvince,
        postalCode: applicantData.address.currentAddressPostalCode,
        fingerprint,
        branch: applicantData.member.branch,
        bundleProductId,
        address: applicantData.address.currentAddressLine1,
        currentAddressUnitType: applicantData.address.currentAddressUnitType,
        currentAddressUnitNumber:
          applicantData.address.currentAddressUnitNumber,
        currentAddressStreetType:
          applicantData.address.currentAddressStreetType,
        currentAddressStreetDir: applicantData.address.currentAddressStreetDir,
        currentAddressStreetNumber:
          applicantData.address.currentAddressStreetNumber,
        currentAddressStreetName:
          applicantData.address.currentAddressStreetName,
        reasonOpened: applicantData.member.reasonOpened,
        instntTxnId,
        mailingSameAsCivic: applicantData.address.mailingSameAsCivic,
        benefit: applicantData.member.benefit,
        rep: applicantData.member.rep,
        idType1: applicantData.member.idType1,
        idCountry1: finInfo.idCountry1,
        idDetail1: applicantData.member.idDetail1,
        country: finInfo.verificationCountry,
        idProvince1: applicantData.member.idProvince1,
        expiryDate1: applicantData.member.expiryDate1,
        issueDate1: applicantData.member.issueDate1,
        citizenship: applicantData.regulatory.citizenship,
        mailStmt: applicantData.regulatory.mailStmt,
        eStmt: applicantData.regulatory.eStmt,
        mailingPreferenceStatement:
          applicantData.contact.mailingPreferenceStatement,
        creditCheckConsentType: applicantData.member.creditCheckConsentType,
        useAddressCellPhoneOnly: applicantData.address.useAddressCellPhoneOnly,
        referralCode: "",
        placeOfIssuance1: applicantData.member.placeOfIssuance1,
        doVerification: true,
        email: sessionStorage.getItem(S_EMAIL),
        msisdn: sessionStorage.getItem(S_MSISDN),
        addressLine3: applicantData.address.currentAddressLine3,
        mailingAddressLineType1: applicantData.address.mailingAddressLineType1,
        mailingAddressLine1: applicantData.address.mailingAddressLine1,
        mailingAddressLine2: applicantData.address.mailingAddressLine2,
        mailingAddressLine3: applicantData.address.mailingAddressLine3,
        mailingCity: applicantData.address.mailingCity,
        mailingProvince: applicantData.address.mailingProvince,
        mailingPostalCode: applicantData.address.mailingPostalCode,
        mailingCountry: applicantData.address.mailingCountry,
        mailingUnitNumber: applicantData.address.mailingUnitNumber,
        mailingUnitType: applicantData.address.mailingUnitType,
        mailingStreetType: applicantData.address.mailingStreetType,
        mailingStreetDir: applicantData.address.mailingStreetDir,
        mailingStreetNumber: applicantData.address.mailingStreetNumber,
        mailingStreetName: applicantData.address.mailingStreetName,
      },
      (response) => {
        if (response.status === HTTP_STATUS.OK) {
          const updateRequest = {
            address: applicantData.address,
            employment: applicantData.employment,
            regulatory: applicantData.regulatory,
          };
          if (!applicantId) {
            updateRequest.member = {
              intendedUseOfMembership:
                applicantData.member.intendedUseOfMembership,
            };
          }
          doUpdateApplicant(updateRequest, response.data.applicantId, () => {
            let updatedVaultProductList = products;
            const activeProduct = getActiveProduct(updatedVaultProductList);
            const idx = updatedVaultProductList.findIndex(
              (x) => x === activeProduct
            );
            delete updatedVaultProductList[idx].applicants;
            updatedVaultProductList[idx].inviteErrorEncountered = false;
            updatedVaultProductList[idx].applicationId =
              response.data.applicationId;
            updatedVaultProductList[idx].applicantId =
              response.data.applicantId;
            updatedVaultProductList[idx].membership = membership;
            let membershipCategory;
            membershipCategory = membershipsData.find(
              (x) => x.Member === membership
            )?.Category;
            updatedVaultProductList[
              idx
            ].membershipCategory = membershipCategory;
            updatedVaultProductList[
              idx
            ].jointMembershipSelected = jointMembershipSelected;

            if (updatedVaultProductList[0].questionnaire) {
              doUploadQuestionnaire({
                ...updatedVaultProductList[0].questionnaire,
                applicationId: response.data.applicationId,
              });
            }
            if (
              response.data.doximProducts &&
              response.data.doximProducts.length !== 0
            ) {
              if (response.data.doximProducts.length === 1) {
                updatedVaultProductList[idx].doximProductId =
                  response.data.doximProducts[idx].id;
              } else {
                // search registered contract
                const registeredContract = response.data.doximProducts.find(
                  (item) =>
                    item.type === AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                );
                if (registeredContract) {
                  updatedVaultProductList[0].contractProductId = registeredContract
                    ? registeredContract.id
                    : null;
                }
                const nonRegisteredContract = response.data.doximProducts.find(
                  (item) =>
                    item.type !== AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                );
                if (nonRegisteredContract) {
                  updatedVaultProductList[0].doximProductId =
                    nonRegisteredContract.id;
                } else {
                  updatedVaultProductList[idx].doximProductId =
                    response.data.doximProducts[idx].id;
                  console.error("UNHANDLED MULTI PRODUCTS", response.data);
                }
              }
            }

            doGetBundleProductRelation(
              {
                bundleProductId: getProductIdInActiveProduct(
                  updatedVaultProductList[idx],
                  applicantData
                ),
                demandProductId: updatedVaultProductList[idx].productId,
                applicationId: updatedVaultProductList[idx].applicationId,
              },
              (getBundleProductRelationRes) => {
                if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
                  console.log(
                    "getBundleProductRelationRes",
                    getBundleProductRelationRes
                  );
                  updatedVaultProductList[idx].bundleProducts =
                    getBundleProductRelationRes.data;
                  doUpdateProductToVault(updatedVaultProductList, () => {
                    if (param.jointAppVaultProducts) {
                      param.jointAppVaultProducts[0].doximProductId =
                        updatedVaultProductList[idx].doximProductId;
                      param.jointAppVaultProducts[0].contractProductId =
                        updatedVaultProductList[idx].contractProductId;
                      param.jointAppVaultProducts[0].bundleProducts =
                        updatedVaultProductList[idx].bundleProducts;
                    }
                    this.updateFinAnalysisIdForLendingProducts(
                      updatedVaultProductList,
                      idx,
                      updatedVaultProductList[idx].applicantId,
                      (newVaultProductList) => {
                        updatedVaultProductList = newVaultProductList;
                        if (oldProductInformation) {
                          this.setState(
                            {
                              ownership: oldProductInformation.intendedUse,
                              origUdfList: oldProductInformation.udfList,
                              oldProductInformation: null,
                            },
                            () => this.processJoints(param)
                          );
                        } else {
                          this.processJoints(param);
                        }
                      }
                    );
                  });
                }
              }
            );
          });
        }
      }
    );
  };

  toggleJointSelection = (choice) => {
    console.log("toggleJointSelection", choice);
    this.setState({ jointMembershipSelected: choice });
    if (!choice) {
      this.setState({ addJointMembers: false, enterJointMembers: false });
    }
  };
  handleContinue = (param) => {
    const {
      continuehandler,
      products,
      getActiveProduct,
      finInfo,
      membershipsData,
      doUpdateProductToVault,
      applicationCompleteList,
      applicantData,
    } = this.props;
    const { applicants, isApplicantPresent, isAddApplicant } = param;
    const {
      jointAccounts, // old list of joint
      jointInSession, // old settings
      inSessionGlobalVault,
      ownership,
      survivor,
      origUdfList,
      membership,
      jointMembershipSelected,
      addJointMembers,
      page,
    } = this.state;

    console.log(
      "handleContinue",
      jointMembershipSelected,
      addJointMembers,
      membership,
      page
    );
    console.log("applicants", applicants);
    console.log("isApplicantPresent", isApplicantPresent);
    console.log(products);
    console.log("jointAccounts", jointAccounts);
    console.log("isAddApplicant", isAddApplicant);
    console.log("ownership", ownership);
    console.log("addJointMembers", addJointMembers);
    console.log("membership", membership);
    console.log("jointMembershipSelected", jointMembershipSelected);
    const activeProduct = getActiveProduct(products);
    console.log("activeProduct", activeProduct);
    if (page === 1) {
      if (finInfo.jointMembership) {
        if (jointMembershipSelected) {
          const jointMembership = getJointMemberships(
            membershipsData,
            applicationCompleteList
          );
          if (activeProduct.applicationId) {
            if (activeProduct.jointMembershipSelected) {
              this.setState({
                isAddApplicant: false,
                addJointMembers: activeProduct.membership === "NEW",
                page: 2,
                enterJointMembers: true,
                jointMembership,
                membership: activeProduct.membership,
              });
            } else {
              this.setState({
                isAddApplicant: jointMembership.length === 0,
                addJointMembers: jointMembership.length === 0,
                page: 2,
                enterJointMembers: jointMembership.length === 0,
                jointMembership,
                membership: jointMembership.length > 0 ? null : "NEW",
              });
            }
          } else {
            this.setState({
              isAddApplicant: jointMembership.length === 0,
              addJointMembers: jointMembership.length === 0,
              page: 2,
              enterJointMembers: jointMembership.length === 0,
              jointMembership,
              membership: jointMembership.length > 0 ? null : "NEW",
            });
          }
        } else {
          const singularMembership = getSingularMemberships(
            membershipsData,
            applicationCompleteList,
            applicantData
          );
          if (singularMembership.length > 0) {
            console.log("showing singular", singularMembership);
            this.setState({
              showSingularMembership: true,
              singularMembership,
              page: 2,
            });
          } else {
            if (activeProduct.applicationId) {
              if (activeProduct.jointMembershipSelected) {
                this.deleteCurrentMembership(param, () =>
                  this.handleCreateNewMembership(param)
                );
              } else {
                this.processJoints(param);
              }
            } else {
              this.handleCreateNewMembership(param);
            }
          }
        }
      } else {
        this.processJoints(param);
      }
    } else {
      if (finInfo.jointMembership) {
        //always going here as page 2 is for jointMembership
        if (activeProduct.applicationId) {
          if (membership !== activeProduct.membership) {
            if (membership === "NEW") {
              this.deleteCurrentMembership(
                param,
                this.handleCreateNewMembership
              );
            } else {
              this.deleteCurrentMembership(param, () =>
                this.handleCreateApplicationWithSelectedMembership(param)
              );
            }
          } else if (
            membership !== "NEW" &&
            activeProduct.membership === membership
          ) {
            if (activeProduct.jointMembershipSelected) {
              this.processJointMembership(param);
            } else {
              continuehandler(
                inSessionGlobalVault,
                AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
              );
            }
          } else {
            this.processJoints(param);
          }
        } else {
          if (membership) {
            if (membership === "NEW") {
              this.handleCreateNewMembership(param);
            } else {
              this.handleCreateApplicationWithSelectedMembership(param);
            }
          } else {
            this.handleCreateNewMembership(param);
          }
        }
      } else {
        this.processJoints(param);
      }
    }
  };

  // meant to update if in session or not
  processJointMembership = (param) => {
    const {
      applicants,
      isApplicantPresent,
      isAddApplicant,
      applicationId,
      applicantId,
      jointAppVaultProducts,
    } = param;
    const { inSessionGlobalVault } = this.state;
    const {
      continuehandler,
      products,
      doDeleteApplicationSlot,
      getActiveProduct,
      doGetApplicationProperty,
      doAddJointApplicants,
    } = this.props;
    console.log(
      "processJointMembership",
      isApplicantPresent,
      applicationId,
      jointAppVaultProducts
    );
    const activeProduct = getActiveProduct(products);
    doGetApplicationProperty(
      { applicationId: activeProduct.applicationId },
      (res) => {
        console.log(res);
        if (res.created) {
          const jointAccounts = res.slots.filter(
            (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
          );
          if (jointAccounts.length > 0) {
            if (jointAccounts[0].extra.inSession !== isApplicantPresent) {
              const jointApplicantRequests = [];
              for (const jointInfo of jointAccounts) {
                console.log("jointInfo", jointInfo);
                const request = {
                  applicationId: activeProduct.applicationId,
                  jointApplicants: [],
                };
                const jointApplicants = [];
                jointInfo.extra.inSession = isApplicantPresent;
                jointApplicants.push(jointInfo);
                request.jointApplicants = jointApplicants;
                doDeleteApplicationSlot({
                  applicationId: activeProduct.applicationId,
                  slotId: jointInfo.id,
                });
                jointApplicantRequests.push(request);
                const joint = {
                  firstName: jointInfo.extra.firstName,
                  lastName: jointInfo.extra.lastName,
                  email: jointInfo.extra.email,
                  msisdn:
                    "1" +
                    jointInfo.extra.phoneNumber.replace(
                      AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
                      ""
                    ),
                  mobilePhone: jointInfo.extra.phoneNumber,
                  phoneNumber: jointInfo.extra.phoneNumber,
                  //TODO what should be the joinType of primary
                  joinType: jointInfo.joinInfo.joinType || "jnt",
                };
                this.addToGlobalVault(
                  joint,
                  jointAppVaultProducts,
                  jointInfo.joinInfo.joinType || "jnt",
                  activeProduct.applicantId,
                  activeProduct.applicationId
                );
              }
              if (jointApplicantRequests.length > 0) {
                doAddJointApplicants(jointApplicantRequests, (res) => {
                  console.log(res);
                  doGetApplicationProperty(
                    { applicationId: activeProduct.applicationId },
                    (res) => {
                      continuehandler(
                        inSessionGlobalVault,
                        AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
                      );
                    }
                  );
                });
              }
            } else {
              continuehandler(
                inSessionGlobalVault,
                AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
              );
            }
          } else {
            continuehandler(
              inSessionGlobalVault,
              AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
            );
          }
        }
      }
    );
  };

  processJoints = (param) => {
    const {
      continuehandler,
      products,
      doDeleteApplicationSlot,
      getActiveProduct,
      doUpdateContract,
      finInfo,
      doGetApplicationProperty,
    } = this.props;
    const {
      applicants,
      isApplicantPresent,
      isAddApplicant,
      jointAppVaultProducts,
    } = param;
    const {
      jointAccounts, // old list of joint
      jointInSession, // old settings
      inSessionGlobalVault,
      ownership,
      survivor,
      origUdfList,
      membership,
      jointMembershipSelected,
      addJointMembers,
      jointExistingAccounts,
      oldProductInformation,
    } = this.state;

    console.log(
      "processJoints",
      jointMembershipSelected,
      addJointMembers,
      membership
    );
    console.log("applicants", applicants);
    console.log("isApplicantPresent", isApplicantPresent);
    console.log(products);
    console.log("jointAccounts", jointAccounts);
    console.log("isAddApplicant", isAddApplicant);
    console.log("ownership", ownership);
    console.log("jointAppVaultProducts", jointAppVaultProducts);

    const activeProduct = getActiveProduct(products);

    doGetApplicationProperty(
      { applicationId: activeProduct.applicationId },
      (res) => {
        console.log(res);
        if (res.created) {
          if (isAddApplicant) {
            if (membership !== "NEW" && finInfo.jointMembership) {
              const pendingJoints = [];
              jointExistingAccounts.forEach((joint) => {
                pendingJoints.push({
                  firstName: joint.firstName,
                  lastName: joint.lastName,
                  emailAddress: joint.email,
                  emailConfirm: joint.email,
                  mobilePhone: joint.phoneNumber,
                  applicantType: joint.joinType,
                  signer: !finInfo.showJointSignatory,
                });
              });
              this.addApplicants(
                activeProduct.applicantId,
                activeProduct.applicationId,
                pendingJoints,
                false,
                jointAppVaultProducts,
                false
              );
            } else {
              this.addApplicants(
                activeProduct.applicantId,
                activeProduct.applicationId,
                applicants,
                isApplicantPresent,
                jointAppVaultProducts,
                jointInSession
              );
            }
            if (
              activeProduct.type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT
            ) {
              doUpdateContract(
                activeProduct.applicationId,
                activeProduct.type,
                activeProduct.doximProductId,
                {
                  intendedUse: ownership,
                  demandProductUDFDetails: {
                    ...origUdfList,
                    udf5: survivor,
                  },
                }
              );
            } else {
              doUpdateContract(
                activeProduct.applicationId,
                activeProduct.type,
                activeProduct.doximProductId,
                {
                  intendedUse: ownership,
                  udfList: {
                    ...origUdfList,
                    udf5: survivor,
                  },
                }
              );
            }
          } else {
            // delete all joint since joint is set to NO
            jointAccounts.forEach((joint) => {
              doDeleteApplicationSlot({
                applicationId: activeProduct.applicationId,
                slotId: joint.id,
              });
            });
            if (
              activeProduct.type === AppConstants.PRODUCT_TYPE.DEMAND_PRODUCT
            ) {
              doUpdateContract(
                activeProduct.applicationId,
                activeProduct.type,
                activeProduct.doximProductId,
                {
                  intendedUse: finInfo.singleSignerIntendedUse || ownership,
                  demandProductUDFDetails: {
                    ...origUdfList,
                    udf5: "",
                  },
                },
                () => {
                  continuehandler(
                    inSessionGlobalVault,
                    AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
                  );
                }
              );
            } else {
              doUpdateContract(
                activeProduct.applicationId,
                activeProduct.type,
                activeProduct.doximProductId,
                {
                  intendedUse: finInfo.singleSignerIntendedUse || ownership,
                  udfList: {
                    ...origUdfList,
                    udf5: "",
                  },
                },
                () => {
                  continuehandler(
                    inSessionGlobalVault,
                    AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
                  );
                }
              );
            }
          }
        }
      }
    );
  };

  handleBack = () => {
    const { handleBack, doToggleBackFlag } = this.props;
    doToggleBackFlag(true);
    handleBack(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
  };

  togglePage = (value) => {
    if (value === 1) {
      this.setState({
        addJointMembers: false,
        page: value,
        showSingularMembership: false,
      });
    } else {
      this.setState({ page: value });
    }
  };

  addToGlobalVault(
    raw,
    jointAppVaultProducts,
    jointType,
    applicantId,
    applicationId
  ) {
    console.log("addToGlobalVault", raw);
    const { doAddGlobalVault, applicantData } = this.props;
    const validMobileNo =
      AppConstants.JOINT_APPLICANT.NUMBERPREFIX +
      raw.mobilePhone.replace(
        AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
        ""
      );
    const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${validMobileNo}`;
    if (jointType === STRINGS.JOIN_APPLICANT.IN_SESSION) {
      const inSessionVault = {
        type: jointType,
        vaultProducts: jointAppVaultProducts,
        mainApplicantId: applicantId,
        mailingPreferenceStatement:
          applicantData.contact.mailingPreferenceStatement,
        reasonOpened: applicantData.member.reasonOpened,
        jointApplicants: {
          email: raw.emailAddress,
          msisdn: validMobileNo,
          status: STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING,
          signer: raw.signer,
        },
      };
      this.state.inSessionGlobalVault.push(inSessionVault);
      return;
    }

    console.log(vKey);
    const isMobile = isScanningAllowed();
    jointAppVaultProducts[0].slotId = raw.id;
    jointAppVaultProducts[0].branch = applicantData.member.branch;
    jointAppVaultProducts[0].eStmt = applicantData.regulatory.eStmt;
    jointAppVaultProducts[0].mailStmt = applicantData.regulatory.mailStmt;
    jointAppVaultProducts[0].mailingPreferenceStatement =
      applicantData.contact.mailingPreferenceStatement;
    jointAppVaultProducts[0].reasonOpened = applicantData.member.reasonOpened;
    jointAppVaultProducts[0].debitProductId = null;
    jointAppVaultProducts[0].enableDebitCard = false;
    jointAppVaultProducts[0].isDesktop =
      !isMobile && isNewMember(applicantData);
    doAddGlobalVault(
      {
        vaultKey: vKey,
        body: {
          type: jointType,
          vaultProducts: jointAppVaultProducts,
          // eslint-disable-next-line object-shorthand
          applicantId: applicantId,
          // eslint-disable-next-line object-shorthand
          jointApplicants: {
            email: raw.emailAddress,
            msisdn: validMobileNo,
            status: STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING,
            signer: raw.signer,
          },
        },
      },
      (globalVaultRes) => {
        console.log(globalVaultRes);
      }
    );
  }

  addApplicants(
    applicantId,
    applicationId,
    applicants,
    isApplicantPresent,
    jointAppVaultProducts,
    oldJointInSession,
    doNotContinue
  ) {
    console.log(
      "addApplicants",
      applicantId,
      applicationId,
      applicants,
      isApplicantPresent,
      jointAppVaultProducts,
      oldJointInSession,
      doNotContinue
    );
    const {
      doDeleteApplicationSlot,
      doAddJointApplicants,
      continuehandler,
      doGetApplicationProperty,
      products,
      getActiveProduct,
      doUpdateProductToVault,
      doCheckExistingUsers,
    } = this.props;
    const { jointAccounts, inSessionGlobalVault, membership } = this.state;
    const activeProduct = getActiveProduct(products);
    let jointType = STRINGS.JOIN_APPLICANT.IN_SESSION;

    if (isApplicantPresent !== "" && !isApplicantPresent) {
      jointType = STRINGS.JOIN_APPLICANT.OUT_OF_SESSION;
    }

    const jointApplicantRequests = [];
    for (const joint of applicants) {
      console.log("joint", joint);
      const request = {
        applicationId,
        jointApplicants: [],
      };
      const jointApplicants = [];
      const joinInfo = {
        joinInfo: {
          joinType: joint.applicantType,
          signer: joint.signer,
        },
        extra: {
          firstName: joint.firstName || undefined,
          lastName: joint.lastName || undefined,
          phoneNumber: joint.mobilePhone,
          inSession: isApplicantPresent,
          email: joint.emailAddress,
          msisdn:
            AppConstants.JOINT_APPLICANT.NUMBERPREFIX +
            joint.mobilePhone.replace(
              AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
              ""
            ),
        },
      };
      console.log("joinInfo", joinInfo, membership);
      jointApplicants.push(joinInfo);
      request.jointApplicants = jointApplicants;
      if (joint.id) {
        // joint would already have an id if already added
        const currentJointInfo = jointAccounts.find(
          (item) => item.id === joint.id
        );
        console.log("currentJointInfo", currentJointInfo);
        if (
          joinInfo.extra.email !== currentJointInfo.extra.email ||
          joinInfo.extra.msisdn !== currentJointInfo.extra.msisdn ||
          joinInfo.joinInfo.joinType !== currentJointInfo.joinInfo.joinType ||
          joinInfo.joinInfo.signer !== currentJointInfo.joinInfo.signer
        ) {
          doDeleteApplicationSlot({ applicationId, slotId: joint.id });
          jointApplicantRequests.push(request);
        } else if (isApplicantPresent !== oldJointInSession) {
          // we can't update the in session flag so we need to delete and add
          doDeleteApplicationSlot({ applicationId, slotId: joint.id });
          jointApplicantRequests.push(request);
        }
      } else {
        jointApplicantRequests.push(request);
      }
      this.addToGlobalVault(
        joint,
        jointAppVaultProducts,
        jointType,
        applicantId,
        applicationId
      );
    }

    // delete joints removed
    for (const joint of jointAccounts) {
      const jointExist = applicants.find((item) => item.id === joint.id);
      if (!jointExist) {
        doDeleteApplicationSlot({ applicationId, slotId: joint.id });
      }
    }

    if (jointApplicantRequests.length > 0) {
      let jointDuplicate = null;
      if (membership && membership !== "NEW") {
        // find if one of the joint has the same email as primary
        jointDuplicate = jointApplicantRequests.find((x) => {
          return x.jointApplicants.find(
            (joint) => joint.extra.email === sessionStorage.getItem(S_EMAIL)
          );
        });
      }
      console.log("jointDuplicate", jointDuplicate);

      doAddJointApplicants(jointApplicantRequests, (res) => {
        console.log(res);
        this.setState({
          inviteErrorEncountered: res.inviteErrorEncountered || jointDuplicate,
          showError: !!jointDuplicate,
        });
        // check if one of the joint has existing duplicate profiles
        let jointHasExistingDuplicateProfiles = false;
        if (
          !jointDuplicate &&
          !res.inviteErrorEncountered &&
          membership &&
          membership !== "NEW"
        ) {
          for (
            let i = 0;
            i < jointApplicantRequests.length &&
            !jointHasExistingDuplicateProfiles;
            i++
          ) {
            const request = jointApplicantRequests[i];
            console.log("request", request);
            for (
              let x = 0;
              x < request.jointApplicants.length &&
              !jointHasExistingDuplicateProfiles;
              x++
            ) {
              doCheckExistingUsers(
                {
                  credential: request.jointApplicants[x].extra.email,
                  bankingType: sessionStorage.getItem(S_BANKING_TYPE),
                },
                (existingRes) => {
                  console.log(existingRes);
                  if (existingRes.data.size > 1) {
                    jointHasExistingDuplicateProfiles = true;
                    this.setState({ showMessage: true });
                  }
                }
              );
            }
          }
        }
        console.log(
          "jointHasExistingDuplicateProfiles",
          jointHasExistingDuplicateProfiles
        );

        const updatedVaultProducts = products;
        updatedVaultProducts[0].inviteErrorEncountered =
          res.inviteErrorEncountered || jointDuplicate;
        updatedVaultProducts[0].applicants = applicants;
        doUpdateProductToVault(updatedVaultProducts);
        if (!doNotContinue) {
          doGetApplicationProperty(
            { applicationId: activeProduct.applicationId },
            (res) => {
              const jointAccounts = res.slots.filter(
                (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
              );
              if (res.slots.length > 1) {
                this.setState({
                  jointAccounts,
                  jointInSession: res.slots[1].extra.inSession, // all joints has the same value
                });
              }
              const notInvited = jointAccounts.find(
                (item) => !item.inviteDetail
              );
              if (notInvited) {
                // user has to fix data issue
              } else {
                continuehandler(
                  inSessionGlobalVault,
                  AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
                );
              }
            }
          );
        }
      });
    } else {
      const notInvited = applicants.find((item) => !item.inviteDetail);
      if (notInvited) {
        // user has to fix data issue
      } else {
        continuehandler(
          inSessionGlobalVault,
          AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
        );
      }
    }
  }

  inviteApplicants(
    applicantId,
    applicationId,
    applicants,
    isApplicantPresent,
    jointAppVaultProducts,
    oldJointInSession
  ) {
    console.log("inviteApplicants");
    const {
      doDeleteApplicationSlot,
      doAddJointApplicants,
      continuehandler,
      doGetApplicationProperty,
      products,
      getActiveProduct,
    } = this.props;
    const { jointAccounts, inSessionGlobalVault } = this.state;
    const activeProduct = getActiveProduct(products);
    let jointType = STRINGS.JOIN_APPLICANT.IN_SESSION;

    if (isApplicantPresent !== "" && !isApplicantPresent) {
      jointType = STRINGS.JOIN_APPLICANT.OUT_OF_SESSION;
    }

    const jointApplicantRequests = [];
    for (const joint of applicants) {
      console.log("joint", joint);
      this.addToGlobalVault(
        joint,
        jointAppVaultProducts,
        jointType,
        applicantId,
        applicationId
      );
    }
  }

  addJointAccount(applicationId, isApplicantPresent, request, joint) {
    console.log("addJointAccount", isApplicantPresent, request, joint);
    const {
      doAddApplicationSlot,
      doinviteApplicationSlot,
      doGetApplicationProperty,
    } = this.props;
    doAddApplicationSlot(request, (response) => {
      if (response.status === HTTP_STATUS.OK) {
        const body = {
          email: joint.emailAddress,
          msisdn:
            AppConstants.JOINT_APPLICANT.NUMBERPREFIX +
            joint.mobilePhone.replace(
              AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
              ""
            ),
        };
        const inviteReq = {
          // eslint-disable-next-line object-shorthand
          body: body,
          // eslint-disable-next-line object-shorthand
          applicationId: applicationId,
          slotId: response.data.slots[0].id,
        };
        if (!isApplicantPresent) {
          console.log("inviting joint", inviteReq);
          doinviteApplicationSlot(inviteReq, (inviteRes) => {
            if (inviteRes.status !== HTTP_STATUS.OK) {
              doGetApplicationProperty({ applicationId }, (res) => {
                // wait to get reducer updated
              });
            }
          });
        } else {
          doGetApplicationProperty({ applicationId }, (res) => {
            // wait to get reducer updated
          });
        }
      } else {
        // eslint-disable-next-line spaced-comment
        //hasError = false;
      }
    });
  }

  selectMembership = (event, param) => {
    console.log("selectMembership", event.target.value);
    const {
      doSearchMember,
      applicationCompleteList,
      getActiveProduct,
      products,
      membershipsData,
    } = this.props;
    const { jointMembershipSelected, jointMembership } = this.state;
    const activeProduct = getActiveProduct(products);
    const membership = event.target.value;
    if (membership === "NEW") {
      this.setState({
        membership,
        dataIssue: null,
        addJointMembers: true,
        inviteErrorEncountered: false,
      });
    } else if (membership) {
      if (jointMembershipSelected) {
        const appCompleted = applicationCompleteList.find(
          (x) => x.memberNumber === membership && x.memberNumber.startsWith("P")
        );
        if (appCompleted) {
          this.setState(
            {
              membership,
              dataIssue: null,
              selectedApplicantId: appCompleted.applicantId,
            },
            () => {
              if (activeProduct.applicationId) {
                this.deleteCurrentMembership(param, () =>
                  this.createNewJointMembershipBasedOnProspect(appCompleted)
                );
              } else {
                this.createNewJointMembershipBasedOnProspect(appCompleted);
              }
            }
          );
        } else {
          doSearchMember({ membershipNumber: membership }, (res) => {
            const selectedMembershipData = membershipsData.find(
              (x) => x.Member === membership
            );
            if (res?.data?.applicantId) {
              this.setState(
                {
                  membership,
                  dataIssue: null,
                  selectedApplicantId: res?.data?.applicantId,
                },
                () => {
                  if (activeProduct.applicationId) {
                    this.deleteCurrentMembership(param, () =>
                      this.createNewJointMembership(
                        res?.data?.applicantId,
                        selectedMembershipData
                      )
                    );
                  } else {
                    this.createNewJointMembership(
                      res?.data?.applicantId,
                      selectedMembershipData
                    );
                  }
                }
              );
            } else {
              this.setState({
                membership,
                dataIssue: "Joint data issue. Applicant id missing.",
                jointExistingAccounts: [],
              });
            }
          });
        }
      } else {
        const appCompleted = applicationCompleteList.find(
          (x) => x.memberNumber === membership
        );
        if (appCompleted) {
          const jointExistingAccounts = [];
          appCompleted.slots.forEach((x) => {
            if (x.memberNumber !== membership) {
              jointExistingAccounts.push({
                firstName: x.firstName,
                lastName: x.lastName,
                email: x.email,
                msisdn: "1" + x.phoneNumber,
                phoneNumber: x.phoneNumber,
                //TODO what should be the joinType of primary
                joinType:
                  x.joinInfo?.joinType ||
                  appCompleted.slots[1]?.joinInfo.joinType,
              });
            }
          });
          this.setState({
            membership,
            dataIssue: null,
            jointExistingAccounts,
            selectedApplicantId: appCompleted.applicantId,
          });
        } else {
          doSearchMember({ membershipNumber: membership }, (res) => {
            if (res?.data?.applicantId) {
              this.setState({
                membership,
                dataIssue: null,
                jointExistingAccounts: [],
                selectedApplicantId: res?.data?.applicantId,
              });
            } else {
              this.setState({
                membership,
                dataIssue: "Joint data issue. Applicant id missing.",
                jointExistingAccounts: [],
              });
            }
          });
        }
      }
    } else {
      this.setState({
        membership,
        dataIssue: "Please select an option.",
        jointExistingAccounts: [],
      });
    }
  };

  createNewJointMembership = (applicantId, selectedMembershipData) => {
    const {
      getActiveProduct,
      products,
      doCreateNewApplicant,
      applicantData,
      doUpdateProductToVault,
      doGetBundleProductRelation,
      membershipsData,
      finInfo,
    } = this.props;
    console.log("createNewJointMembership", applicantId);
    const { membership, oldProductInformation } = this.state;
    const activeProduct = getActiveProduct(products);
    let bundleProductId = activeProduct.productId;
    if (activeProduct.productList && activeProduct.productList.length > 0) {
      bundleProductId = getProductIdByAge(
        applicantData.member.dob,
        activeProduct.productList
      );
    }
    const preProcessingMembership = membership;
    this.setState({ membership: "" }, () =>
      doCreateNewApplicant(
        {
          applicantId: applicantId,
          memberNumber: membership === "NEW" ? null : membership,
          branch: applicantData.member.branch,
          bundleProductId,
          dateOfBirth: "1976-10-11", // Fake date to pass validation
          isJoint: true,
          jointApplicantId:
            selectedMembershipData.Category === "jnt"
              ? sessionStorage.getItem(S_APPLICANT_ID)
              : null,
        },
        (response) => {
          if (response.status === HTTP_STATUS.OK) {
            this.setState({ membership: preProcessingMembership }, () => {
              let updatedVaultProductList = products;
              const idx = updatedVaultProductList.findIndex(
                (x) => x === activeProduct
              );
              delete updatedVaultProductList[idx].applicants;
              updatedVaultProductList[idx].inviteErrorEncountered = false;
              updatedVaultProductList[idx].applicationId =
                response.data.applicationId;
              updatedVaultProductList[idx].applicantId =
                response.data.applicantId;
              updatedVaultProductList[idx].membership = membership;
              updatedVaultProductList[
                idx
              ].membershipCategory = membershipsData.find(
                (x) => x.Member === membership
              ).Category;
              updatedVaultProductList[idx].jointMembershipSelected = true;
              if (
                response.data.doximProducts &&
                response.data.doximProducts.length !== 0
              ) {
                if (response.data.doximProducts.length === 1) {
                  updatedVaultProductList[idx].doximProductId =
                    response.data.doximProducts[idx].id;
                } else {
                  // search registered contract
                  const registeredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type ===
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  if (registeredContract) {
                    updatedVaultProductList[0].contractProductId = registeredContract
                      ? registeredContract.id
                      : null;
                  }
                  const nonRegisteredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type !==
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  if (nonRegisteredContract) {
                    updatedVaultProductList[0].doximProductId =
                      nonRegisteredContract.id;
                  } else {
                    updatedVaultProductList[idx].doximProductId =
                      response.data.doximProducts[idx].id;
                    console.error("UNHANDLED MULTI PRODUCTS", response.data);
                  }
                }
              }

              doGetBundleProductRelation(
                {
                  bundleProductId: getProductIdInActiveProduct(
                    updatedVaultProductList[idx],
                    applicantData
                  ),
                  demandProductId: updatedVaultProductList[idx].productId,
                  applicationId: updatedVaultProductList[idx].applicationId,
                },
                (getBundleProductRelationRes) => {
                  if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
                    console.log(
                      "getBundleProductRelationRes",
                      getBundleProductRelationRes
                    );
                    updatedVaultProductList[idx].bundleProducts =
                      getBundleProductRelationRes.data;
                    doUpdateProductToVault(updatedVaultProductList, () => {
                      this.updateFinAnalysisIdForLendingProducts(
                        updatedVaultProductList,
                        idx,
                        updatedVaultProductList[idx].applicantId,
                        (newVaultProductList) => {
                          updatedVaultProductList = newVaultProductList;
                          if (response.data?.applicants.length) {
                            const jointExistingAccounts = [];
                            const mainMemberNo = sessionStorage.getItem(
                              S_APPLICANT_MEMBER_NO
                            );
                            response.data.applicants.forEach((jointApp) => {
                              if (jointApp.MemberNumber !== mainMemberNo) {
                                jointExistingAccounts.push({
                                  firstName: jointApp.firstName,
                                  lastName: jointApp.lastName,
                                  email: jointApp.email,
                                  msisdn:
                                    "1" +
                                    jointApp.phoneNumber.replace(
                                      AppConstants.JOINT_APPLICANT
                                        .CONTACT_NO_PATTERN,
                                      ""
                                    ),
                                  phoneNumber: jointApp.phoneNumber,
                                  //TODO what should be the joinType of primary
                                  joinType: jointApp?.joinType || "jnt",
                                });
                              }
                            });
                            if (oldProductInformation) {
                              this.updateProductInformation(
                                oldProductInformation,
                                activeProduct
                              );
                            }
                            this.setState(
                              {
                                jointExistingAccounts,
                                oldProductInformation: null,
                              },
                              () => {
                                const pendingJoints = [];
                                jointExistingAccounts.forEach((joint) => {
                                  pendingJoints.push({
                                    firstName: joint.firstName,
                                    lastName: joint.lastName,
                                    emailAddress: joint.email,
                                    emailConfirm: joint.email,
                                    mobilePhone: joint.phoneNumber,
                                    applicantType: joint.joinType,
                                    signer: !finInfo.showJointSignatory,
                                  });
                                });
                                this.addApplicants(
                                  response.data.applicantId,
                                  response.data.applicationId,
                                  pendingJoints,
                                  false,
                                  [
                                    {
                                      ...activeProduct,
                                      applicantId: null,
                                      applicationId: null,
                                      applicationStep: {
                                        index: null,
                                        step: "",
                                      },
                                      productId: activeProduct.productId,
                                      productIndex: activeProduct.productIndex,
                                      productName: activeProduct.productName,
                                      templateId: activeProduct.templateId,
                                      type: activeProduct.type,
                                      isJointProduct: true,
                                    },
                                  ],
                                  false,
                                  true
                                );
                              }
                            );
                          }
                        }
                      );
                    });
                  }
                }
              );
            });
          } else {
            console.log("handle error here");
          }
        }
      )
    );
  };

  createNewJointMembershipBasedOnProspect = (prospectApp) => {
    const {
      getActiveProduct,
      products,
      doCreateNewApplicant,
      applicantData,
      doUpdateProductToVault,
      doGetBundleProductRelation,
      membershipsData,
      finInfo,
    } = this.props;
    const { membership, oldProductInformation } = this.state;
    console.log("createNewJointMembershipBasedOnProspect", prospectApp);
    const activeProduct = getActiveProduct(products);
    let bundleProductId = activeProduct.productId;
    if (activeProduct.productList && activeProduct.productList.length > 0) {
      bundleProductId = getProductIdByAge(
        applicantData.member.dob,
        activeProduct.productList
      );
    }
    doCreateNewApplicant(
      {
        applicantId: prospectApp.applicantId,
        memberNumber: membership === "NEW" ? null : prospectApp.memberNumber,
        branch: applicantData.member.branch,
        bundleProductId,
        dateOfBirth: "1976-10-11", // Fake date to pass validation
      },
      (response) => {
        if (response.status === HTTP_STATUS.OK) {
          let updatedVaultProductList = products;
          const idx = updatedVaultProductList.findIndex(
            (x) => x === activeProduct
          );
          delete updatedVaultProductList[idx].applicants;
          updatedVaultProductList[idx].inviteErrorEncountered = false;
          updatedVaultProductList[idx].applicationId =
            response.data.applicationId;
          updatedVaultProductList[idx].applicantId = response.data.applicantId;
          updatedVaultProductList[idx].membership = membership;
          updatedVaultProductList[idx].membershipCategory = "y";
          updatedVaultProductList[idx].jointMembershipSelected = true;
          if (
            response.data.doximProducts &&
            response.data.doximProducts.length !== 0
          ) {
            if (response.data.doximProducts.length === 1) {
              updatedVaultProductList[idx].doximProductId =
                response.data.doximProducts[idx].id;
            } else {
              // search registered contract
              const registeredContract = response.data.doximProducts.find(
                (item) =>
                  item.type === AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
              );
              if (registeredContract) {
                updatedVaultProductList[0].contractProductId = registeredContract
                  ? registeredContract.id
                  : null;
              }
              const nonRegisteredContract = response.data.doximProducts.find(
                (item) =>
                  item.type !== AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
              );
              if (nonRegisteredContract) {
                updatedVaultProductList[0].doximProductId =
                  nonRegisteredContract.id;
              } else {
                updatedVaultProductList[idx].doximProductId =
                  response.data.doximProducts[idx].id;
                console.error("UNHANDLED MULTI PRODUCTS", response.data);
              }
            }
          }

          doGetBundleProductRelation(
            {
              bundleProductId: getProductIdInActiveProduct(
                updatedVaultProductList[idx],
                applicantData
              ),
              demandProductId: updatedVaultProductList[idx].productId,
              applicationId: updatedVaultProductList[idx].applicationId,
            },
            (getBundleProductRelationRes) => {
              if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
                console.log(
                  "getBundleProductRelationRes",
                  getBundleProductRelationRes
                );
                updatedVaultProductList[idx].bundleProducts =
                  getBundleProductRelationRes.data;
                doUpdateProductToVault(updatedVaultProductList, () => {
                  this.updateFinAnalysisIdForLendingProducts(
                    updatedVaultProductList,
                    idx,
                    prospectApp.applicantId,
                    (newVaultProductList) => {
                      updatedVaultProductList = newVaultProductList;
                      if (prospectApp.slots.length) {
                        const jointExistingAccounts = [];
                        prospectApp.slots.forEach((jointApp) => {
                          if (jointApp.memberNumber !== membership) {
                            jointExistingAccounts.push({
                              firstName: jointApp.firstName,
                              lastName: jointApp.lastName,
                              email: jointApp.email,
                              msisdn:
                                "1" +
                                jointApp.phoneNumber.replace(
                                  AppConstants.JOINT_APPLICANT
                                    .CONTACT_NO_PATTERN,
                                  ""
                                ),
                              phoneNumber: jointApp.phoneNumber,
                              //TODO what should be the joinType of primary
                              joinType: jointApp?.joinType || "jnt",
                            });
                          }
                        });
                        if (oldProductInformation) {
                          this.updateProductInformation(
                            oldProductInformation,
                            activeProduct
                          );
                        }
                        this.setState(
                          {
                            jointExistingAccounts,
                            oldProductInformation: null,
                          },
                          () => {
                            const pendingJoints = [];
                            jointExistingAccounts.forEach((joint) => {
                              pendingJoints.push({
                                firstName: joint.firstName,
                                lastName: joint.lastName,
                                emailAddress: joint.email,
                                emailConfirm: joint.email,
                                mobilePhone: joint.phoneNumber,
                                applicantType: joint.joinType,
                                signer: !finInfo.showJointSignatory,
                              });
                            });
                            this.addApplicants(
                              response.data.applicantId,
                              response.data.applicationId,
                              pendingJoints,
                              false,
                              [
                                {
                                  ...activeProduct,
                                  applicantId: null,
                                  applicationId: null,
                                  applicationStep: {
                                    index: null,
                                    step: "",
                                  },
                                  productId: activeProduct.productId,
                                  productIndex: activeProduct.productIndex,
                                  productName: activeProduct.productName,
                                  templateId: activeProduct.templateId,
                                  type: activeProduct.type,
                                  isJointProduct: true,
                                },
                              ],
                              false,
                              true
                            );
                          }
                        );
                      }
                    }
                  );
                });
              }
            }
          );
        }
      }
    );
  };

  updateFinAnalysisIdForLendingProducts = (
    updatedVaultProductList,
    productIndex,
    applicantId,
    callback
  ) => {
    const { doGetFinancialList, doUpdateProductToVault } = this.props;
    console.log(
      "updateFinAnalysisIdForLendingProducts",
      updatedVaultProductList,
      productIndex,
      applicantId
    );
    let continueCallBack = true;
    if (
      updatedVaultProductList[productIndex].type ===
      AppConstants.PRODUCT_TYPE.LENDING_PRODUCT
    ) {
      doGetFinancialList({ applicantId: applicantId }, (res) => {
        console.log(res);
        if (res) {
          res.forEach((raw) => {
            console.log(
              raw.applicationId.toString(),
              updatedVaultProductList[productIndex].applicationId
            );
            if (
              raw.applicationId.toString() ===
              updatedVaultProductList[productIndex].applicationId
            ) {
              updatedVaultProductList[productIndex]["finAnalysisId"] =
                raw.finAnalysisId;
              doUpdateProductToVault(updatedVaultProductList, () => {
                continueCallBack = false;
                callback(updatedVaultProductList);
              });
            }
          });
        }
      });
    } else {
      callback(updatedVaultProductList);
    }
  };

  updateProductInformation = (oldProductInformation, activeProduct) => {
    const { doUpdateProductInformation } = this.props;
    delete oldProductInformation?.lpBasicDetails?.applicationGuid;
    delete oldProductInformation?.lpBasicDetails?.applicationId;
    doUpdateProductInformation(
      {
        ...oldProductInformation,
        demandProductUDFDetails: {
          ...oldProductInformation.udfList,
        },
      },
      activeProduct.applicationId,
      activeProduct.type,
      activeProduct.doximProductId,
      () => {}
    );
  };

  handleCloseError = () => {
    this.setState({ showError: false, showMessage: false });
  };

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      continuehandler,
      doGetProductFromVault,
      products,
      doAddApplicationSlot,
      doinviteApplicationSlot,
      doGetApplicantType,
      applicantTypes,
      doAddGlobalVault,
      doGetApplicationProperty,
      getActiveProduct,
      account,
      finInfo,
      membershipsData,
      applicantData,
    } = this.props;
    const {
      jointAccounts,
      jointInSession,
      ownershipList,
      ownership,
      survivorList,
      survivor,
      ssoLogin,
      showSingularMembership,
      jointMembershipSelected,
      addJointMembers,
      enterJointMembers,
      membership,
      dataIssue,
      jointMembership,
      singularMembership,
      jointExistingAccounts,
      page,
      inviteErrorEncountered,
      showError,
      showMessage,
    } = this.state;
    console.log("jointAccounts", jointAccounts);
    return (
      <div>
        {showError && (
          <PopupModal
            type={AppConstants.MODALTYPE.FAILURE}
            description={STRINGS.JOINT_APPLICANT.FORM.JOINT_DUPLICATE_EMAIL}
            toggleModal={this.handleCloseError}
            showModal={showError}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showMessage && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={
              STRINGS.JOINT_APPLICANT.FORM.JOINT_DUPLICATE_EXISTING_PROFILE
            }
            toggleModal={this.handleCloseError}
            showModal={showMessage}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {!showSingularMembership && (
          <JointApplicant
            finInfo={finInfo}
            continuehandler={continuehandler}
            handleBack={this.handleBack}
            handleContinue={this.handleContinue}
            getDataFromServer={getDataFromServer}
            steps={steps}
            activeStepID={activeStepID}
            doGetProductFromVault={doGetProductFromVault}
            doAddApplicationSlot={doAddApplicationSlot}
            doinviteApplicationSlot={doinviteApplicationSlot}
            doGetApplicantType={doGetApplicantType}
            doGetApplicationProperty={doGetApplicationProperty}
            doAddGlobalVault={doAddGlobalVault}
            products={products}
            applicantTypes={applicantTypes}
            getActiveProduct={getActiveProduct}
            jointAccounts={jointAccounts}
            jointInSession={jointInSession}
            ownershipList={ownershipList}
            ownership={ownership}
            survivorList={survivorList}
            survivor={survivor}
            handleChange={this.handleChange}
            account={account}
            ssoLogin={ssoLogin}
            toggleJointSelection={this.toggleJointSelection}
            jointMembershipSelected={jointMembershipSelected}
            addJointMembers={addJointMembers}
            enterJointMembers={enterJointMembers}
            membershipsData={membershipsData}
            membership={membership}
            selectMembership={this.selectMembership}
            dataIssue={dataIssue}
            jointMembership={jointMembership}
            jointExistingAccounts={jointExistingAccounts}
            page={page}
            togglePage={this.togglePage}
            contKycFlag={!isNewMember(applicantData)}
            inviteErrorEncountered={inviteErrorEncountered}
          />
        )}
        {showSingularMembership && (
          <Membership
            steps={steps}
            activeStepID={activeStepID}
            membershipsData={membershipsData}
            membership={membership}
            selectMembership={this.selectMembership}
            handleContinue={this.handleContinue}
            dataIssue={dataIssue}
            singularMembership={singularMembership}
            handleBack={this.handleBack}
            page={page}
            togglePage={this.togglePage}
            contKycFlag={!isNewMember(applicantData)}
            applicantData={applicantData}
            getActiveProduct={getActiveProduct}
            products={products}
          />
        )}
      </div>
    );
  }
}

JointApplicantContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doAddApplicationSlot: PropTypes.func.isRequired,
  doinviteApplicationSlot: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  applicantTypes: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  doGetProductFromVault: PropTypes.func.isRequired,
  doAddGlobalVault: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doDeleteApplicationSlot: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  globalVault: PropTypes.object,
  doToggleBackFlag: PropTypes.func.isRequired,
  finInfo: PropTypes.object,
  doUpdateContract: PropTypes.func.isRequired,
  applicationCompleteList: PropTypes.arrayOf(PropTypes.any),
  doGetProductInformation: PropTypes.func.isRequired,
  doUpdateProductInformation: PropTypes.func.isRequired,
};
JointApplicantContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  applicantTypes: [],
  products: [],
  globalVault: {},
  finInfo: {},
  applicationCompleteList: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAccountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicantType: (callback) => dispatch(getApplicantType(callback)),
    doAddGlobalVault: (callback) => dispatch(addGlobalVault(callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doDeleteApplicationSlot: (request, callback) =>
      dispatch(deleteApplicationSlot(request, callback)),
    dosetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
    doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
    doAddJointApplicants: (request, callback) =>
      dispatch(addJointApplicants(request, callback)),
    doGetApplication: (applicationId, callback) =>
      dispatch(getApplication(applicationId, callback)),
    doGetContract: (
      applicationId,
      applicationType,
      productId,
      waitMessage,
      callback
    ) =>
      dispatch(
        getContract(
          applicationId,
          applicationType,
          productId,
          waitMessage,
          callback
        )
      ),
    doUpdateContract: (
      applicationId,
      applicationType,
      productId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateContract(
          applicationId,
          applicationType,
          productId,
          payLoad,
          callback
        )
      ),
    doSearchMember: (params, callback) =>
      dispatch(searchMember(params, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doGetFinInfo: (callback) => dispatch(getFIInfo(callback)),
    doDeleteApplication: (applicationId, callback) =>
      dispatch(deleteApplication(applicationId, callback)),
    doGetProductInformation: (
      applicationId,
      productType,
      doximProductId,
      callback
    ) =>
      dispatch(
        getProductInformation(
          applicationId,
          productType,
          doximProductId,
          callback
        )
      ),
    doUpdateProductInformation: (
      requestBody,
      applicationId,
      productType,
      doximProductId,
      callback
    ) =>
      dispatch(
        updateProductInformation(
          requestBody,
          applicationId,
          productType,
          doximProductId,
          callback
        )
      ),
    doGetFinancialList: (reqData, callback) =>
      dispatch(getFinancialList(reqData, callback)),
    doCheckExistingUsers: (userDetails, callback) =>
      dispatch(doCheckExistingUser(userDetails, callback)),
  };
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicantTypes: state.JoinApplicantReducer.applicantTypes,
  globalVault: state.JoinApplicantReducer.globalVault,
  applicantData: state.ApplicationReducer.response,
  finInfo: state.MarketplaceReducer.finInfo,
  account: state.AboutYouReducer.account,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  actionBack: state.ApplicationReducer.actionBack,
  membershipsData: state.ApplicationReducer.membershipsData,
});

const JointApplicantWrapper = withApplicantHOC(JointApplicantContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JointApplicantWrapper);
