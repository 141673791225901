import * as actionTypes from "Redux/Actions/actionTypes";
import { RESET_STORE_REQUEST_KEEP_JOINT } from "./actionTypes";

const resetStore = () => {
  return {
    type: actionTypes.RESET_STORE_REQUEST,
  };
};

const resetStoreKeepJoint = () => {
  return {
    type: actionTypes.RESET_STORE_REQUEST_KEEP_JOINT,
  };
};

export default resetStore;
