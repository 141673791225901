import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import VALIDATIONS from "../../Constants/ValidationList";
import checkErrors from "../../Utils/InputValidator";
import * as ROUTES from "Constants/Routes";
import PopupModal from "../Common/PopupModal/PopupModal";
import { HTTP_STATUS, S_PERSONAL } from "../../Communication/Constants";

class BusinessReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedBusinessType: "",

      isCannabisOrMoneyIndustry: "",
      isFinancialInstitution: "",

      reasonOpenAccount: "",
      relationshipBusiness: "",
      accountStatementType: "",
      businessBranch: "",

      legalBusinessName: "",
      tradeName: "",
      businessNumber: "",
      hasCertifcateOfIncorporation: "",
      hasBusinessOperate: "",
      hasCertificateOfIncorporation: "",

      businessStreetName: "",
      businessSuiteNo: "",
      businessStreetNumber: "",
      businessCity: "",
      businessProvince: "",
      businessPostalCode: "",

      businessMailingStreetName: "",
      businessMailingSuiteNo: "",
      businessMailingStreetNumber: "",
      businessMailingCity: "",
      businessMailingProvince: "",
      businessMailingPostalCode: "",

      businessEmail: "",
      businessPhoneNumber: "",
      businessPhoneExtension: "",

      isBusinessLegagllyFormed: "no",
      isBusinessPhysicalLocation: "no",
      isBusinessTransact: "no",
      businessEntity: "",
      businessSector: "",
      businessSubSector: "",
      businessGroup: "",
      businessIndustry: "",
      businessIndustryDescription: "",
      countryIncorporation: "",
      countryPhysicalLocation: [],
      countryTransaction: [],

      isBusinessNotForProfit: false,
      isBusinessFileTaxesUSA: false,
      isBusinessFileTaxesOthers: false,
      isBusinessSolicitDonations: false,
      isBusinessRegisteredWithCRA: false,

      businessNotForProfit: "",
      businessTinUSA: "",
      businessFileTaxesOthersList: [],

      show: [],
      processFlow: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.BUSINESS_REVIEW,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeProduct, processTemplate } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        // Business Type
        selectedBusinessType: activeProduct?.selectedBusinessType ?? "",

        // Business Eligibility
        isCannabisOrMoneyIndustry:
          activeProduct?.isCannabisOrMoneyIndustry ?? "",
        isFinancialInstitution: activeProduct?.isFinancialInstitution ?? "",

        reasonOpenAccount: activeProduct?.reasonOpenAccount ?? "",
        relationshipBusiness: activeProduct?.relationshipBusiness ?? "",
        accountStatementType: activeProduct?.accountStatementType ?? "",
        businessBranch: activeProduct?.businessBranch ?? "",

        // Details
        tradeName: activeProduct?.tradeName ?? "",
        hasCertifcateOfIncorporation:
          activeProduct?.hasCertifcateOfIncorporation,
        legalBusinessName: activeProduct?.legalBusinessName ?? "",
        hasBusinessOperate: !!activeProduct?.tradeName ? "yes" : "no",
        hasCertificateOfIncorporation: activeProduct?.hasCertificateOfIncorporation
          ? "yes"
          : "no",
        hasMasterBusinessLicense: activeProduct?.hasMasterBusinessLicense
          ? "yes"
          : "no",
        // Address
        businessStreetName: activeProduct?.businessStreetName ?? "",
        businessSuiteNo: activeProduct?.businessSuiteNo ?? "",
        businessStreetNumber: activeProduct?.businessStreetNumber ?? "",
        businessCity: activeProduct?.businessCity ?? "",
        businessProvince: activeProduct?.businessProvince ?? "",
        businessPostalCode: activeProduct?.businessPostalCode ?? "",

        // Mailing address
        businessMailingStreetName:
          activeProduct?.businessMailingStreetName ?? "",
        businessMailingSuiteNo: activeProduct?.businessMailingSuiteNo ?? "",
        businessMailingStreetNumber:
          activeProduct?.businessMailingStreetNumber ?? "",
        businessMailingCity: activeProduct?.businessMailingCity ?? "",
        businessMailingProvince: activeProduct?.businessMailingProvince ?? "",
        businessMailingPostalCode:
          activeProduct?.businessMailingPostalCode ?? "",

        // Contact
        businessEmail: activeProduct?.businessEmail,
        businessPhoneNumber: activeProduct?.businessPhoneNumber,
        businessPhoneExtension: activeProduct?.businessPhoneExtension,

        // Operations
        isBusinessLegagllyFormed:
          activeProduct?.countryIncorporation !== "" &&
          activeProduct?.countryIncorporation !== undefined
            ? "yes"
            : "no",
        isBusinessPhysicalLocation:
          activeProduct?.countryPhysicalLocation &&
          activeProduct?.countryPhysicalLocation.length > 0
            ? "yes"
            : "no", //activeProduct?.isBusinessPhysicalLocation,
        isBusinessTransact:
          activeProduct?.countryTransaction &&
          activeProduct?.countryTransaction.length > 0
            ? "yes"
            : "no", //activeProduct?.isBusinessTransact,
        businessEntity: activeProduct?.businessEntity ?? "",
        businessSector: activeProduct?.businessSector ?? "",
        businessSubSector: activeProduct?.businessSubSector ?? "",
        businessGroup: activeProduct?.businessGroup ?? "",
        businessIndustry: activeProduct?.businessIndustry ?? "",
        businessIndustryDescription:
          activeProduct?.businessIndustryDescription ?? "",
        countryIncorporation: activeProduct?.countryIncorporation ?? "",
        countryPhysicalLocation: activeProduct?.countryPhysicalLocation ?? [],
        countryTransaction: activeProduct?.countryTransaction ?? [],

        // Taxation
        isBusinessNotForProfit: activeProduct?.isBusinessNotForProfit,
        isBusinessFileTaxesUSA: activeProduct?.isBusinessFileTaxesUSA,
        isBusinessFileTaxesOthers: activeProduct?.isBusinessFileTaxesOthers,
        isBusinessSolicitDonations: activeProduct?.isBusinessSolicitDonations,
        isBusinessRegisteredWithCRA: activeProduct?.isBusinessRegisteredWithCRA,
        businessNotForProfit: activeProduct?.businessNotForProfit,
        businessTinUSA: activeProduct?.businessTinUSA ?? "",
        businessFileTaxesOthersList:
          activeProduct?.businessFileTaxesOthersList ?? [],
      });
      const processFlow = processTemplate.flow;
      const show = processFlow.map((item) => item.componentName);
      this.setState({
        processFlow,
        show,
      });
    }
  }

  setFormData = () => {};

  toggleModal = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;
    let valid = true;

    if (valid) {
      handleContinue(property);
    }
  };
  handleContinue = () => {
    const { doGetAcoountDetails } = this.props;
    let valid = true;

    doGetAcoountDetails((getAccountActionRes) => {
      if (getAccountActionRes.status === HTTP_STATUS.OK) {
        if (valid) {
          this.setState({ showModal: true });
        }
      }
    });
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.BUSINESS_REVIEW);
  };

  handleChoice = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (event) => {
    console.log("handleChange", event?.target?.name, event?.target?.value);
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;

    let eventName = event?.target.name;
    let eventValue = event?.target.value;

    this.setState(
      {
        [eventName]: eventValue,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
  };

  verifyData = () => {
    let formIsValid = true;
    return formIsValid;
  };

  render() {
    const {
      steps,
      activeStepID,
      handleEditPage,
      finInfo,
      applicantData,
      activeProduct,
      provinceList,
    } = this.props;
    const { showModal } = this.state;

    const {
      selectedBusinessType,

      isCannabisOrMoneyIndustry,
      isFinancialInstitution,

      reasonOpenAccount,
      relationshipBusiness,
      accountStatementType,
      businessBranch,

      legalBusinessName,
      tradeName,
      businessNumber,
      hasCertifcateOfIncorporation,
      hasBusinessOperate,
      hasCertificateOfIncorporation,
      hasMasterBusinessLicense,
      businessStreetName,
      businessSuiteNo,
      businessStreetNumber,
      businessCity,
      businessProvince,
      businessPostalCode,

      businessMailingStreetName,
      businessMailingSuiteNo,
      businessMailingStreetNumber,
      businessMailingCity,
      businessMailingProvince,
      businessMailingPostalCode,

      businessEmail,
      businessPhoneNumber,
      businessPhoneExtension,

      isBusinessLegagllyFormed,
      isBusinessPhysicalLocation,
      isBusinessTransact,
      businessEntity,
      businessSector,
      businessSubSector,
      businessGroup,
      businessIndustry,
      businessIndustryDescription,
      countryIncorporation,
      countryPhysicalLocation,
      countryTransaction,

      isBusinessNotForProfit,
      isBusinessFileTaxesUSA,
      isBusinessFileTaxesOthers,
      isBusinessSolicitDonations,
      isBusinessRegisteredWithCRA,
      businessNotForProfit,
      businessTinUSA,
      businessFileTaxesOthersList,
      show,
    } = this.state;
    console.log(steps, activeStepID);

    const actionComponent = (
      <div className="[ business-details-review ] [ confirm-account-container ] [ business-review-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.BUSINESS_REVIEW.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.BUSINESS_REVIEW.REVIEW_DETAILS_MSG1}
          </div>
          <div className="[ form-description ]">
            {STRINGS.BUSINESS_REVIEW.REVIEW_DETAILS_MSG2}
          </div>

          {/* 
                    BUSINESS TYPE
                */}
          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_TYPE) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_REVIEW.BUSINESS_TYPE}
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.TYPE_OF_BUSINESS}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.business?.businessType}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {/* 
                    BUSINESS ELIGIBILITY
                    reasonOpenAccount would have a value if it started new
                */}
          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_ELIGIBILITY) &&
            activeProduct?.reasonOpenAccount && (
              <>
                <div className="[ details-section ] [ mb-3 ]">
                  <div className="[ row ] [ details-section__header">
                    <div className="[ col-12 ]">
                      <h3 className="[ d-flex justify-content-between align-items-center ]">
                        {STRINGS.BUSINESS_REVIEW.BUSINESS_ELIGIBILITY}
                      </h3>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.BUSINESS_REVIEW.IS_CANNABIS_OR_MONEY}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        No
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.BUSINESS_REVIEW.IS_FINANCIAL_INSTITUTION}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        No
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

          {/* 
              BUSINESS START
              reasonOpenAccount would have a value if it started new
          */}

          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_START) &&
            activeProduct?.reasonOpenAccount && (
              <>
                <div className="[ details-section ] [ mb-3 ]">
                  <div className="[ row ] [ details-section__header">
                    <div className="[ col-12 ]">
                      <h3 className="[ d-flex justify-content-between align-items-center ]">
                        {STRINGS.BUSINESS_REVIEW.BUSINESS_START}
                      </h3>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.BUSINESS_REVIEW.DECISION_TO_OPEN_AN_ACCOUNT}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {
                          finInfo?.config?.businessReason?.find(
                            (item) =>
                              item.code === activeProduct.reasonOpenAccount
                          )?.description
                        }
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.BUSINESS_REVIEW.RELATIONSHIP_BUSINESS}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {
                          finInfo?.config?.businessRelation?.find(
                            (item) => item.code === relationshipBusiness
                          )?.description
                        }
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.BUSINESS_REVIEW.BRANCH}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {
                          finInfo?.config?.businessBranch?.find(
                            (item) => item.code === businessBranch
                          )?.description
                        }
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.BUSINESS_REVIEW.RECEIVE_ACCOUNT_STATEMENTS}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {accountStatementType}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

          {/* 
                    BUSINESS REGISTRATION
                */}

          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_LOOKUP) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_REVIEW.BUSINESS_REGISTRATION}
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.LEGAL_BUSINESS_NAME_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.member?.company}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.TRADE_NAME_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.business?.tradeName}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.BUSINESS_NUMBER_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.member?.businessNumber
                        ? `**** ${applicantData?.member?.businessNumber.substr(
                            applicantData?.member?.businessNumber.length - 4
                          )}`
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {/* 
                    BUSINESS REGISTERED ADDRESS
                */}

          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_INFO_ADDRESS) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_REVIEW.BUSINESS_REGISTERED_ADDRESS}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.BUSINESS_INFO_ADDRESS)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.ADDRESS}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressLine1}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.ADDRESS2}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressLine2}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.CITY}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressLine3}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.PROVINCE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {
                        provinceList.find(
                          (x) =>
                            x.code ===
                            applicantData?.address?.currentAddressProvince
                        )?.description
                      }
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.POSTAL_CODE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressPostalCode}
                    </div>
                  </div>
                </div>

                {/* <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.MAILING_ADDRESS_AS_LISTED}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      Yes
                    </div>
                  </div>
                </div> */}
              </div>
            </>
          ) : (
            ""
          )}

          {/*
                    BUSINESS REGISTERED MAILING ADDRESS
                */}

          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_INFO_MAIL) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {
                        STRINGS.BUSINESS_REVIEW
                          .BUSINESS_REGISTERED_MAILING_ADDRESS
                      }
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.BUSINESS_INFO_MAIL)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.ADDRESS}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.mailingAddressLine1}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.ADDRESS2}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.mailingAddressLine2}
                    </div>
                  </div>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.CITY}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.mailingCity}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.PROVINCE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {
                        provinceList.find(
                          (x) =>
                            x.code === applicantData?.address?.mailingProvince
                        )?.description
                      }
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.POSTAL_CODE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.mailingPostalCode}
                    </div>
                  </div>
                </div>

                {/* <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.MAILING_ADDRESS_AS_LISTED}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      Yes
                    </div>
                  </div>
                </div> */}
              </div>
            </>
          ) : (
            ""
          )}

          {/*
                    BUSINESS CONTACT DETAILS
                */}

          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_CONTACT) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_REVIEW.BUSINESS_CONTACT_DETAILS}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.BUSINESS_CONTACT)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.PHONE_NUMBER}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.address?.currentAddressPhoneArea +
                        applicantData?.address?.currentAddressPhone}{" "}
                      EXT. {applicantData?.address?.extension}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.BUSINESS_EMAIL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {applicantData?.member?.workEmail}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {/* 
                    BUSINESS DETAILS
                */}
          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_DETAILS) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_REVIEW.BUSINESS_DETAILS}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.BUSINESS_DETAILS)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.OPERATE_UNDER_TRADE_NAME}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {!!applicantData?.business?.tradeName ? "yes" : "no"}
                    </div>
                  </div>
                </div>

                {applicantData?.isSoleProprietor && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.BUSINESS_REVIEW.CERTIFICATE_OF_INCORPORATION}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {hasMasterBusinessLicense}
                      </div>
                    </div>
                  </div>
                )}
                {!applicantData?.isSoleProprietor && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.BUSINESS_REVIEW.CERTIFICATE_OF_INCORPORATION}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {hasCertificateOfIncorporation}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {/*
                    BUSINESS OPERATIONS
                */}
          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_OPERATIONS) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_REVIEW.BUSINESS_OPERATIONS}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() =>
                          handleEditPage(ROUTES.BUSINESS_OPERATIONS)
                        }
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.LEGALLY_FORMED_OUTSIDE_CANADA}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {isBusinessLegagllyFormed}
                      {isBusinessLegagllyFormed === "yes"
                        ? " - " +
                          finInfo?.config?.businessCountries.find(
                            (x) => x.code === countryIncorporation
                          )?.description
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.LOCATION_OUTSIDE_CANADA}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {isBusinessPhysicalLocation}
                      {Array.isArray(countryPhysicalLocation) &&
                      countryPhysicalLocation?.length > 0
                        ? " - " +
                          countryPhysicalLocation
                            .map(
                              (s) =>
                                finInfo?.config?.businessCountries.find(
                                  (x) => x.code === s.country
                                )?.description
                            )
                            .join(", ")
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.TRANSACT_OUTSIDE_CANADA}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {isBusinessTransact}
                      {Array.isArray(countryTransaction) &&
                      countryTransaction?.length > 0
                        ? " - " +
                          countryTransaction.map(
                            (s) =>
                              finInfo?.config?.businessCountries.find(
                                (x) => x.code === s.country
                              )?.description
                          )
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.REVENUE_IS_PASSIVE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {businessEntity}
                    </div>
                  </div>
                </div>

                {applicantData?.business?.naicsSector && (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BUSINESS_REVIEW.INDUSTRY_SECTOR}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {applicantData?.business.naicsSector}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BUSINESS_REVIEW.INDUSTRY_SUBSECTOR}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {applicantData?.business.naicsSubSector}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BUSINESS_REVIEW.INDUSTRY_GROUP}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {applicantData?.business.naicsIndustryGroups}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BUSINESS_REVIEW.INDUSTRY}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {applicantData?.business.naicsIndustries}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BUSINESS_REVIEW.INDUSTRY_DESCRIPTION}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {applicantData?.business.naicsDescription}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BUSINESS_REVIEW.INDUSTRY_DESCRIPTION}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {applicantData?.business.naicsDescription}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {applicantData?.business?.naicsCode && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.BUSINESS_REVIEW.NAICS_CODE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {applicantData?.business.naicsCode}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {/*
              BUSINESS TAXATION
          */}
          {show.includes(AppConstants.APPLICATIONSTEP.BUSINESS_TAX) ? (
            <>
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.BUSINESS_REVIEW.BUSINESS_TAXATION}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.BUSINESS_TAX)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.BUSINESS_REVIEW.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.NOT_FOR_PROFIT}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {isBusinessNotForProfit ? "Yes" : "No"}
                    </div>
                  </div>
                </div>

                {isBusinessNotForProfit && (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.BUSINESS_TAX.FORM
                              .BUSINESS_REGISTERED_AS_NOT_FOR_PROFIT
                          }
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {isBusinessRegisteredWithCRA ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.BUSINESS_TAX.FORM
                              .DOES_BUSINESS_SOLICIT_DONATIONS
                          }
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {isBusinessSolicitDonations ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.FILE_TAXES_IN_USA}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {isBusinessFileTaxesUSA ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
                {isBusinessFileTaxesUSA && (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BUSINESS_TAX.FORM.TAX_IDENTITY_NUMBER_USA}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          ****&nbsp;
                          {businessTinUSA
                            ? businessTinUSA.substr(businessTinUSA.length - 3)
                            : null}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.BUSINESS_REVIEW.FILE_TAXES_IN_OTHER}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {isBusinessFileTaxesOthers ? "Yes" : "No"}
                    </div>
                  </div>
                </div>

                {isBusinessFileTaxesOthers ? (
                  <>
                    {businessFileTaxesOthersList?.map((record, idx) => (
                      <div key={record}>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.BUSINESS_REVIEW.COUNTRY_NO} {idx + 1}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {
                                finInfo?.config?.businessCountries.find(
                                  (x) => x.code === record?.country
                                )?.description
                              }{" "}
                              / ****&nbsp;
                              {record.tin
                                ? record.tin.substr(record.tin.length - 3)
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>

        {showModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.SUCCESS}
            title={STRINGS.BUSINESS_REVIEW.SUCCESS_POP_UP_TITLE}
            description={STRINGS.BUSINESS_REVIEW.SUCCESS_POP_UP_DESCRIPTION}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

BusinessReview.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleEditPage: PropTypes.func.isRequired,
  handleSample: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

BusinessReview.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  handleEditPage: () => {},
  handleSample: () => {},
  steps: [],
  activeStepID: -1,
};

export default BusinessReview;
